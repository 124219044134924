import React, { useState } from 'react';
import PaymentError from './PaymentError';
import LoginSuccessFully from './LoginSuccessFully';
import LoginAccount from './LoginAccount';

function Poppages() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [successisPopupOpen, setIssuccessPopupOpen] = useState(false);
  const [fingerisPopupOpen, setIsAccountPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const successtogglePopup = () => {
    setIssuccessPopupOpen(!successisPopupOpen);
  };

  const LoginAccounttogglePopup = () => {
    setIsAccountPopupOpen(!fingerisPopupOpen);
  };

  return (
    <div className="App">
      <h1>React Popup Example</h1>
      <button onClick={togglePopup}>Open error Popup</button>
      <PaymentError isOpen={isPopupOpen} onClose={togglePopup} />

      <button onClick={successtogglePopup}>Open success Popup</button>
      <LoginSuccessFully
        isOpensuccess={successisPopupOpen}
        onClosesuccess={successtogglePopup}
      />

      <button onClick={LoginAccounttogglePopup}>Open success Popup</button>
      <LoginAccount
        isOpenLogin={fingerisPopupOpen}
        onCloseLOgin={LoginAccounttogglePopup}
      />
    </div>
  );
}
export default Poppages;
