import React, { useState } from 'react';
import '../Client/UpdatePlan.css';

function UpdatePlan() {
  const durationOptions = ['1 Year', '3 Years', '5 Years'];
  const discountOptions = ['5%', '10%', '15%', '20%', '25%'];
  const amountOptions = ['$100', '$200', '$300', '$400', '$500'];
  const [selectedData, setSelectedData] = useState({});
  const [selectedBoxSize, setSelectedBoxSize] = useState(null);

  const handleSelectChange = (event, rowKey, field) => {
    setSelectedData({
      ...selectedData,
      [rowKey]: {
        ...selectedData[rowKey],
        [field]: event.target.value,
      },
    });
  };

  const handleBoxSizeChange = (rowId) => {
    setSelectedBoxSize(rowId);
  };

  return (
    <div className="container">
      <h4
        className="text-center"
        style={{
          fontFamily: 'Poppins',
          fontSize: '32px',
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        UPGRADE PLAN
      </h4>
      <h6 className="text-center">BOX SIZE AND RENTAL PERIOD</h6>

      <div className="table-responsive">
        <table className="table borderless" style={{ fontSize: '14px' }}>
          <thead>
            <tr>
              <th scope="col">Select</th>
              <th scope="col">Length</th>
              <th scope="col">Width</th>
              <th scope="col">Depth</th>
              <th scope="col">Duration</th>
              <th scope="col">Discount</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                id: 1,
                size: 'SMALL(SV65)',
                length: '520MM',
                width: '70MM',
                depth: '70MM',
              },
              {
                id: 2,
                size: 'MEDIUM(SV83)',
                length: '520MM',
                width: '220MM',
                depth: '70MM',
              },
              {
                id: 3,
                size: 'LARGE(SV83)',
                length: '520MM',
                width: '220MM',
                depth: '120MM',
              },
              {
                id: 4,
                size: 'EXTRA LARGE(SV93)',
                length: '520MM',
                width: '220MM',
                depth: '120MM',
              },
            ].map((row, index) => (
              <tr key={row.id}>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={selectedBoxSize === row.id}
                      onChange={() => handleBoxSizeChange(row.id)}
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      {row.size}
                    </label>
                  </div>
                </td>
                <td>{row.length}</td>
                <td>{row.width}</td>
                <td>{row.depth}</td>
                <td>
                  <select
                    value={selectedData[index]?.duration || ''}
                    onChange={(event) =>
                      handleSelectChange(event, index, 'duration')
                    }
                    className="form-control"
                    style={{ width: '120px' }}
                  >
                    <option value="">--Select--</option>
                    {durationOptions.map((duration, idx) => (
                      <option key={idx} value={duration}>
                        {duration}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={selectedData[index]?.discount || ''}
                    onChange={(event) =>
                      handleSelectChange(event, index, 'discount')
                    }
                    className="form-control"
                    style={{ width: '100px' }}
                  >
                    <option value="">--select--</option>
                    {discountOptions.map((discount, idx) => (
                      <option key={idx} value={discount}>
                        {discount}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={selectedData[index]?.amount || ''}
                    onChange={(event) =>
                      handleSelectChange(event, index, 'amount')
                    }
                    className="form-control"
                    style={{ width: '100px' }}
                  >
                    <option value="">----</option>
                    {amountOptions.map((amount, idx) => (
                      <option key={idx} value={amount}>
                        {amount}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <p>
        - 3 & 5 years package you can also have the option of paying monthly
      </p>
      <p>- C100 refundable key deposit</p>

      <div className="row">
        <div className="col-md-12">
          <h4>PAYMENT METHOD</h4>
          <div className="d-flex justify-content-evenly align-items-start">
            <div className="form-check">
              <input
                className="form-check-input fw-bold"
                type="checkbox"
                id="cash"
              />
              <label className="form-check-label fw-bold" htmlFor="cash">
                Cash
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="debitCard"
              />
              <label className="form-check-label fw-bold" htmlFor="debitCard">
                Debit Card
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="bankTransfer"
              />
              <label
                className="form-check-label fw-bold"
                htmlFor="bankTransfer"
              >
                Bank transfer
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="other" />
              <label className="form-check-label fw-bold" htmlFor="other">
                Other
              </label>
            </div>
          </div>
        </div>
      </div>

      <form>
        <div className="text-end my-3">
          <button
            type="submit"
            className="btn"
            style={{ backgroundColor: 'green', color: 'white' }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdatePlan;
