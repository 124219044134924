import React from 'react';
import './Popup.css';
import errorIMageg from '../popUPPage/popupimage/errorImage.png';

const PaymentError = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content text-center ">
        <img src={`${errorIMageg}`} alt="" className="popuperrorimage" />
        <h2 className="text-danger my-4">ERROR!</h2>
        <h5 className=" text-danger  fs-5">Login Failed Payment Outstanding</h5>
        <p>
          Access has been blocked due to an unpaid balance. Please complete your
          payment to proceed
        </p>
        <button onClick={onClose} className="btn btn-danger rounded-pill px-5">
          Close
        </button>
      </div>
    </div>
  );
};

export default PaymentError;
