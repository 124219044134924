import React from 'react';
import {
  Modal,
  Tabs,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Space,
  Image,
} from 'antd';
import moment from 'moment';
import {
  PhoneOutlined,
  MailOutlined,
  IdcardOutlined,
  FileTextOutlined,
  UserOutlined,
} from '@ant-design/icons';
import defaultPic from '../../../assets/user.jpg';
import TransactionHistoryTab from './TransactionHistoryTab';
import LockerInfo from './LockerInfo';
import '../resusableStyle/ClientDetailShowModal.css';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const ClientDetailShowModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedUserData,
}) => {
  return (
    <Modal
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
      width={'800px'}
    >
      {selectedUserData ? (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Client" key="1">
            <div className="row ">
              <div className="col-5 d-flex align-items-stretch">
                <Card
                  style={{
                    textAlign: 'center',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <Image
                    src={selectedUserData.clientPic || defaultPic}
                    alt="Client Pic"
                    className="card-img-top rounded"
                    style={{
                      width: '100%',
                      height: '150px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="card-body">
                    <Title
                      level={4}
                      className="card-title"
                      style={{ margin: '10px 0' }}
                    >
                      {`${selectedUserData.foreName} ${selectedUserData.surName}`}
                    </Title>
                    <p className="card-text" style={{ color: '#666' }}>
                      {`${selectedUserData.email} `}
                    </p>
                  </div>
                </Card>
              </div>

              <div className="col-7 d-flex align-items-stretch">
                <Col
                  className="p-4 d-flex flex-column"
                  style={{
                    textAlign: 'center',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <div className="user-info-container d-flex justify-content-center align-items-center">
                    <Space
                      direction="vertical"
                      size="large"
                      style={{ width: '100%', flexGrow: 1 }}
                      className="mb-4"
                    >
                      <Row className="user-info-row align-items-center">
                        <Col span={8} className="user-info-label">
                          <UserOutlined className="me-2" />
                          <Text strong>Name:</Text>
                        </Col>
                        <Col span={16} className="user-info-value">
                          <Text>{`${selectedUserData?.foreName} ${selectedUserData?.surName}`}</Text>
                        </Col>
                      </Row>
                      <Row className="user-info-row align-items-center">
                        <Col span={8} className="user-info-label">
                          <MailOutlined className="me-2" />
                          <Text strong>Email:</Text>
                        </Col>
                        <Col span={16} className="user-info-value">
                          <Text>{selectedUserData?.email}</Text>
                        </Col>
                      </Row>

                      <Row className="user-info-row align-items-center">
                        <Col span={10} className="user-info-label">
                          <PhoneOutlined className="me-2" />{' '}
                          <Text strong>Telephone:</Text>
                        </Col>
                        <Col span={10} className="user-info-value">
                          <Text>{selectedUserData?.telephone}</Text>
                        </Col>
                      </Row>

                      <Row className="user-info-row align-items-center">
                        <Col span={10} className="user-info-label">
                          <IdcardOutlined className="me-2" />
                          <Text strong>DOB:</Text>
                        </Col>
                        <Col span={10} className="user-info-value">
                          <Text>{selectedUserData?.dob}</Text>
                        </Col>
                      </Row>
                    </Space>
                  </div>
                </Col>
              </div>
            </div>

            <Divider style={{ margin: '20px 0' }} />

            <div>
              <Title level={5}>
                <FileTextOutlined /> Identification Documents:
              </Title>

              {selectedUserData.identificationDocument?.length ? (
                <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
                  {selectedUserData.identificationDocument.map((doc, index) => (
                    <li
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                      style={{ margin: '10px 0' }}
                    >
                      <Text>{doc.documentType}</Text>
                      <Button
                        type="link"
                        onClick={() => window.open(doc.documentUrl, '_blank')}
                      >
                        View Document
                      </Button>
                    </li>
                  ))}
                </ul>
              ) : (
                <Text>No identification documents available.</Text>
              )}
            </div>
          </TabPane>

          <TabPane tab="Nominee Details" key="2">
            <Card className="mb-4">
              <Title level={5}>Nominee One</Title>

              <div style={{ display: 'flex', alignItems: '' }}>
                <div>
                  <Image
                    src={selectedUserData.nomineeOne?.nomineePic || defaultPic}
                    alt="Nominee Pic"
                    className="rounded"
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'cover',
                      marginRight: '10px',
                    }}
                  />
                </div>

                <div className="ms-5">
                  <p>
                    <Text strong>Name: </Text>
                    {`${selectedUserData.nomineeOne?.nomineeTitle || '---'} ${
                      selectedUserData.nomineeOne?.nomineeForename || '---'
                    } ${selectedUserData.nomineeOne?.nomineeSurname || '---'}`}
                  </p>
                  <p>
                    <Text strong>Email: </Text>
                    {selectedUserData.nomineeOne?.nomineeEmail || '---'}
                  </p>
                  <p>
                    <Text strong>Telephone: </Text>
                    {selectedUserData.nomineeOne?.nomineeTel || '---'}
                  </p>
                  <p>
                    <Text strong>Date of Birth: </Text>
                    {selectedUserData.nomineeOne?.nomineeDob
                      ? moment(selectedUserData.nomineeOne.nomineeDob).format(
                          'YYYY-MM-DD'
                        )
                      : '---'}
                  </p>
                  <p>
                    <Text strong>Nationality: </Text>
                    {selectedUserData.nomineeOne?.nomineeNationality || '---'}
                  </p>
                  <p>
                    <Text strong>Street: </Text>
                    {selectedUserData.nomineeOne?.nomineeStreet || '---'}
                  </p>
                  <p>
                    <Text strong>Town: </Text>
                    {selectedUserData.nomineeOne?.nomineeTown || '---'}
                  </p>
                  <p>
                    <Text strong>Postcode: </Text>
                    {selectedUserData.nomineeOne?.nomineePostcode || '---'}
                  </p>
                </div>
              </div>

              <Title level={5}>
                <FileTextOutlined /> Identification Documents One:
              </Title>

              {selectedUserData?.nomineeOne?.identificationDocument.length ? (
                <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
                  {selectedUserData.nomineeOne.identificationDocument.map(
                    (doc, index) => (
                      <li
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                        style={{ margin: '10px 0' }}
                      >
                        <Text>{doc.documentType}</Text>
                        <Button
                          type="link"
                          onClick={() => window.open(doc.documentUrl, '_blank')}
                        >
                          View Document
                        </Button>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <Text>No identification documents available.</Text>
              )}
            </Card>

            <Card className="mb-4">
              <Title level={5}>Nominee Two</Title>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <Image
                    src={selectedUserData.nomineeTwo?.nomineePic || defaultPic}
                    alt="Nominee Pic"
                    className="rounded"
                    style={{
                      width: '200px',
                      height: '200px',
                      objectFit: 'cover',
                      marginRight: '10px',
                    }}
                  />
                </div>

                <div className="ms-5">
                  {selectedUserData.nomineeTwo ? (
                    <>
                      <p>
                        <Text strong>Name: </Text>
                        {`${
                          selectedUserData.nomineeTwo.nomineeTitle || 'N/A'
                        } ${
                          selectedUserData.nomineeTwo.nomineeForename || ''
                        } ${selectedUserData.nomineeTwo.nomineeSurname || ''}`}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {selectedUserData.nomineeTwo.nomineeEmail || 'N/A'}
                      </p>
                      <p>
                        <Text strong>Telephone: </Text>
                        {selectedUserData.nomineeTwo.nomineeTel || 'N/A'}
                      </p>
                      <p>
                        <Text strong>Date of Birth: </Text>
                        {selectedUserData.nomineeTwo.nomineeDob
                          ? moment(
                              selectedUserData.nomineeTwo.nomineeDob
                            ).format('YYYY-MM-DD')
                          : 'N/A'}
                      </p>
                      <p>
                        <Text strong>Nationality: </Text>
                        {selectedUserData.nomineeTwo.nomineeNationality ||
                          'N/A'}
                      </p>
                      <p>
                        <Text strong>Street: </Text>
                        {selectedUserData.nomineeTwo?.nomineeStreet || '---'}
                      </p>
                      <p>
                        <Text strong>Town: </Text>
                        {selectedUserData.nomineeTwo?.nomineeTown || '---'}
                      </p>
                      <p>
                        <Text strong>Postcode: </Text>
                        {selectedUserData.nomineeTwo.nomineePostcode || 'N/A'}
                      </p>
                    </>
                  ) : (
                    <Text>No information available for Nominee Two.</Text>
                  )}
                </div>
              </div>

              <Title level={5}>
                <FileTextOutlined /> Identification Documents Nominee Two:
              </Title>

              {selectedUserData.nomineeTwo?.identificationDocument &&
              selectedUserData.nomineeTwo.identificationDocument.length > 0 ? (
                <ul style={{ paddingLeft: '20px', listStyleType: 'none' }}>
                  {selectedUserData.nomineeTwo.identificationDocument.map(
                    (doc, index) => (
                      <li
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                        style={{ margin: '10px 0' }}
                      >
                        <Text>{doc.documentType}</Text>
                        <Button
                          type="link"
                          onClick={() => window.open(doc.documentUrl, '_blank')}
                        >
                          View Document
                        </Button>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <Text>No identification documents available.</Text>
              )}
            </Card>
          </TabPane>

          <TabPane tab="Transaction History" key="3">
            <TransactionHistoryTab selectedUserData={selectedUserData} />
          </TabPane>

          <TabPane tab="Locker Details " key="4">
            <LockerInfo selectedUserData={selectedUserData} />
          </TabPane>
        </Tabs>
      ) : (
        <p>Loading user data...</p>
      )}
    </Modal>
  );
};

export default ClientDetailShowModal;
