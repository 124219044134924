import React from 'react';
import PropTypes from 'prop-types';
import '../resusableStyle/FormButtons.css';

const FormButtons = ({
  type = 'button',
  onClick,
  children,
  className,
  style,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`custom-button ${className}`}
      style={style}
    >
      {children}
    </button>
  );
};

FormButtons.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default FormButtons;
