import React from 'react';
import { FaHome, FaUserCircle } from 'react-icons/fa';
import { FiEdit, FiSearch } from 'react-icons/fi';
import Logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

const SafeNavbar = ({ setSearchTerm }) => {
  const navigate = useNavigate();

  const handleHomeNavigate = () => {
    navigate('/admin-home');
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-content">
          <img
            src={Logo}
            alt="Logo"
            className="logo"
            onClick={handleHomeNavigate}
          />
          <div className="profile text-light">
            <FaUserCircle className="profile-icon" />
            <span className="username">User Name</span>
            <FiEdit className="edit-icon" />
          </div>
        </div>
        <div className="search-container">
          <div className="search-input-container">
            <FiSearch className="search-icon" />
            <input
              type="text"
              className="search-input"
              placeholder="Search anything here"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="home-button-container">
          <button className="home-button" onClick={handleHomeNavigate}>
            <FaHome className="me-2 fs-5" /> <span className="">Home</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default SafeNavbar;
