import {
  makeBearerJsonApiRequest,
  makeJsonApiRequest,
} from '../../services/ApiService';

const API_URL = process.env.REACT_APP_API_URL;

export const getVisitHistoryAPI = async (userId) => {
  const response = await makeBearerJsonApiRequest(
    'get',
    `${API_URL}get-visit-history?userId=${userId}`,
    userId
  );
  return response.data;
};

export const sendUserEventReport = async (userId) => {
  const response = await makeJsonApiRequest(
    'POST',
    `${API_URL}get-user-event-report`,
    { userId }
  );
  return response;
};

export const getClientList = async () => {
  const endpoint = `${API_URL}get-all-client-lists`;
  const response = await makeBearerJsonApiRequest('POST', endpoint, {});
  return response;
};

export const sendOtp = async (userId, email) => {
  const endpoint = `${API_URL}send-otp`;
  try {
    const response = await makeBearerJsonApiRequest('POST', endpoint, {
      userId,
      email,
    });
    return response;
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
};

export const verifyOtp = async (userId, otp) => {
  const endpoint = `${API_URL}verify-client-otp`;
  try {
    const response = await makeBearerJsonApiRequest('POST', endpoint, {
      userId,
      otp,
    });
    return response;
  } catch (error) {
    throw new Error('Error verifying OTP');
  }
};

export const clientLogout = async (userId) => {
  if (!userId) {
    throw new Error('User ID is required for logout.');
  }

  const endPoint = `${API_URL}client-logout`;
  try {
    const response = await makeBearerJsonApiRequest('POST', endPoint, {
      userId,
    });
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const clientLogin = async (userId) => {
  const endPoint = `${API_URL}client-login`;
  try {
    const response = await makeBearerJsonApiRequest('POST', endPoint, {
      userId,
    });
    return response;
  } catch (error) {
    console.error('Error in client login:', error);
    throw error;
  }
};

export const submitNameDobBox = async (foreName, dob, boxNumber) => {
  const endPoint = `${API_URL}alternate-login`;
  try {
    const response = await makeJsonApiRequest('POST', endPoint, {
      foreName,
      dob,
      boxNumber,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
