import { makeBearerJsonApiRequest } from '../../../services/ApiService';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getClientTableAPI = async () => {
  const endpoint = `${API_BASE_URL}get-all-clients`;
  const payload = { isArchived: false };
  const response = await makeBearerJsonApiRequest('GET', endpoint, payload);
  return response;
};

export const getArchiveClient = async () => {
  const endpoint = `${API_BASE_URL}get-archive-clients`;
  // const payload = { isArchived: false };
  const response = await makeBearerJsonApiRequest('GET', endpoint, {});
  return response;
};

export const getLoginData = async () => {
  const endPoint = `${API_BASE_URL}get-all-client-login`;
  const payload = {};
  const response = await makeBearerJsonApiRequest('POST', endPoint, payload);
  return response;
};

export const getTransactionAPI = async (clientId) => {
  try {
    const response = await makeBearerJsonApiRequest(
      'GET',
      `${API_BASE_URL}get-transactions?userId=${clientId}`,
      clientId
    );
    return response;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};
