import React, { useState, useEffect } from 'react';
import BackButton from '../reusable/reusableComponent/BackButton';
import { getClientTableAPI } from './API/ClientTableAPI';
import ClientViewDetails from './ClientViewDetails';
import { useNavigate } from 'react-router-dom';
import { Image, Pagination, Spin, Input } from 'antd';
import './client-style/EditClientDetails.css';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import moment from 'moment';
import DefaultClientProfile from '../../assets/user.jpg';

const { Search } = Input;

const EditClientDetails = ({ searchTerm }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [allClients, setAllClients] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    createdAt: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    const combinedSearchTerm = searchTerm || searchTerm1;
    if (combinedSearchTerm.length > 0) {
      searchClients(combinedSearchTerm);
    } else {
      setFilteredClients(allClients);
    }
  }, [searchTerm, searchTerm1]);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getClientTableAPI();
      if (response.success && response.data) {
        const clients = response.data.clients;
        setFilteredClients(clients);
        setAllClients(clients);
        setCurrentPage(1);
      } else {
        setError(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const searchClients = (term) => {
    const lowercasedTerm = term.toLowerCase();

    const filtered = allClients.filter((client) => {
      const fullName = `${client.foreName} ${client.surName}`.toLowerCase();
      const formattedStartDate = moment(client.createdAt).format('DD-MM-YYYY');
      const postcode = client.postcode ? client.postcode.toString() : '';
      const street = client.street ? client.street.toLowerCase() : '';
      const town = client.town ? client.town.toLowerCase() : '';

      return (
        fullName.includes(lowercasedTerm) ||
        client.email.toLowerCase().includes(lowercasedTerm) ||
        formattedStartDate.includes(lowercasedTerm) ||
        street.includes(lowercasedTerm) ||
        town.includes(lowercasedTerm) ||
        postcode.includes(lowercasedTerm) ||
        client.telephone.includes(lowercasedTerm)
      );
    });

    setFilteredClients(filtered);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleEditClient = (client) => {
    setSelectedClient(client);
    fetchClientData();
    setShowClientDetails(true);
  };

  const handleBackToTable = () => {
    setShowClientDetails(false);
  };

  const paginatedClients = filteredClients.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const toggleSortOrder = (field) => {
    const newSortOrder = { ...sortOrder };
    const currentOrder = newSortOrder[field];

    if (currentOrder === 'asc') {
      newSortOrder[field] = 'desc';
    } else if (currentOrder === 'desc') {
      newSortOrder[field] = 'asc';
    } else {
      newSortOrder[field] = 'asc';
    }

    setSortOrder(newSortOrder);
    sortData(field, newSortOrder[field]);
  };

  const sortData = (field, order) => {
    const sortedClients = [...filteredClients];

    if (field === 'name') {
      sortedClients.sort((a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return order === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    } else if (field === 'createdAt') {
      sortedClients.sort((a, b) => {
        const dateA = moment(a.createdAt).unix();
        const dateB = moment(b.createdAt).unix();
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }

    setFilteredClients(sortedClients);
  };

  return (
    <div className="container-fluid">
      {showClientDetails ? (
        <ClientViewDetails
          clientDetails={selectedClient}
          onClose={handleBackToTable}
          fetchClientData={fetchClientData}
        />
      ) : (
        <div className="table-responsive">
          <div className="d-flex justify-content-end align-items-center">
            <Search
              placeholder="Search clients by name, email, or other details"
              allowClear
              value={searchTerm1}
              onChange={(e) => setSearchTerm1(e.target.value)}
              enterButton
              className="search-input"
            />
          </div>
          {loading ? (
            <Spin className="d-flex justify-content-center align-items-center" />
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <>
              <table className="table client-table">
                <thead>
                  <tr>
                    <th scope="col" className="th-client">
                      CLIENT
                    </th>
                    <th
                      style={{ width: '20%' }}
                      scope="col table-heading"
                      className="sortable-column"
                      onClick={() => toggleSortOrder('name')}
                    >
                      NAME/EMAIL
                      {sortOrder.name === 'asc' ? (
                        <BsFillCaretUpFill className="m-2 sort-icon" />
                      ) : (
                        <BsFillCaretDownFill className="m-2 sort-icon" />
                      )}
                    </th>
                    <th
                      scope="col"
                      className="th-start-date"
                      onClick={() => toggleSortOrder('createdAt')}
                    >
                      START DATE
                      {sortOrder.createdAt === 'asc' ? (
                        <BsFillCaretUpFill className="m-2 sort-icon" />
                      ) : (
                        <BsFillCaretDownFill className="m-2 sort-icon" />
                      )}
                    </th>
                    <th scope="col" className="th-address">
                      STREET
                    </th>
                    <th scope="col" className="th-address">
                      TOWN
                    </th>
                    <th scope="col" className="th-postcode">
                      POSTCODE
                    </th>
                    <th scope="col" className="th-telephone">
                      TELEPHONE
                    </th>
                    <th scope="col" className="th-edit-client">
                      EDIT CLIENT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedClients.length > 0 ? (
                    paginatedClients.map((client) => (
                      <tr key={client._id}>
                        <td>
                          <Image
                            src={client.clientPic || DefaultClientProfile}
                            alt="profile"
                            className="avatar rounded-circle client-avatar"
                            width={60}
                            height={60}
                          />
                        </td>
                        <td>
                          <p className="client-name fs-6 fw-bold mb-0">{`${
                            client.foreName || 'Unknown'
                          } ${client.surName || ''}`}</p>
                          <p className="client-email fw-normal">
                            {client.email || '---'}
                          </p>
                        </td>
                        <td>
                          {moment(client.createdAt).format('DD-MM-YYYY') ||
                            '---'}
                        </td>
                        <td>{client.street || '---'}</td>
                        <td>{client.town || '---'}</td>
                        <td>{client.postcode || '---'}</td>
                        <td>{client.telephone || '---'}</td>
                        <td>
                          <button
                            className="btn btn-view-history"
                            onClick={() => handleEditClient(client)}
                          >
                            Edit Client Details
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No clients found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredClients.length}
                onChange={handlePaginationChange}
                showSizeChanger
                pageSizeOptions={['5', '10', '20']}
                className="pagination"
              />
            </>
          )}
          <div className="back-button-container">
            <BackButton onClick={handleBackPrevious} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditClientDetails;
