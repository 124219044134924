import React, { useEffect, useState } from 'react';
import './SecuritySafe.css';
import {
  captureFingerPrint,
  fetchLockers,
  fetchSafes,
  submitFingerPrintData,
  withoutFingerScanService,
} from '../../client-management/ClientApi';
import { toast } from 'react-toastify';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Image, message, Modal, Tooltip } from 'antd';
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

const SecuritySafe = ({ email, handleBack }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedSafe, setSelectedSafe] = useState(null);
  const [payloadValue, setPayloadValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [loadingWithoutScan, setLoadingWithoutScan] = useState(false);

  const [formdata, setFormData] = useState({
    boxId: selectedSafe,
    cLocker: false,
    complimentaryLocker: {
      lockerId: '',
      lockerNumber: '',
      column: '',
    },
    plan: '',
    boxType: 'safe',
    paymentAmount: payloadValue,
    keyDeposite: '',
    keyDepositDue: '',
    paymentType: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [applicationNo, setApplicationNo] = useState('');
  const [copied, setCopied] = useState(false);
  const [fingerPrintImage, setFingerPrintImage] = useState('');

  useEffect(() => {
    loadSafes();
  }, []);

  const loadSafes = async () => {
    setLoading(true);
    try {
      const response = await fetchSafes('Medium');
      if (response.success && response.data.safes.length > 0) {
        const firstSafe = response.data.safes[0];
        setSelectedSafe(firstSafe);
        setFormData({
          ...formdata,
          boxId: firstSafe._id,
        });
      }
    } catch (error) {
      console.error('Error fetching safes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleComplimentryLocker = async (event) => {
    const value = event.target.value;

    if (value === 'Yes') {
      try {
        const lockers = await fetchLockers('Small');
        if (lockers?.success && lockers?.data?.lockers.length > 0) {
          const firstLocker = lockers?.data?.lockers[0];
          const lockerNum = firstLocker?.lockerNumber;
          const lockerColumn = firstLocker?.column;
          const lockerId = firstLocker?._id;

          setFormData({
            ...formdata,
            cLocker: true,
            complimentaryLocker: {
              lockerNumber: lockerNum,
              lockerId: lockerId,
              column: lockerColumn,
            },
          });
        }
      } catch (error) {
        console.error('Error fetching lockers:', error);
      }
    } else {
      setFormData({
        ...formdata,
        cLocker: false,
        complimentaryLocker: null,
      });
    }
  };

  const paymentMethodMap = {
    Cash: 'cash',
    DC: 'dc',
    CC: 'cc',
    BT: 'bt',
    SO: 'so',
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!formdata.boxId) {
      newErrors.boxId = 'Box Number is required.';
      isValid = false;
    }
    if (!formdata.keyDeposite) {
      newErrors.keyDeposite = 'Key Deposit is required.';
      isValid = false;
    }
    if (!formdata.plan) {
      newErrors.plan = 'Plan is required.';
      isValid = false;
    }
    if (!formdata.paymentType) {
      newErrors.paymentType = 'Payment type is required.';
      isValid = false;
    }
    if (!formdata.paymentAmount) {
      newErrors.paymentAmount = 'Payment Amount is required.';
      isValid = false;
    }

    setErrors(newErrors);

    if (!isValid) {
      toast.error('Please fill in all required fields.');
    }

    return isValid;
  };

  const handleModalOpen = () => {
    const isValid = validateForm();
    if (isValid) {
      setIsModalVisible(true);
    }
  };

  const handleStartNowClick = async () => {
    setLoading(true);
    setErrors({
      boxId: '',
      keyDeposit: '',
      plan: '',
      paymentAmount: '',
      paymentType: '',
    });

    let isValid = true;
    const newErrors = {};

    if (
      !formdata.boxId &&
      !formdata.keyDeposite &&
      !formdata.plan &&
      !formdata.paymentType &&
      !formdata.paymentAmount &&
      !formdata.feedback
    ) {
      toast.error('All fields are required.');
      setLoading(false);
      return;
    }
    if (!formdata.boxId) {
      newErrors.boxId = 'Box Number is required.';
      isValid = false;
      toast.error(newErrors.boxId);
    }
    if (!formdata.keyDeposite) {
      newErrors.keyDeposite = 'Key Deposit is required.';
      isValid = false;
      toast.error(newErrors.keyDeposite);
    }
    if (!formdata.plan) {
      newErrors.plan = 'Plan is required.';
      isValid = false;
      toast.error(newErrors.plan);
    }
    if (!formdata.paymentType) {
      newErrors.paymentType = 'Payment type is required.';
      isValid = false;
      toast.error(newErrors.paymentType);
    }
    if (!formdata.paymentAmount) {
      newErrors.paymentAmount = 'Payment Amount is required.';
      isValid = false;
      toast.error(newErrors.paymentAmount);
    }
    setErrors(newErrors);

    if (!isValid) {
      setLoading(false);
      return;
    }
    try {
      const captureData = await captureFingerPrint();
      setFingerPrintImage(captureData?.BMPBase64);

      const complimentaryLocker =
        formdata?.complimentaryLocker?.lockerId === '' &&
        formdata?.complimentaryLocker?.lockerNumber === '' &&
        formdata?.complimentaryLocker?.column === ''
          ? null
          : formdata.complimentaryLocker;

      if (captureData && captureData.TemplateBase64) {
        const apiPayload = {
          fingerPrintRaw: {
            ...captureData,
            BMPBase64: undefined,
            WSQImageSize: undefined,
          },
          fingerPrintString: captureData.TemplateBase64,
          boxId: formdata.boxId,
          boxType: formdata.boxType,
          plan: formdata.plan,
          paymentAmount: formdata.paymentAmount,
          paymentType: formdata.paymentType,
          complimentaryLocker: complimentaryLocker,
          keyDeposite: parseInt(formdata.keyDeposite),
          keyDepositDue: formdata.keyDepositDue,
          email: email,
        };

        const submitData = await submitFingerPrintData(apiPayload);

        if (submitData && submitData?.data?.applicationNo) {
          setApplicationNo(submitData?.data?.applicationNo);
          setIsModalVisible(true);
        }
      }
    } catch (error) {
      alert('Error connecting to the capture services.');
    } finally {
      setLoading(false);
    }
  };

  const handleWithoutFingerScanClick = async () => {
    setLoadingWithoutScan(true);
    const complimentaryLocker =
      formdata?.complimentaryLocker?.lockerId === '' &&
      formdata?.complimentaryLocker?.lockerNumber === '' &&
      formdata?.complimentaryLocker?.column === ''
        ? null
        : formdata.complimentaryLocker;
    try {
      const apiPayload = {
        boxId: formdata.boxId,
        boxType: formdata.boxType,
        plan: formdata.plan,
        paymentAmount: formdata.paymentAmount,
        paymentType: formdata.paymentType,
        complimentaryLocker: complimentaryLocker,
        keyDeposite: parseInt(formdata.keyDeposite),
        keyDepositDue: formdata.keyDepositDue,
        email: email,
      };

      const data = await withoutFingerScanService(apiPayload);

      if (data.success) {
        message.success(data.message);
        setApplicationNo(data?.data?.applicationNo);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error('Something went wrong!');
    } finally {
      setLoadingWithoutScan(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(applicationNo);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleBackToPrevious = () => {
    handleBack();
    setIsModalVisible(false);
  };

  return (
    <div className="mt-5">
      <h6 className="header-text mb-3">INDEPENDENT SECURITY SAFE</h6>
      <div className="info-text mb-3">
        INTERESTED IN HAVING YOUR OWN 24/7 ALARM MONITORED SAFE? PLEASE SPEAK TO
        A MEMBER OF STAFF
      </div>
      <form>
        <div className="security-form-text">
          <div className="row mb-4">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="safeNo"
                  className="security-form-label form-check-label"
                >
                  SAFE NO:
                </label>
                <input
                  id="safeNo"
                  type="text"
                  className="form-control custom-margin"
                  name={`safeNo`}
                  value={selectedSafe ? selectedSafe.safeNumber : ''}
                  readOnly
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="keyDeposit"
                  className="security-form-label form-check-label"
                >
                  Complimentary Locker:
                </label>
                <select
                  id="keyDeposit"
                  name="keyDeposit"
                  className="form-control custom-margin"
                  value={
                    formdata?.complimentaryLocker?.lockerNumber ? 'Yes' : 'No'
                  }
                  onChange={handleComplimentryLocker}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="depositBox"
                  className="security-form-label form-check-label"
                >
                  BOX NO:
                </label>
                <input
                  id="depositBox"
                  name="depositBox"
                  type="text"
                  className="form-control custom-margin"
                  value={formdata?.complimentaryLocker?.lockerNumber || ''}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="years"
                  className="security-form-label form-check-label"
                >
                  Months:
                </label>
                <select
                  id="years"
                  name="years"
                  className="security-year form-select"
                  value={formdata.plan || ''}
                  onChange={(e) => {
                    setFormData({
                      ...formdata,
                      plan: e.target.value,
                    });
                  }}
                >
                  <option value="">--Select--</option>
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                  <option value="36">36 Months</option>
                  <option value="60">60 Months</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="total"
                  className="security-form-label form-check-label ms-2"
                >
                  TOTAL:
                </label>
                <input
                  id="total"
                  name="total"
                  type="text"
                  placeholder="Total amount"
                  className="form-control custom-margin"
                  value={formdata.paymentAmount}
                  onChange={(e) => {
                    setFormData({
                      ...formdata,
                      paymentAmount: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row align-items-center p-2 mt-2">
              <div className="col-sm-2 col-lg-1 col-md-2 me-2">
                <label className="custom-label">Key Deposit:</label>
              </div>
              <div className="col-sm-2 col-lg-2 col-md-2">
                <label className="fs-6">
                  <input
                    type="radio"
                    name="keyDepositDue"
                    value="due"
                    checked={selectedOption === 'due'}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedOption(value);
                      setFormData((prev) => ({
                        ...prev,
                        keyDepositDue: true,
                      }));
                    }}
                  />{' '}
                  Key Deposit Due
                </label>
              </div>
              <div className="col-sm-4 col-lg-8 col-md-8">
                {selectedOption && (
                  <input
                    id="keyDeposit"
                    name="keyDeposite"
                    className="form-control custom-input"
                    type="text"
                    placeholder="Key Deposit"
                    value={formdata.keyDeposite}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setFormData((prev) => ({
                        ...prev,
                        keyDeposite: value,
                      }));
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <h2 className="header-text mt-4 mb-4">Payment Process of Locker</h2>

        <div className="d-flex flex-wrap">
          {['Cash', 'DC', 'CC', 'BT', 'SO'].map((method, index) => (
            <div className="form-check form-check-inline fw-bold" key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="paymentMethod"
                id={`payment${method.replace(/\s+/g, '')}`}
                value={method}
                onChange={(e) => {
                  const selectedMethod = e.target.value;
                  const mappedValue = paymentMethodMap[selectedMethod];
                  setFormData((prevState) => ({
                    ...prevState,
                    paymentType: mappedValue,
                  }));
                }}
              />
              <label
                className="security-form-label fw-bolder"
                htmlFor={`payment${method.replace(/\s+/g, '')}`}
              >
                {method}
              </label>
            </div>
          ))}
        </div>

        <div className="stepper-navigation">
          <button
            type="button"
            onClick={handleModalOpen}
            className="btn next-btn fw-bold"
            loading={loading}
          >
            Submit
          </button>
        </div>
      </form>

      <Modal
        title="Fingerprint Scanner"
        open={isModalVisible}
        onCancel={() => handleBackToPrevious()}
        footer={[
          <Button
            key="close"
            onClick={() => {
              handleBackToPrevious();
            }}
          >
            Close
          </Button>,
        ]}
        closable={false}
      >
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {!applicationNo && (
            <div className="d-flex justify-content-around gap-2">
              <button
                onClick={handleStartNowClick}
                className="custom-button print-button"
              >
                {loading ? 'Scanning...' : 'Scan Fingerprint'}
              </button>

              <button
                className="custom-button email-button"
                onClick={handleWithoutFingerScanClick}
              >
                {loadingWithoutScan ? 'Processing...' : 'Without Finger Scan'}
              </button>
            </div>
          )}
          {applicationNo ? (
            <div style={{ marginTop: '20px' }}>
              <CheckCircleOutlined
                style={{ fontSize: '48px', color: 'green' }}
              />
              <p>Fingerprint scanned successfully!</p>

              <div className="copy-container text-center mt-3">
                <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
                  <div
                    onClick={handleCopyToClipboard}
                    className="application-number d-flex align-items-center justify-content-center"
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      border: '1px solid #e0e0e0',
                      padding: '10px',
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                      marginBottom: '20px',
                    }}
                  >
                    <span>{applicationNo}</span>
                    {copied ? (
                      <BsClipboardCheck
                        className="ms-2"
                        style={{ color: 'green', fontSize: '1.5rem' }}
                      />
                    ) : (
                      <BsClipboard
                        className="ms-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    )}
                  </div>
                </Tooltip>

                {fingerPrintImage && (
                  <div>
                    <h4>Fingerprint Image:</h4>
                    <Image
                      src={`data:image/bmp;base64,${fingerPrintImage}`}
                      width={'100px'}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p>No application number available.</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SecuritySafe;
