import React, { useEffect, useState } from 'react';
import { Table, Card, Typography, Col, Row, Image } from 'antd';
import { ClientHistoryApI } from './ClientApi';
import './client-style/ClientHistory.css';

const { Text, Title } = Typography;

function ClientHistory({ clientDetails }) {
  const [clientHistoryData, setClientHistoryData] = useState([]);

  useEffect(() => {
    const fetchedClientHistory = async () => {
      try {
        const response = await ClientHistoryApI(clientDetails?._id);
        if (response?.success && response?.data) {
          setClientHistoryData(response?.data);
        }
      } catch (err) {
        console.error('Error fetching client history data:', err);
      }
    };

    if (clientDetails && clientDetails?._id) {
      fetchedClientHistory();
    }
  }, [clientDetails]);

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    const date = new Date(timeString);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'loginTime',
      render: (loginTime) => {
        return loginTime
          ? new Date(loginTime).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '---';
      },
    },
    {
      title: 'Log In Time',
      dataIndex: 'loginTime',
      render: (loginTime) => formatTime(loginTime) || 'No login time',
    },
    {
      title: 'Log Out Time',
      dataIndex: 'logoutTime',
      render: (logoutTime) => formatTime(logoutTime) || 'No logout time',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (status === 'logged_out' ? 'Log Out' : 'Log In'),
    },
  ];

  const dataSource = clientHistoryData.map((visit) => ({
    key: visit._id,
    loginTime: visit.loginTime,
    logoutTime: visit.logoutTime,
    status: visit.status,
  }));

  return (
    <div>
      <div className="container-fluid my-3">
        {clientDetails ? (
          <Card className="client-history-card" hoverable>
            <Row gutter={[16, 16]} align="middle">
              <Col md={8} xs={24} className="image-container">
                <Image
                  src={
                    clientDetails.clientPic
                      ? clientDetails.clientPic
                      : 'https://via.placeholder.com/150'
                  }
                  alt="Client Avatar"
                  className="client-avatar"
                  width={100}
                  height={100}
                  style={{ borderRadius: '50%' }}
                />
              </Col>
              <Col md={16} xs={24}>
                <div className="client-details">
                  <Title level={4} className="client-name">
                    {clientDetails.foreName || 'N/A'}{' '}
                    {clientDetails.surName || 'N/A'}
                  </Title>
                  <Text strong className="client-email">
                    {clientDetails.email || 'N/A'}
                  </Text>
                  <div className="client-info">
                    <Text strong>UCC No.: </Text>
                    <Text>{clientDetails.uccNumber || '---'}</Text>
                  </div>
                  <div className="client-info">
                    <Text strong>Contact No.: </Text>
                    <Text>{clientDetails.telephone || 'N/A'}</Text>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        ) : (
          <Col span={24}>
            <Text>No client details available</Text>
          </Col>
        )}
        <Table
          className="client-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName="client-row"
        />
        {dataSource.length === 0 && (
          <div className="text-center">No visit history available</div>
        )}
      </div>
    </div>
  );
}

export default ClientHistory;
