import React from 'react';
import './HomeFooter.css';
import logo from '../../../assets/images/logo.png';

const HomeFooter = () => {
  return (
    <div>
      <footer className="home-footer">
        <img src={logo} alt="Logo" className="footer-logo" />
        <p className="footer-text">Developed and hosted by Sentinel Vaults</p>
      </footer>
    </div>
  );
};

export default HomeFooter;
