import React from 'react';
import { Modal, Button, Divider } from 'antd';
import { LockOutlined, SafetyOutlined } from '@ant-design/icons';
import './UpgradeModal.css';

const UpgradeModal = ({ isVisible, onClose, onSelectComponent }) => {
  return (
    <Modal
      title="Choose Your Upgrade Plan"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      centered
      className="upgrade-modal"
    >
      <p className="modal-description">
        Upgrade to a Locker or Safe based on your needs. Select an option below.
      </p>
      <div className="upgrade-options">
        <Button
          type="primary"
          className="upgrade-option-button"
          icon={<LockOutlined />}
          onClick={() => onSelectComponent('locker')}
          size="large"
        >
          Select Locker
        </Button>
        <Divider className="divider-text">OR</Divider>
        <Button
          type="primary"
          className="upgrade-option-button safe-button"
          icon={<SafetyOutlined />}
          onClick={() => onSelectComponent('safe')}
          size="large"
        >
          Select Safe
        </Button>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
