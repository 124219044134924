import { makeBearerJsonApiRequest } from '../../services/ApiService';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchSafesApi = async () => {
  try {
    const response = await makeBearerJsonApiRequest(
      'get',
      `${API_URL}get-safes`,
      {}
    );
    return response?.data;
  } catch (error) {
    throw new Error('Failed to fetch safes data. Please try again later.');
  }
};
