import React, { useEffect, useState } from 'react';
import './client-style/ArchiveLokerTable.css';
import LoginAccount from './../locker-management/locker/popUPPage/LoginAccount';
import { archiveUsers, getReportArchive } from './ClientApi';
import { Image, Input, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import UpgradeModal from '../reusable/Client/UpgradeModal';
import BackButton from '../reusable/reusableComponent/BackButton';
import { toast } from 'react-toastify';
import moment from 'moment';
import CopyableText from '../reusable/Common/CopyableText';

const { Search } = Input;

function ArchiveLokerTable({ searchTerm }) {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [archiveData, setArchiveData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fingerisPopupOpen, setIsAccountPopupOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchArchivedClients();
  }, []);

  useEffect(() => {
    let filtered = archiveData;
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(
        (client) =>
          `${client.foreName} ${client.surName}`
            .toLowerCase()
            .includes(lowerSearchTerm) ||
          (client.applicationNo &&
            client.applicationNo.toLowerCase().includes(lowerSearchTerm)) ||
          (client.createdAt &&
            moment(client.createdAt)
              .format('DD-MM-YYYY')
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
          (client.uccNumber &&
            client.uccNumber.toLowerCase().includes(lowerSearchTerm)) ||
          (client.boxType &&
            client.boxType.toLowerCase().includes(lowerSearchTerm)) ||
          (client.street &&
            client.street.toLowerCase().includes(lowerSearchTerm)) ||
          (client.town &&
            client.town.toLowerCase().includes(lowerSearchTerm)) ||
          (client.postcode &&
            client.postcode.toLowerCase().includes(lowerSearchTerm)) ||
          (client.telephone &&
            client.telephone.toLowerCase().includes(lowerSearchTerm)) ||
          (client.boxNumber &&
            client.boxNumber.toLowerCase().includes(lowerSearchTerm)) ||
          (client.dob &&
            moment(client.dob)
              .format('DD-MM-YYYY')
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
          (client.email && client.email.toLowerCase().includes(lowerSearchTerm))
      );
    } else if (searchTerm1) {
      const lowerSearchTerm1 = searchTerm1.toLowerCase();
      filtered = filtered.filter(
        (client) =>
          `${client.foreName} ${client.surName}`
            .toLowerCase()
            .includes(lowerSearchTerm1) ||
          (client.applicationNo &&
            client.applicationNo.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.createdAt &&
            moment(client.createdAt)
              .format('DD-MM-YYYY')
              .toLowerCase()
              .includes(lowerSearchTerm1)) ||
          (client.dob &&
            moment(client.dob)
              .format('DD-MM-YYYY')
              .toLowerCase()
              .includes(lowerSearchTerm1)) ||
          (client.uccNumber &&
            client.uccNumber.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.boxType &&
            client.boxType.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.street &&
            client.street.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.town &&
            client.town.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.postcode &&
            client.postcode.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.telephone &&
            client.telephone.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.boxNumber &&
            client.boxNumber.toLowerCase().includes(lowerSearchTerm1)) ||
          (client.email &&
            client.email.toLowerCase().includes(lowerSearchTerm1))
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, searchTerm1, archiveData]);

  const fetchArchivedClients = async () => {
    try {
      const payload = { isArchived: true };
      const response = await archiveUsers(payload);
      setArchiveData(response?.clients || []);
      setFilteredData(response?.clients || []);
    } catch (error) {
      console.error('Error fetching archived clients:', error);
    }
  };

  const LoginAccounttogglePopup = () => {
    setIsAccountPopupOpen(!fingerisPopupOpen);
  };

  const handleChangeClientPlan = (client) => {
    setSelectedClient(client);
    setIsModalVisible(true);
    setIsTableVisible(false);
  };

  const handleBackToTable = () => {
    setIsTableVisible(true);
    setIsModalVisible(false);
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const onSelectComponent = (type) => {
    if (type === 'locker') {
      navigate(`/upgrade-locker`, { state: { client: selectedClient } });
    } else if (type === 'safe') {
      navigate(`/upgrade-safe`, { state: { client: selectedClient } });
    }
    handleBackToTable();
  };

  const columns = [
    {
      title: 'CLIENT',
      dataIndex: 'clientPic',
      align: 'center',
      render: (text, record) => (
        <Image
          src={record.clientPic || 'default-image-url.jpg'}
          alt="Client"
          className="avatar rounded-circle client-avatar"
          style={{ width: '60px', height: '60px' }}
        />
      ),
    },
    {
      title: 'NAME/EMAIL',
      dataIndex: 'name',
      align: 'center',
      sorter: (a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      render: (text, record) => (
        <div>
          <p className="mb-0 fw-bolder">{`${record.foreName || ''} ${
            record.surName || ''
          }`}</p>
          <p>{record.email || ''}</p>
        </div>
      ),
    },
    {
      title: 'UCC NO.',
      dataIndex: 'uccNumber',
      align: 'center',
      render: (text) => <CopyableText text={text} />,
    },
    {
      title: 'SAFE/LOCKER',
      dataIndex: 'boxType',
      align: 'center',
      render: (text) => text || 'N/A',
    },
    {
      title: 'STREET',
      dataIndex: 'street',
      align: 'center',
      render: (text) => {
        if (text && text.length > 100) {
          return text.slice(0, 100) + '...';
        }
        return text || '---';
      },
      width: 180,
    },
    {
      title: 'TOWN',
      dataIndex: 'town',
      align: 'center',
      render: (text) => text || '---',
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      align: 'center',
      sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'DURATION',
      dataIndex: 'plan',
      align: 'center',
      render: (text) => (text ? `${text} Month` : '---'),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'paymentAmount',
      align: 'center',
      render: (text) => text || '---',
    },
    {
      title: 'PHONE',
      dataIndex: 'telephone',
      align: 'center',
      render: (text) => text || '---',
    },
    {
      title: 'ACTION',
      align: 'center',
      render: (text, record) => (
        <button
          className="btn-view-renew"
          onClick={() => handleChangeClientPlan(record)}
        >
          Renew
        </button>
      ),
    },
  ];

  const handleReportGenerate = async () => {
    try {
      const reportUrl = await getReportArchive();
      window.open(reportUrl, '_blank');
    } catch (error) {
      console.error('Error generating the report:', error);
      toast.error('An error occurred while generating the report.');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="mb-2 d-flex justify-content-end fw-bolder">
          <button className="btn btn-primary" onClick={handleReportGenerate}>
            Report Generate
          </button>
        </div>

        <Search
          placeholder="Search clients by name, email, or other details"
          allowClear
          value={searchTerm1}
          onChange={(e) => setSearchTerm1(e.target.value)}
          enterButton
          className="search-input"
        />
      </div>

      <div className="my-3 p-2">
        <div className="back-button-container">
          <BackButton onClick={handleBackPrevious} />
        </div>

        <div className="table-responsive">
          {isTableVisible && (
            <Table
              dataSource={filteredData}
              columns={columns}
              pagination={{ pageSize: 10 }}
              rowKey="_id"
            />
          )}
          <LoginAccount
            isOpenLogin={fingerisPopupOpen}
            onCloseLOgin={LoginAccounttogglePopup}
          />

          <UpgradeModal
            isVisible={isModalVisible}
            onClose={handleBackToTable}
            onSelectComponent={onSelectComponent}
            client={selectedClient}
          />
        </div>
      </div>
    </>
  );
}

export default ArchiveLokerTable;
