import React, { useState, useEffect } from 'react';
import { generateReport, getUpcommingRenewal } from './RenewalApi';
import {
  Image,
  Pagination,
  DatePicker,
  Button,
  Result,
  Row,
  Col,
  Spin,
  Input,
} from 'antd';
import moment from 'moment';
import BackButton from '../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import CopyableText from '../reusable/Common/CopyableText';

const { Search } = Input;

const RenewalTable = ({ searchTerm }) => {
  const [clientDatas, setClient] = useState([]);
  const [searchTerm1, setSearchTerm1] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    createdAt: null,
    renewalDate: null,
  });
  const navigate = useNavigate();
  const pageSize = 10;

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    filterClients();
  }, [searchTerm, searchTerm1, clientDatas]);

  const fetchClientData = async (datesPayload = null) => {
    console.log('datesPayload: ', datesPayload?.startDate);
    setLoading(true);
    try {
      const response = datesPayload
        ? await getUpcommingRenewal(
            datesPayload?.startDate,
            datesPayload?.endDate
          )
        : await getUpcommingRenewal();

      if (response.success && response.data) {
        const clients = response.data;
        setClient(clients);
        setFilteredClients(clients);
        setCurrentPage(1);
      } else {
        setError(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterClick = () => {
    if (startDate && endDate) {
      const payload = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      };
      fetchClientData(payload);
    } else {
      toast.error('Please select both start and end dates.');
    }
  };

  const filterClients = () => {
    let filtered = [...clientDatas];

    if (searchTerm && searchTerm.trim()) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((client) => {
        const fullNameMatch = `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
        const emailMatch = client.email
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const uccNumberMatch = client.uccNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const boxNumberMatch = client.boxNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const postcodeMatch = client.postcode
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const telephoneMatch = client.telephone
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
        const renewalDateMatch = renewDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        let regDate = moment(client.createdAt).format('DD-MM-YYYY');
        const RegDateMatch = regDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const dobDate = moment(client.dob).format('DD-MM-YYYY');
        const dobDateMatch = dobDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const streetMatch = client.street
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const townMatch = client.town
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);

        return (
          fullNameMatch ||
          emailMatch ||
          uccNumberMatch ||
          boxNumberMatch ||
          postcodeMatch ||
          telephoneMatch ||
          renewalDateMatch ||
          RegDateMatch ||
          dobDateMatch ||
          streetMatch ||
          townMatch
        );
      });
    }

    if (searchTerm1 && searchTerm1.trim()) {
      const lowerCaseSearchTerm1 = searchTerm1.toLowerCase();
      filtered = filtered.filter((client) => {
        const fullNameMatch = `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const emailMatch = client.email
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const uccNumberMatch = client.uccNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const boxNumberMatch = client.boxNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const boxTypeMatch = client.boxType
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const postcodeMatch = client.postcode
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const telephoneMatch = client.telephone
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const streetMatch = client.street
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const townMatch = client.town
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
        const renewalDateMatch = renewDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        let regDate = moment(client.createdAt).format('DD-MM-YYYY');
        const RegDateMatch = regDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const dobDate = moment(client.dob).format('DD-MM-YYYY');
        const dobDateMatch = dobDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        return (
          fullNameMatch ||
          emailMatch ||
          uccNumberMatch ||
          boxNumberMatch ||
          postcodeMatch ||
          telephoneMatch ||
          renewalDateMatch ||
          RegDateMatch ||
          streetMatch ||
          townMatch ||
          boxTypeMatch ||
          dobDateMatch
        );
      });
    }
    setFilteredClients(filtered);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const handleReportGenerate = async () => {
    try {
      const reportUrl = await generateReport(
        startDate ? startDate.format('YYYY-MM-DD') : undefined,
        endDate ? endDate.format('YYYY-MM-DD') : undefined
      );
      window.open(reportUrl, '_blank');
    } catch (error) {
      console.error('Error generating the report:', error);
      toast.error('Failed to generate the report!');
    }
  };

  const toggleSortOrder = (field) => {
    const newSortOrder = { ...sortOrder };
    const currentOrder = newSortOrder[field];

    if (currentOrder === 'asc') {
      newSortOrder[field] = 'desc';
    } else if (currentOrder === 'desc') {
      newSortOrder[field] = 'asc';
    } else {
      newSortOrder[field] = 'asc';
    }

    setSortOrder(newSortOrder);
    sortData(field, newSortOrder[field]);
  };

  const sortData = (field, order) => {
    const sortedClients = [...filteredClients];

    if (field === 'name') {
      sortedClients.sort((a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return order === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    } else if (field === 'startDate') {
      sortedClients.sort((a, b) => {
        const dateA = moment(a.startDate).unix();
        const dateB = moment(b.startDate).unix();
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    } else if (field === 'renewalDate') {
      sortedClients.sort((a, b) => {
        const dateA = moment(a.renewalDate).unix();
        const dateB = moment(b.renewalDate).unix();
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }

    setFilteredClients(sortedClients);
  };

  return (
    <div className="container-fluid my-3 p-4 mb-5">
      <div className="back-button-container">
        <BackButton onClick={handleBackPrevious} />
      </div>
      <div className="filter-container mb-4">
        <Row
          gutter={16}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Col xs={8} sm={8} md={8} lg={6}>
            <DatePicker
              value={startDate}
              onChange={handleStartDateChange}
              format="DD-MM-YYYY"
              placeholder="Start Date"
              className="rounded w-100 h-100"
            />
          </Col>
          <Col xs={8} sm={8} md={8} lg={6}>
            <DatePicker
              value={endDate}
              onChange={handleEndDateChange}
              format="DD-MM-YYYY"
              placeholder="End Date"
              className="rounded w-100 p-2"
            />
          </Col>
          <Col xs={8} sm={8} md={2} lg={2}>
            <Button
              onClick={handleFilterClick}
              type="primary"
              block
              className="rounded w-100 p-2 h-100"
            >
              Filter
            </Button>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} style={{ marginLeft: 'auto' }}>
            <Button
              onClick={handleReportGenerate}
              type="primary"
              block
              className="rounded w-100 h-100"
            >
              Report Generate
            </Button>
          </Col>
        </Row>
      </div>

      <div className="row justify-content-end">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <Search
            placeholder="Search by Postcode or Box Number"
            onChange={(e) => setSearchTerm1(e.target.value)}
            enterButton
            allowClear
            value={searchTerm1}
            className="custom-search-bar"
          />
        </div>
      </div>

      <div className="table-responsive">
        {loading ? (
          <div className="loading-container d-flex align-item-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            <table className="table client-table">
              <thead>
                <tr>
                  <th scope="col">CLIENT PICTURE</th>
                  <th scope="col" onClick={() => toggleSortOrder('name')}>
                    NAME{' '}
                    {sortOrder.name === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : sortOrder.name === 'desc' ? (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>
                  <th scope="col">EMAIL</th>
                  <th scope="col">UCC NO.</th>
                  <th scope="col">BOX TYPE</th>
                  <th scope="col">BOX NO.</th>
                  <th scope="col" onClick={() => toggleSortOrder('startDate')}>
                    START DATE{' '}
                    {sortOrder.startDate === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : sortOrder.startDate === 'desc' ? (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>
                  <th
                    scope="col"
                    onClick={() => toggleSortOrder('renewalDate')}
                  >
                    RENEWAL DATE{' '}
                    {sortOrder.renewalDate === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : sortOrder.renewalDate === 'desc' ? (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className="align-items-center">
                {filteredClients.length > 0 ? (
                  filteredClients
                    .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((client) => (
                      <tr key={client._id} className="client-row">
                        <td className="client-view">
                          <Image
                            src={
                              client.clientPic ||
                              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9SRRmhH4X5N2e4QalcoxVbzYsD44C-sQv-w&s'
                            }
                            alt="profile"
                            className="avatar rounded-circle client-avatar"
                            width={60}
                            height={60}
                          />
                        </td>
                        <td>
                          <p className="client-name fs-6 fw-bold mb-0">{`${
                            client.foreName || 'Unknown'
                          } ${client.surName || ''}`}</p>
                        </td>
                        <td className="client-email fw-normal">
                          {client.email || '---'}
                        </td>
                        <td className="client-ucc-number">
                          <CopyableText text={client.uccNumber || '---'} />
                        </td>
                        <td className="client-ucc-number">
                          {client.boxType || '---'}
                        </td>
                        <td className="client-ucc-number">
                          {client.boxNumber || '---'}
                        </td>
                        <td className="client-date fw-normal">
                          {client.startDate
                            ? moment(client.startDate).format('DD-MM-YYYY')
                            : '---'}
                        </td>
                        <td className="client-renewal-date fw-normal">
                          {client.renewalDate
                            ? moment(client.renewalDate).format('DD-MM-YYYY')
                            : '---'}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">
                      <Result
                        status="404"
                        title="No Clients Found"
                        subTitle="Sorry, we couldn't find any clients matching your search."
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={filteredClients.length}
              onChange={handlePaginationChange}
              className="mt-2 pagination"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RenewalTable;
