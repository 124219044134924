import {
  makeBearerJsonApiRequest,
  makeJsonApiRequest,
} from '../../../services/ApiService';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getLockers = async () => {
  const endpoint = `${API_BASE_URL}/get-lockers`;
  const response = await makeBearerJsonApiRequest('GET', endpoint, null);
  return response;
};

export const getLockersByColumns = async (searchText) => {
  const endpoint = `${API_BASE_URL}/get-lockers?searchText=${encodeURIComponent(
    searchText
  )}`;

  const response = await makeBearerJsonApiRequest('GET', endpoint, null);
  return response;
};

export const updatePlanPricing = async (payload) => {
  const endpoint = `${API_BASE_URL}update-plan-pricing`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, payload);
    return response;
  } catch (error) {
    throw new Error('Error updating plan price');
  }
};
