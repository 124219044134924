import React from 'react';
import { Card, Table, Typography } from 'antd';
import moment from 'moment';

const { Title } = Typography;

const LockerInfo = ({ selectedUserData }) => {
  const lockerInfo = selectedUserData?.locker || {};
  const safeInfo = selectedUserData?.safe;
  const boxType = selectedUserData?.boxType;

  const dataSource = [
    {
      key: '1',
      label: 'Box Type',
      value: selectedUserData?.boxType || 'N/A',
    },
    {
      key: '2',
      label: 'Box Number',
      value: selectedUserData?.boxNumber || 'N/A',
    },
    {
      key: '3',
      label: 'Box Size',
      value: selectedUserData?.boxSize || 'N/A',
    },
    {
      key: '4',
      label: 'Status',
      value: lockerInfo?.status ? lockerInfo?.status : safeInfo?.status,
    },
    {
      key: '5',
      label: 'Column',
      value: lockerInfo?.column || 'N/A',
    },
    {
      key: '6',
      label: 'Start Date',
      value:
        boxType === 'safe'
          ? safeInfo?.startDate
            ? moment(safeInfo.startDate).format('DD-MM-YYYY')
            : 'N/A'
          : lockerInfo?.startDate
          ? moment(lockerInfo.startDate).format('DD-MM-YYYY')
          : 'N/A',
    },
    {
      key: '7',
      label: 'Renewal Date',
      value:
        boxType === 'safe'
          ? safeInfo?.renewalDate
            ? moment(safeInfo.renewalDate).format('DD-MM-YYYY')
            : 'N/A'
          : lockerInfo?.renewalDate
          ? moment(lockerInfo.renewalDate).format('DD-MM-YYYY')
          : 'N/A',
    },
  ];

  if (selectedUserData?.boxType === 'safe') {
    dataSource.push({
      key: '8',
      label: 'Complimentary Locker',
      value: selectedUserData?.complimentaryLocker
        ? `${selectedUserData.complimentaryLocker.lockerNumber} (Column: ${selectedUserData.complimentaryLocker.column})`
        : 'N/A',
    });
    const columnIndex = dataSource.findIndex((item) => item.key === '5');
    if (columnIndex !== -1) {
      dataSource.splice(columnIndex, 1);
    }
  }

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      render: (text) => <span className="fs-5 fw-bold">{text}</span>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text) => <span className="fs-6">{text}</span>,
    },
  ];

  if (!selectedUserData) {
    return (
      <div className="d-flex justify-content-center">
        <Card
          style={{
            width: '38rem',
            margin: '20px 0',
            padding: '20px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          }}
          bordered={false}
        >
          <Title className="fs-2 text-decoration-none text-center">
            No Locker / Safe Details Available
          </Title>
        </Card>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-center">
      <Card
        style={{
          width: '38rem',
          margin: '20px 0',
          padding: '20px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        }}
        bordered={false}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          showHeader={false}
          rowKey="key"
        />
      </Card>
    </div>
  );
};

export default LockerInfo;
