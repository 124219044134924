import React, { useEffect, useState } from 'react';
import { FaFingerprint, FaMailBulk, FaUserPlus } from 'react-icons/fa';
import EventTable from './EventTable';
import EventLogin from './EventLogin';
import { useLocation, useNavigate } from 'react-router-dom';
import EventMailStorage from './EventMailStorage';

const buttons = [
  {
    id: 1,
    name: 'Client Login',
    icon: <FaFingerprint className="fs-5" />,
  },
  {
    id: 2,
    name: 'Client Event History',
    icon: <FaUserPlus className="fs-5" />,
  },
  {
    id: 3,
    name: 'Client Mail Storage',
    icon: <FaMailBulk className="fs-5" />,
  },
];

const EventFilterButtons = ({ searchTerm }) => {
  const [activeButton, setActiveButton] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.previousTab) {
      setActiveButton(location.state.previousTab);
    }
  }, [location.state]);

  const handleButtonClick = (id) => {
    setActiveButton(id);
    navigate(location.pathname, { state: { previousTab: id } });
  };

  return (
    <div className="p-2">
      <div className="button-group d-flex justify-content-start ">
        {buttons.map((button) => (
          <button
            key={button.id}
            className={`filter-button w-25 ${
              activeButton === button.id ? 'active' : 'inactive'
            }`}
            onClick={() => handleButtonClick(button.id)}
          >
            <div className="d-flex align-items-center">
              <span className="me-3">{button.icon}</span>
              <span className="button-text">{button.name}</span>
            </div>
          </button>
        ))}
      </div>

      {activeButton === 1 && <EventLogin searchTerm={searchTerm} />}
      {activeButton === 2 && <EventTable searchTerm={searchTerm} />}
      {activeButton === 3 && <EventMailStorage searchTerm={searchTerm} />}
    </div>
  );
};

export default EventFilterButtons;
