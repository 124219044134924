import React, { useState } from 'react';
import { PaymentCalculatioAPI } from '../PamentCalculatioAPI/PaymentCalculatioAPI';
import './PaymentCalculator.css';
import moment from 'moment/moment';
import NavbarWithSearchBar from '../../reusable/reusableComponent/NavbarWithSearchBar';
import HomeFooter from '../../home/HomeFooter/HomeFooter';
import BackButton from '../../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';

const PaymentCalculator = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ size: '', tenure: '' });
  const [PaymentCal, setPaymentCal] = useState('');
  const [errors, setErrors] = useState({});
  const [globalError, setGlobalError] = useState('');

  const curentntDate = moment();
  const daysRemaningOfMonth = moment()
    .endOf('month')
    .diff(curentntDate, 'days');
  const currentMonthName = moment().format('MMMM');

  const validateFields = () => {
    const fieldErrors = {};
    if (!formData.size) {
      fieldErrors.size = 'Locker size is required.';
    }
    if (!formData.tenure) {
      fieldErrors.tenure = 'Duration is required.';
    }
    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0;
  };

  const handleCalculate = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      const response = await PaymentCalculatioAPI(
        formData.size,
        parseInt(formData.tenure)
      );
      setPaymentCal(response.data);
      setErrors({});
      setGlobalError('');
    } catch (err) {
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : 'An error occurred. Please try again.';
      setGlobalError(errorMessage);
    }
  };

  const handleClear = () => {
    setFormData({ size: '', tenure: '' });
    setPaymentCal('');
    setErrors({});
    setGlobalError('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handlePreviousBack = () => {
    navigate(-1);
  };

  return (
    <>
      <NavbarWithSearchBar />
      <div className="p-4">
        <div className="payment-calculator">
          <h2 className="payment-heading">Payment Calculator</h2>
          <p className="remaining-days">
            <span className="days-highlight">{daysRemaningOfMonth}</span> days
            left in <span className="month-name">{currentMonthName}</span>
          </p>

          <div className="form-group-payment form-group mb-4">
            <label className="label-payment">Select Locker Size</label>
            <select
              name="size"
              value={formData.size}
              onChange={handleChange}
              className={`payment-dropdown ${
                errors.size ? 'error-border' : ''
              }`}
            >
              <option value="">Select</option>
              <option value="Small">Small</option>
              <option value="Medium">Medium</option>
              <option value="Large">Large</option>
              <option value="Extra Large">Extra Large</option>
            </select>
            {errors.size && (
              <p className="error-message text-danger">{errors.size}</p>
            )}
          </div>

          <div className="form-group mb-4">
            <label className="label-payment">Select Duration</label>
            <select
              name="tenure"
              value={formData.tenure}
              onChange={handleChange}
              className={`payment-dropdown ${
                errors.tenure ? 'error-border' : ''
              }`}
            >
              <option value="">Select</option>
              {formData.size === 'Small' && (
                <option value="6">Half Yearly</option>
              )}
              <option value="12">Yearly</option>
              <option value="36">3 Year</option>
              <option value="60">5 Year</option>
            </select>
            {errors.tenure && (
              <p className="error-message text-danger">{errors.tenure}</p>
            )}
          </div>

          <div className="payment-button-group mt-5">
            <button className="pay-button clear-button" onClick={handleClear}>
              Clear
            </button>
            <button
              className="pay-button calculate-button"
              onClick={handleCalculate}
            >
              Calculate
            </button>
          </div>

          {globalError && (
            <p className="error text-center mt-3">{globalError}</p>
          )}

          {PaymentCal && (
            <div className="total-amount">
              <p className="total-amount-heading">Total Amount</p>
              <p className="monthly-payment">
                Monthly Payment = <span>£{PaymentCal.monthlyRate}</span>
              </p>
              <p className="yearly-payment">
                Yearly Payment = <span>£{PaymentCal.yearlyRate}</span>
              </p>
              <p className="daily-payment">
                Daily Payment = <span>£{PaymentCal.dailyRate}</span>
              </p>
              <p className="daily-payment">
                Remaining Days Charge ({currentMonthName}) ={' '}
                <span>£{PaymentCal.dailyRate * daysRemaningOfMonth}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="back-button-container">
        <BackButton onClick={handlePreviousBack} />
      </div>
      <HomeFooter />
    </>
  );
};

export default PaymentCalculator;
