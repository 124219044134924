import React from 'react';
import { Button, Input, Modal } from 'antd';

function RemarkModal({ isOpen, onClose, remark, onChange, onSave, clientId }) {
  return (
    <Modal
      title="Remark"
      open={isOpen}
      onCancel={onClose}
      footer={[
        clientId ? (
          <Button key="save" type="primary" onClick={onSave}>
            Save
          </Button>
        ) : (
          <Button key="save" type="success" onClick={onClose}>
            Save
          </Button>
        ),
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Input.TextArea
        value={remark}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter Remark"
      />
    </Modal>
  );
}

export default RemarkModal;
