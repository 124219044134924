import React, { useState } from 'react';
import { Tooltip, message } from 'antd';

function CopyableText({
  text,
  successMessage = 'Copied to clipboard!',
  tooltipMessage = 'Click to copy',
  textStyle = {},
}) {
  const [tooltipText, setTooltipText] = useState(tooltipMessage);

  const handleCopy = () => {
    if (text) {
      navigator.clipboard.writeText(text);
      setTooltipText(successMessage);
      message.success(successMessage);

      setTimeout(() => {
        setTooltipText(tooltipMessage);
      }, 1500);
    }
  };

  return (
    <Tooltip title={tooltipText}>
      <span
        onClick={handleCopy}
        style={{
          cursor: 'pointer',
          color: '#000',
          ...textStyle,
        }}
      >
        {text || '---'}
      </span>
    </Tooltip>
  );
}

export default CopyableText;
