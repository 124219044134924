import React, { useState } from 'react';
import NavbarWithSearchBar from '../reusable/reusableComponent/NavbarWithSearchBar';
import ClientFilterButtons from './ClientFilterButtons';
import HomeFooter from '../home/HomeFooter/HomeFooter';
import { useLocation } from 'react-router-dom';

const ClientHome = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div>
      <NavbarWithSearchBar setSearchTerm={setSearchTerm} />
      <ClientFilterButtons searchTerm={searchTerm} />
      <HomeFooter />
    </div>
  );
};

export default ClientHome;
