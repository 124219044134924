import React, { useEffect, useState } from 'react';
import MakePayment from './MakePayment';
import { TbCreditCardPay } from 'react-icons/tb';
import { RiAccountBoxLine } from 'react-icons/ri';
import { LuUserMinus2 } from 'react-icons/lu';
import { useLocation, useNavigate } from 'react-router-dom';
import FinanceClientTable from './FiananceClientTable';
import ArrearsTable from './ArrearsTable';

const FinancialFilterButton = ({ searchTerm }) => {
  const [activeButton, setActiveButton] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.previousTab) {
      setActiveButton(location.state.previousTab);
    }
  }, [location.state]);

  const handleButtonClick = (id) => {
    setActiveButton(id);
    navigate(location.pathname, { state: { previousTab: id } });
  };
  const buttons = [
    {
      id: 1,
      name: 'Post Payment',
      icon: <TbCreditCardPay className="fs-5" />,
    },
    {
      id: 2,
      name: 'Client Account',
      icon: <RiAccountBoxLine className="fs-5" />,
    },
    {
      id: 3,
      name: 'Arrears',
      icon: <LuUserMinus2 className="fs-5" />,
    },
  ];

  return (
    <div className="p-2">
      <div className="button-group d-flex justify-content-start ">
        {buttons.map((button) => (
          <button
            key={button.id}
            className={`filter-button w-25 me-2 ${
              activeButton === button.id ? 'active' : 'inactive'
            }`}
            onClick={() => handleButtonClick(button.id)}
          >
            <div className="d-flex align-items-center">
              <span className="me-3">{button.icon}</span>
              <span className="button-text">{button.name}</span>
            </div>
          </button>
        ))}
      </div>

      {activeButton === 1 && <MakePayment searchTerm={searchTerm} />}
      {activeButton === 2 && <FinanceClientTable searchTerm={searchTerm} />}
      {activeButton === 3 && <ArrearsTable searchTerm={searchTerm} />}
    </div>
  );
};

export default FinancialFilterButton;
