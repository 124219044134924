import {
  makeBearerJsonApiRequest,
  makeJsonApiRequest,
} from '../../services/ApiService';

const API_URL = process.env.REACT_APP_API_URL;

export const getUpcommingRenewal = async (startDate, endDate) => {
  const endpoint = `${API_URL}get-all-upcomming-renewals`;
  try {
    const response = await makeBearerJsonApiRequest('POST', endpoint, {
      startDate,
      endDate,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const generateReport = async (startDate, endDate) => {
  const endpoint = `${API_URL}generate-report`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, {
      startDate,
      endDate,
    });

    if (response?.status === 200 && response?.success) {
      return response?.data;
    } else {
      throw new Error('Failed to generate the report');
    }
  } catch (error) {
    console.error('Error generating the report:', error);
    throw error;
  }
};
