import { makeBearerJsonApiRequest } from '../../../services/ApiService';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const PaymentCalculatioAPI = async (size, tenure) => {
  const endpoint = `${API_BASE_URL}calculate-payement-data`;

  const payload = {
    size,
    tenure,
  };
  const response = await makeBearerJsonApiRequest('POST', endpoint, payload);
  return response;
};
