import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { IoIosWarning } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { loginUser } from './AuthApi';
import InputField from '../reusable/reusableComponent/InputField';
import FormButtons from '../reusable/reusableComponent/FormButtons';
import { toast } from 'react-toastify';
import AuthSideImage from '../../assets/images/loginImage.png';
import AuthImage from '../reusable/AuthComponents/AuthImage';
import { Spin } from 'antd';
import '../../assets/css/style.css';
import { requestFCMToken } from '../../config/firebaseConfig';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (!email) {
      tempErrors.email = 'Email is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Email is invalid.';
      isValid = false;
    }

    if (!password) {
      tempErrors.password = 'Password is required.';
      isValid = false;
    } else if (password.length < 6) {
      tempErrors.password = 'Password must be at least 6 characters long.';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        const webDeviceToken = await requestFCMToken();
        // const webDeviceToken =
        // ('hdbvshbvbdsbvbshbsdbvhdbhbsuhjbvhdbshvbdbvjbdshbvhdbhvbsb');
        if (!webDeviceToken) {
          console.error('FCM token not retrieved.');
          setLoading(false);
          return;
        }
        const response = await loginUser(email, password, webDeviceToken);
        if (response && response.data && response.data.token) {
          toast.success(response.message);
          navigate('/admin-home');
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        if (error.response) {
          toast.error(
            error.response.data.message || 'Invalid email or password'
          );
        } else if (error.request) {
          toast.error('No response received, please try again');
        } else {
          console.error('An error occurred', error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <div className="loader-overlay">
          <Spin size="large" />
        </div>
      ) : (
        <div className="row">
          <AuthImage imageUrl={AuthSideImage} />

          <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center mb-3">
              <img
                className="img-fluid"
                src={logo}
                alt="Sentinel Vaults Logo"
                style={{ height: '100px' }}
              />
            </div>
            <div className="w-75 px-lg-5 px-md-4">
              <div className="mb-3">
                <h4>Welcome to</h4>
                <h1 className="fw-bolder" style={{ color: '#1F3F5F' }}>
                  Sentinel Vaults
                </h1>
              </div>
              {(errors.email || errors.password) && (
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <IoIosWarning className="me-2" />
                  <div>
                    {errors.email && (
                      <span>
                        {errors.email}
                        {errors.password && ' '}
                      </span>
                    )}
                    {errors.password && <span>{errors.password}</span>}
                  </div>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <h4 className="text-bolder">Log in to your account</h4>
                  <InputField
                    label="Email"
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                  <InputField
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div className="d-flex justify-content-between mb-3">
                  {/* <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="rememberMe"
                    />
                    <label
                      className="form-check-label rememberMe"
                      htmlFor="rememberMe"
                    >
                      Remember me
                    </label>
                  </div> */}
                  <p className="text-end fw-bold forgetColor">
                    <Link
                      to="/forgot-password"
                      style={{ textDecoration: 'none' }}
                    >
                      Forget password?
                    </Link>
                  </p>
                </div>
                <div className="text-center">
                  <FormButtons type="submit">Login</FormButtons>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
