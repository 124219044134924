import { makeJsonApiRequest } from '../../services/ApiService';
import { removeToken } from './AuthService';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const loginUser = async (email, password, webDeviceToken) => {
  const endpoint = `${API_BASE_URL}admin-login`;
  const jsonData = { email, password, webDeviceToken };

  try {
    const response = await makeJsonApiRequest('POST', endpoint, jsonData);
    if (response && response.data && response.data.token) {
      Cookies.set('token', response.data.token);
      Cookies.set('admin-details', JSON.stringify(response.data.existingAdmin));
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const forgetPassword = async (email) => {
  const endpoint = `${API_BASE_URL}forget-password`;
  const jsonData = { email };
  try {
    const response = await makeJsonApiRequest('POST', endpoint, jsonData);
    return response;
  } catch (error) {
    throw error.response ? error.response : error;
  }
};

export const logoutUser = (navigate) => {
  try {
    const result = removeToken();
    if (result) {
      navigate('/');
      return 'Successfully logged out';
    }
  } catch (error) {
    console.error('Logout error:', error);
    throw new Error('Logout failed. Please try again.');
  }
};

export const verifyOTP = async (email, otp) => {
  const endpoint = `${API_BASE_URL}verify-otp`;
  const jsonData = { email, otp };

  try {
    const response = await makeJsonApiRequest('POST', endpoint, jsonData);
    if (response && response.success) {
      return {
        success: true,
        message: response.message || 'OTP verified successfully.',
      };
    } else {
      return {
        success: false,
        error: response.message || 'OTP verification failed.',
      };
    }
  } catch (error) {
    console.error('API error:', error);
    throw new Error(error.message || 'Network error');
  }
};

export const resetPassword = async (email, otp, password) => {
  const endpoint = `${API_BASE_URL}reset-password`;
  const jsonData = { email, otp, password };

  const response = await makeJsonApiRequest('POST', endpoint, jsonData);
  if (response && response.success) {
    return { success: true };
  } else {
    return {
      success: false,
      error: response.error || 'Password update failed',
    };
  }
};

export const updatePassword = async (oldPassword, newPassword) => {
  const endpoint = `${API_BASE_URL}/change-password`;
  const jsonData = { oldPassword, newPassword };

  const response = await makeJsonApiRequest('POST', endpoint, jsonData);

  if (response && response.success) {
    return { success: true };
  } else {
    return {
      success: false,
      error: response.error || 'Password update failed',
    };
  }
};
