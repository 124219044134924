import Cookies from 'js-cookie';

export const storeToken = (token) => {
  Cookies.set('token', token, { expires: 7 });
};

export const getToken = () => {
  return Cookies.get('token');
};

export const removeToken = () => {
  try {
    Cookies.remove('token');
    Cookies.remove('admin-details');
    return true;
  } catch (error) {
    console.error('Error removing token:', error);
    throw new Error('Failed to remove token');
  }
};

export const isAuthenticated = () => {
  const token = getToken();
  return token != null;
};
