import React, { useEffect, useState } from 'react';
import { message, Radio } from 'antd';
import { getForename, makePayment } from './FinancialApi.jsx';
import BackButton from '../../reusable/reusableComponent/BackButton.jsx';
import { useNavigate } from 'react-router-dom';

const MakePayment = () => {
  const [uccNumber, setUccNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchName = async () => {
      if (uccNumber) {
        try {
          const result = await getForename(uccNumber);
          if (result.success) {
            setName(`${result?.foreName || ''} ${result?.surName || ''}`);
          } else {
            setName(result.message);
          }
        } catch (error) {
          message.error('Failed to fetch forename');
        }
      } else {
        setName('');
      }
    };

    fetchName();
  }, [uccNumber]);

  const validateFields = () => {
    const newErrors = {};
    if (!uccNumber) newErrors.uccNumber = 'UCC number is required';
    if (!amount || isNaN(amount) || parseInt(amount, 10) <= 0)
      newErrors.amount = 'Amount must be a valid number greater than 0';
    if (!description) newErrors.description = 'Description is required';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    setLoading(true);
    const paymentAmount = parseInt(amount, 10);
    const dataToSubmit = {
      uccNumber,
      amount: paymentAmount,
      paymentMethod:
        description === 'Non Cash Adjustment (No Payment received)'
          ? 'non_cash'
          : paymentMethod,
      description,
    };

    try {
      const result = await makePayment(dataToSubmit);
      if (result.status === 201) {
        message.success(result.message || 'Payment processed successfully!');
        setUccNumber('');
        setAmount('');
        setDescription('');
        setPaymentMethod('cash');
      } else {
        message.error(result.message || 'Payment failed');
      }
    } catch (err) {
      message.error(`Payment failed: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  return (
    <div className="user-details-container p-3">
      <h4 className="header-text fs-3">MAKE PAYMENT</h4>
      <form onSubmit={handleSubmit}>
        {/* <div className="row mb-3">
          <div className="col-md-6  col-lg-6  d-flex align-items-center">
            <label htmlFor="uccno" className="custom-label">
              UCC NO: <span className="required text-danger">*</span>
            </label>
            <input
              id="uccno"
              name="uccno"
              type="text"
              className="form-control custom-input"
              placeholder="Ucc No."
              value={uccNumber}
              onChange={(e) => setUccNumber(e.target.value)}
            />
          </div>
          {forename && <small className="text-success"> {forename}</small>}
          {errors.uccNumber && (
            <small className="text-danger">{errors.uccNumber}</small>
          )}
          <div className="col-md-6  col-lg-6 d-flex align-items-center">
            <label htmlFor="date-of-payment" className="custom-label">
              DATE OF PAYMENT:<span className="required text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control custom-input"
              value={new Date().toLocaleDateString()}
              readOnly
            />
          </div>
        </div> */}

        <div className="row mb-3">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center mb-0 position-relative">
              <label htmlFor="title" className="custom-label">
                UCC NO: <span className="required text-danger">*</span>
                <br />
              </label>
              <input
                id="uccno"
                name="uccno"
                type="text"
                className="form-control custom-input"
                placeholder="Ucc No."
                value={uccNumber}
                onChange={(e) => setUccNumber(e.target.value)}
              />
            </div>

            <div
              className="error-message position-absolute"
              style={{ left: '9.5rem' }}
            >
              {name && <p className="text-success fs-6 fw-bold">{name}</p>}
              {errors.uccNumber && (
                <small className="text-danger">{errors.uccNumber}</small>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="dob" className="custom-label">
                DATE OF PAYMENT:<span className="required text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control custom-input"
                value={new Date().toLocaleDateString()}
                readOnly
              />
            </div>
          </div>
        </div>

        {/* <div className="row d-flex">
          <div className="col-md-6 mb-3 d-flex align-items-center">
            <label htmlFor="amount" className="custom-label">
              AMOUNT:<span className="required text-danger">*</span>
            </label>
            <input
              id="amount"
              name="amount"
              type="text"
              className="form-control custom-input"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />{' '}
            {errors.amount && (
              <small className="text-danger">{errors.amount}</small>
            )}
          </div>

          <div className="col-md-6 mb-3 d-flex align-items-center">
            <label htmlFor="description" className="custom-label">
              DESCRIPTION:<span className="required text-danger">*</span>
            </label>
            <select
              id="description"
              className="form-control custom-input"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                if (
                  e.target.value === 'Non Cash Adjustment (No Payment received)'
                ) {
                  setPaymentMethod('non_cash');
                } else {
                  setPaymentMethod('cash');
                }
              }}
            >
              <option value="">Select Description</option>
              <option value="Box Fee received ">Box Fee Received </option>
              <option value="Key Deposit received ">
                Key Deposit Received{' '}
              </option>
              <option value="Renewal Fee">Renewal Fee</option>
              <option value="Non Cash Adjustment (No Payment received)">
                Non Cash Adjustment (No Payment received)
              </option>
            </select>{' '}
            {errors.description && (
              <small className="text-danger">{errors.description}</small>
            )}
          </div>
        </div> */}

        <div className="row mb-3">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center mb-0 position-relative">
              <label htmlFor="title" className="custom-label">
                AMOUNT:<span className="required text-danger">*</span>
                <br />
              </label>
              <input
                id="amount"
                name="amount"
                type="text"
                className="form-control custom-input"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>

            <div
              className="error-message position-absolute"
              style={{ left: '9.5rem' }}
            >
              {errors.amount && (
                <small className="text-danger">{errors.amount}</small>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="d-flex align-items-center position-relative">
              <label htmlFor="dob" className="custom-label">
                DESCRIPTION:<span className="required text-danger">*</span>
              </label>
              <select
                id="description"
                className="form-control custom-input"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (
                    e.target.value ===
                    'Non Cash Adjustment (No Payment received)'
                  ) {
                    setPaymentMethod('non_cash');
                  } else {
                    setPaymentMethod('cash');
                  }
                }}
              >
                <option value="">Select Description</option>
                <option value="Box Fee received ">Box Fee Received </option>
                <option value="Key Deposit received ">
                  Key Deposit Received{' '}
                </option>
                <option value="Renewal Fee">Renewal Fee</option>
                <option value="Non Cash Adjustment (No Payment received)">
                  Non Cash Adjustment (No Payment received)
                </option>
              </select>{' '}
            </div>
            <div
              className="error-message position-absolute"
              // style={{ left: '9.5rem' }}
            >
              {errors.description && (
                <small className="text-danger">{errors.description}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row mb-3 mt-2">
          <label className="custom-label">
            Payment Method:<span className="required text-danger">*</span>
          </label>
          <div className="d-flex justify-content-between">
            <Radio.Group
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              className="w-100 fs-4"
              disabled={
                description === 'Non Cash Adjustment (No Payment received)'
              }
            >
              <Radio value="cash" className="fs-6">
                Cash
              </Radio>
              <Radio value="cc" className="fs-6 text-center">
                CC
              </Radio>
              <Radio value="dc" className="fs-6 text-center">
                DC
              </Radio>
              <Radio value="bt" className="fs-6">
                BT
              </Radio>
              <Radio value="so" className="fs-6">
                SO
              </Radio>
            </Radio.Group>
          </div>
        </div>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="btn d-flex justify-content-end">
            <button type="submit" className="btn next-btn">
              Make Payment
            </button>
          </div>
        )}
      </form>
      <div className="back-button-container">
        <BackButton onClick={handleBackPrevious} />
      </div>
    </div>
  );
};

export default MakePayment;
