import React from 'react';
import PropTypes from 'prop-types';

const AuthImage = ({ imageUrl }) => {
  return (
    <div
      className="col-lg-6 col-md-6 col-sm-12 d-none d-md-block"
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${imageUrl})`,
        height: '100vh',
      }}
    ></div>
  );
};

AuthImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default AuthImage;
