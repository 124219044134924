import React from 'react';
import { Typography, Table } from 'antd';
import moment from 'moment';
import '../resusableStyle/TransactionHistoryTab.css';

const { Title, Text } = Typography;

const TransactionHistoryTab = ({ selectedUserData }) => {
  const transactionHistory = selectedUserData?.transactionHistory || [];

  const columns = [
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      render: (text) => text || '---',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (text) => text || '---',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      render: (text) => (
        <span className={parseFloat(text) > 0 ? 'text-danger fw-bold' : ''}>
          {text ? `£${parseFloat(text).toLocaleString()}` : '---'}
        </span>
      ),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      render: (text) => (
        <span className={parseFloat(text) > 0 ? 'text-success fw-bold' : ''}>
          {text ? `£${parseFloat(text).toLocaleString()}` : '---'}
        </span>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (text) => {
        if (text === undefined || text === null) return '---';
        const balance = parseFloat(text);
        const isNegative = balance < 0;
        const formattedBalance = isNegative
          ? `-£${Math.abs(balance).toLocaleString()}`
          : `£${balance.toLocaleString()}`;
        return (
          <span className={`fw-bold ${isNegative ? 'text-danger' : ''}`}>
            {formattedBalance}
          </span>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (text) =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
    },
  ];

  return (
    <>
      <Title className="fs-2 text-decoration-none text-center">
        Transaction History
      </Title>
      {transactionHistory.length > 0 ? (
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={transactionHistory}
            rowKey="_id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: true }}
          />
        </div>
      ) : (
        <Text style={{ display: 'block', textAlign: 'center' }}>
          No transaction history available.
        </Text>
      )}
    </>
  );
};

export default TransactionHistoryTab;
