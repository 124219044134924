import React from 'react';
import NotFound from '../../../assets/images/not-found.png';
import { FaFrown } from 'react-icons/fa';

const ResultNotFound = ({ error, searchTerm }) => {
  return (
    <div style={{ textAlign: 'center', color: '#FF6B6B' }}>
      <img
        src={NotFound}
        alt="Not Found"
        style={{ width: '350px', height: '350px' }}
      />
      <div style={{ fontSize: '24px' }}>
        {error ? error : `Search result not found for "${searchTerm}"`}
      </div>
      {error && <FaFrown style={{ fontSize: '36px' }} />}
    </div>
  );
};

export default ResultNotFound;
