import React, { useState, useEffect } from 'react';
import NavbarWithSearchBar from '../../reusable/reusableComponent/NavbarWithSearchBar';
import LockerTable from './LockerTable';
import HomeFooter from '../../home/HomeFooter/HomeFooter';

const LockerHome = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div>
      <NavbarWithSearchBar setSearchTerm={setSearchTerm} />
      <LockerTable searchTerm={searchTerm} />
      <HomeFooter />
    </div>
  );
};

export default LockerHome;
