import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { archiveUserDetails, ClientViewDetailsAPI } from './ClientApi';
import { updateUserDetails, uploadImageToAPI } from './ClientApi';
import { FiSave } from 'react-icons/fi';
import IdentificationDocuments from '../reusable/Client/DocumentUpload';
import BackButton from '../reusable/reusableComponent/BackButton';
import RemarkModal from '../reusable/Client/RemarkModal';
import { toast } from 'react-toastify';
import { ImForward } from 'react-icons/im';
import './client-style/ClientViewDetails.css';
import { useNavigate } from 'react-router-dom';
import { nationalities } from '../reusable/reusableComponent/nationalities';
import { DatePicker, Image } from 'antd';
import moment from 'moment';
import NomineeOneDetailsEdit from './NomineeOneDetailsEdit';
import NomineeTwoDetailsEdit from './NomineeTwoDetailsEdit';
import { validateUserDetails } from '../../services/Utils';
import Swal from 'sweetalert2';
import { IoCloseCircleSharp } from 'react-icons/io5';

function ClientViewDetails({ clientDetails, onClose, fetchClientData }) {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [showArchiveTable, setShowArchiveTable] = useState(false);
  const [clientImageShow, setClientImageShow] = useState(
    clientDetails?.clientPic || null
  );
  const [identificationDocument, setIdentificationDocument] = useState();
  const [
    nomineeOneIdentificationDocument,
    setNomineeOneIdentificationDocument,
  ] = useState([]);

  const [nomineeOneImageShow, setNomineeOneImageShow] = useState();
  const [nomineeTwoImageShow, setNomineeTwoImageShow] = useState();
  const [
    nomineeTwoIdentificationDocument,
    setnomineeTwoIdentificationDocument,
  ] = useState([]);
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });
  const [nomineeTwoDocuments, setNomineeTwoDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });

  const [isloading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [remark, setRemark] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = formData.transactionHistory
    ? formData.transactionHistory.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F10') {
        event.preventDefault();
        setShowPopup(true);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const fileInputRef = useRef(null);

  const fetchedData = async () => {
    try {
      const response = await ClientViewDetailsAPI(clientDetails?._id);
      if (response.success && response.data) {
        const client = response.data;
        setFormData(client);
        setIdentificationDocument(client?.identificationDocument || []);
        setRemark(client?.remark);
        setNomineeOneIdentificationDocument(
          client?.nomineeOne?.identificationDocument
        );
        setnomineeTwoIdentificationDocument(
          client?.nomineeTwo?.identificationDocument
        );
        setClientImageShow(client?.clientPic);
        setNomineeOneImageShow(client?.nomineeOne?.nomineePic);
        setNomineeTwoImageShow(client?.nomineeTwo?.nomineePic);
      } else {
        console.error('API Error:', response.message);
      }
    } catch (error) {
      console.error('error: ', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchedData();
  }, [clientDetails]);

  const handleFileUpload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (formData?.nomineeTwo?.identificationDocument) {
      setnomineeTwoIdentificationDocument(
        formData?.nomineeTwo?.identificationDocument
      );

      const initialDocuments = { ...nomineeTwoDocuments };

      const documentUrls = formData?.nomineeTwo?.identificationDocument.map(
        (doc) => doc.documentUrl
      );
      initialDocuments.documentUrls = documentUrls;

      setDocuments(initialDocuments);
    }
  }, [formData?.nomineeTwo?.identificationDocument]);

  const handleFileChange = async (e, docName) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const fileURL = await uploadImageToAPI(formData);
        setIdentificationDocument([
          {
            documentType: docName,
            documentUrl: fileURL[0],
          },
        ]);
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fileUploaded: error.message,
        }));
      }
    }
  };

  const handleClientFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const fileURL = await uploadImageToAPI(formData);
        setClientImageShow(fileURL[0]);
      } catch (error) {
        setErrors({ fileUploaded: error.message });
      }
    }
  };

  const handleShowArchiveTable = async () => {
    const payload = {
      userId: clientDetails._id,
      isArchived: true,
    };
    setIsLoading(true);
    try {
      const response = await archiveUserDetails(payload);
      if (response.success) {
        Swal.fire({
          title: 'Success!',
          text: response.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        setShowArchiveTable(true);
      } else {
        Swal.fire({
          title: 'Error!',
          html: `<span style="color: red; font-weight: bold;">${response.message}</span>`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('API error during archiving:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setErrors({});

    const validationErrors = validateUserDetails(formData, documents);

    if (!clientImageShow && !formData.clientPic) {
      validationErrors.clientPic = 'Please upload a client picture.';
    }

    // const isDocumentUploaded = Object.entries(documents).some(
    //   ([docName, isChecked]) =>
    //     isChecked ||
    //     identificationDocument.some((doc) => doc.documentType === docName)
    // );

    // if (!isDocumentUploaded) {
    //   validationErrors.documents =
    //     'Please upload at least one identification document.';
    // }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const dataToSubmit = {
      userId: clientDetails._id,
      email: formData.email,
      title: formData.title,
      foreName: formData.foreName,
      surName: formData.surName,
      dob: formData.dob,
      street: formData.street,
      town: formData.town,
      postcode: formData.postcode,
      telephone: formData.telephone,
      clientPic: clientImageShow,
      nationality: formData.nationality,
      identificationDocument: identificationDocument,
    };
    try {
      const response = await updateUserDetails(JSON.stringify(dataToSubmit));

      if (response && response.success) {
        toast.success(response.message);
        fetchClientData();
        fetchedData();
        setIsEditing(!isEditing);
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      console.error('Error submitting user details:', error);
      setErrors({ submit: 'Error submitting user details. Please try again.' });
    }
  };

  const handleRemarkUpdate = async () => {
    try {
      const response = await updateUserDetails({
        userId: clientDetails?._id,
        remark,
      });
      setFormData((prevData) => ({
        ...prevData,
        remark,
      }));
      setShowPopup(false);
      return response;
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const handleBackPrevious = () => {
    navigate(-1);
    fetchClientData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('nomineeOne.')) {
      const nomineeKey = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        nomineeOne: {
          ...prev.nomineeOne,
          [nomineeKey]: value,
        },
      }));
    }
    if (name.startsWith('nomineeTwo.')) {
      const nomineeKey = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        nomineeTwo: {
          ...prev.nomineeTwo,
          [nomineeKey]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRemoveImage = () => {
    setClientImageShow('');
    setFormData((prevFormData) => ({
      ...prevFormData,
      clientPic: '',
    }));
  };

  const handleRemoveDocument = () => {
    setIdentificationDocument([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      identificationDocument: [],
    }));
  };

  return (
    <div className="user-details-container mt-5 p-3">
      <div className="back-button-container">
        <BackButton onClick={handleBackPrevious} />
      </div>
      <div>
        <div className="header mb-4 d-flex justify-content-between">
          <div className="d-flex align-items-baseline">
            <h6 className="header-text me-3 fs-4">CLIENT HOLDER DETAILS</h6>
            <button
              className="btn-button save-btn btn float-end"
              onClick={handleSave}
            >
              <FiSave className="icon-edit-save me-2" />
              Save
            </button>
          </div>
          <button
            className="btn btn-move-archive"
            onClick={handleShowArchiveTable}
            type="button"
          >
            Move to Archive <ImForward className="mr-2 fs-5" />
          </button>
        </div>

        <form>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="title" className="custom-label">
                  Title:
                </label>

                <select
                  id="title"
                  name="title"
                  className={`form-control select-title custom-input-box w-100 ${
                    errors.title ? 'border-danger' : ''
                  }`}
                  value={formData.title || ''}
                  onChange={handleChange}
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </select>
              </div>

              <div className="error-message">
                {errors.title && (
                  <span className="text-danger">{errors.title}</span>
                )}
              </div>

              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="forenames" className="custom-label">
                  Forename:
                </label>
                <input
                  id="foreName"
                  name="foreName"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.foreName ? 'border-danger' : ''
                  }`}
                  placeholder="Forename   "
                  value={formData.foreName}
                  onChange={handleChange}
                />
              </div>

              <div className="error-message">
                {errors.foreName && (
                  <span className="text-danger">{errors.foreName}</span>
                )}
              </div>

              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="tel" className="custom-label">
                  Tel:
                </label>
                <input
                  id="telephone"
                  name="telephone"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.telephone ? 'border-danger' : ''
                  }`}
                  placeholder="Telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                />
              </div>
              <div className="error-message">
                {errors.telephone && (
                  <span className="text-danger">{errors.telephone}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="dob" className="custom-label">
                  DOB:
                </label>
                <DatePicker
                  id="dob"
                  name="dob"
                  className={`custom-input-box mt-1 w-100 custom-datepicker ${
                    errors.dob ? 'border-danger' : ''
                  }`}
                  style={{
                    height: '40px',
                    border: '1px solid rgb(206 212 217)',
                  }}
                  format="DD-MM-YYYY"
                  value={
                    formData?.dob ? moment(formData?.dob, 'DD-MM-YYYY') : null
                  }
                  placeholder="Select Date of Birth"
                  onChange={(date, dateString) => {
                    setFormData((prev) => ({
                      ...prev,
                      dob: dateString,
                    }));
                  }}
                />
              </div>
              <div className="error-message">
                {errors.dob && (
                  <span className="text-danger">{errors.dob}</span>
                )}
              </div>
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="surnames" className="custom-label">
                  Surnames:
                </label>
                <input
                  id="surName"
                  name="surName"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.surName ? 'border-danger' : ''
                  }`}
                  placeholder="Surnames"
                  value={formData.surName}
                  onChange={handleChange}
                />
              </div>
              <div className="error-message">
                {errors.surName && (
                  <span className="text-danger">{errors.surName}</span>
                )}
              </div>
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="email" className="custom-label">
                  Email:
                </label>
                <input
                  id="email"
                  name="email"
                  className={`form-control custom-input-box w-100 ${
                    errors.email ? 'border-danger' : ''
                  }`}
                  type="text"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="error-message">
                {errors.email && (
                  <span className="text-danger me-5">{errors.email}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center mb-3">
                <label htmlFor="street" className="custom-label">
                  Street:
                </label>
                <input
                  id="street"
                  name="street"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.street ? 'border-danger' : ''
                  }`}
                  placeholder="Street"
                  value={formData.street}
                  onChange={handleChange}
                />
              </div>
              <div className="error-message">
                {errors.street && (
                  <span className="text-danger">{errors.street}</span>
                )}
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center mb-3">
                <label htmlFor="town" className="custom-label">
                  Town:
                </label>
                <input
                  id="town"
                  name="town"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.town ? 'border-danger' : ''
                  }`}
                  placeholder="Town"
                  value={formData.town}
                  onChange={handleChange}
                />
              </div>
              <div className="error-message">
                {errors.town && (
                  <span className="text-danger">{errors.town}</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="postcode" className="custom-label">
                  Postcode:
                </label>
                <input
                  id="postcode"
                  name="postcode"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.postcode ? 'border-danger' : ''
                  }`}
                  placeholder="Postcode"
                  value={formData.postcode}
                  onChange={handleChange}
                />
              </div>
              <div className="error-message">
                {errors.postcode && (
                  <span className="text-danger">{errors.postcode}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nationality" className="custom-label">
                  Nationality:
                </label>
                <select
                  id="nationality"
                  name="nationality"
                  className={`form-select-dropdown w-100 ${
                    errors.nationality ? 'border-danger' : ''
                  }`}
                  value={formData.nationality}
                  onChange={handleChange}
                >
                  <option value="">Select Nationality</option>
                  {nationalities.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="error-message">
                {errors.nationality && (
                  <span className="text-danger">{errors.nationality}</span>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex">
            <div className="upload-section mt-5 col-sm-12 col-md-9 col-lg-9">
              <h6 className="header-text">CLIENT PROFILE PICTURE</h6>
              <div>
                <div className="drop-box d-flex justify-content-between align-items-center">
                  <div className="upload-text">
                    <p className="fs-4 upload-instruction">
                      <MdOutlineFileUpload /> Drag and Drop file here or{' '}
                      <span className="upload-link fw-bold">Upload</span>
                    </p>
                    <p className="upload-supported">
                      Files Supported: JPG, JPEG, PNG
                    </p>
                  </div>
                  <div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      name="clientPic"
                      accept="image/png, image/jpeg"
                      className="d-none"
                      onChange={handleClientFileChange}
                    />
                    <button
                      className="btn upload-button fw-bolder"
                      onClick={handleFileUpload}
                    >
                      Upload Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 client-profile-pic d-flex align-items-end">
              {clientImageShow ? (
                <div className="position-relative">
                  <Image
                    src={clientImageShow}
                    alt="Client Profile"
                    style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '12px',
                      border: '1px solid #ddd',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                      marginLeft: '15px',
                    }}
                  />
                  <IoCloseCircleSharp
                    className="image-close-icon"
                    onClick={handleRemoveImage}
                  />
                </div>
              ) : (
                <p>No profile picture available</p>
              )}
            </div>
          </div>
          {errors.clientPic && (
            <span className="text-danger">{errors.clientPic}</span>
          )}

          <IdentificationDocuments
            documents={documents}
            setDocuments={setDocuments}
            identificationDocument={identificationDocument}
            handleFileChange={handleFileChange}
            errors={errors}
            handleRemoveDocument={handleRemoveDocument}
          />

          <div>
            <NomineeOneDetailsEdit
              formData={formData}
              setFormData={setFormData}
              fetchedData={fetchedData}
            />
          </div>

          <div>
            <NomineeTwoDetailsEdit
              formData={formData}
              setFormData={setFormData}
              fetchedData={fetchedData}
            />
          </div>
        </form>
      </div>

      {showArchiveTable && onClose()}
      <div className="back-button-container">
        <BackButton onClick={onClose} />
      </div>
      <RemarkModal
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        remark={remark}
        onChange={setRemark}
        onSave={handleRemarkUpdate}
        clientId={clientDetails?._id}
      />
    </div>
  );
}

export default ClientViewDetails;
