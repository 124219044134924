import React from 'react';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import AuthSideImage from '../../assets/images/loginImage.png';
import AuthImage from '../reusable/AuthComponents/AuthImage';
import '../../assets/css/style.css';

function ResetPasswordSuccessfull() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/');
  };

  return (
    <div className="container-fluid">
      <div className="row min-vh-100">
        <AuthImage imageUrl={AuthSideImage} />
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
          <div className="text-center mb-5">
            <img
              className="img-fluid"
              src={logo}
              alt="Sentinel Vaults Logo"
              style={{ height: '100px' }}
            />
          </div>
          <div className="text-center" style={{ width: '80%' }}>
            <form onSubmit={handleLogin}>
              <h3 className="fw-bold mb-3">Successful password reset!</h3>
              <p className="mb-4 text-muted text-center">
                You can now use your new password to login to your account
              </p>
              <button
                type="submit"
                className="btn btn-primary form-control rounded-3"
                style={{ backgroundColor: '#1F3F5F', border: 'none' }}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordSuccessfull;
