import React, { useEffect, useRef, useState } from 'react';
import '../Client/UserDetails.css';
import { MdOutlineFileUpload } from 'react-icons/md';
import { validateNomineeDetails } from '../../../services/Utils';
import { uploadImageToAPI } from '../../client-management/ClientApi';
import { Image, Spin, Collapse, DatePicker } from 'antd';
import moment from 'moment';
import { nationalities } from '../reusableComponent/nationalities';
import { IoCloseCircleSharp } from 'react-icons/io5';

const { Panel } = Collapse;

const NomineeDetails = ({
  nextStep,
  prevStep,
  handleChange,
  formData,
  setFormData,
}) => {
  console.log('formData:1234567 ', formData);
  const [documents, setDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });
  const [nomineeTwoDocuments, setNomineeTwoDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });
  const [nomineeTwoUploadedFile, setNomineeTwoUploadedFile] = useState({});
  const [fileUploaded, setFileUploaded] = useState({});
  const [fileUploadedNomineeTwo, setFileUploadedNomineeTwo] = useState({});
  const [errors, setErrors] = useState({});
  console.log('errors: ', errors);
  const [isLoading, setIsLoading] = useState(false);
  const [identificationDocument, setIdentificationDocument] = useState([]);
  const [
    nomineeTwoIdentificationDocument,
    setnomineeTwoIdentificationDocument,
  ] = useState([]);

  useEffect(() => {
    if (Array.isArray(formData.nomineeOne.identificationDocument)) {
      setIdentificationDocument(formData.nomineeOne.identificationDocument);

      const documentUrls = formData.nomineeOne.identificationDocument.map(
        (doc) => doc.documentUrl
      );

      setDocuments((prevDocuments) => ({
        ...prevDocuments,
        documentUrls,
      }));
    } else {
      console.error(
        'Unexpected format for identificationDocument:',
        formData.nomineeOne.identificationDocument
      );
    }
  }, [formData.nomineeOne.identificationDocument]);

  useEffect(() => {
    if (formData.nomineeTwo.identificationDocument) {
      setnomineeTwoIdentificationDocument(
        formData.nomineeTwo.identificationDocument
      );

      const initialDocuments = { ...nomineeTwoDocuments };

      const documentUrls = formData.nomineeTwo.identificationDocument.map(
        (doc) => doc.documentUrl
      );
      initialDocuments.documentUrls = documentUrls;

      setDocuments(initialDocuments);
    }
  }, [formData.nomineeTwo.identificationDocument]);

  const fileInputRef = useRef(null);
  const documentInputsRef = useRef({});

  const fileInputNomineeTwoRef = useRef(null);
  const documentInputNomineeTwosRef = useRef({});

  const handleCheckboxChange = (e, docName) => {
    const { checked } = e.target;

    if (checked) {
      setDocuments({
        passport: false,
        driversLicense: false,
        bankStatement: false,
        councilTax: false,
        utilityBill: false,
        [docName]: true,
      });
    } else {
      setDocuments((prevState) => ({
        ...prevState,
        [docName]: false,
      }));
    }
  };

  const handleCheckboxNomineeTwoChange = (event) => {
    const { name, checked } = event.target;
    setNomineeTwoDocuments((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileNomineeTwoUpload = (e) => {
    e.preventDefault();
    fileInputNomineeTwoRef.current.click();
  };

  const handleFileChange = async (e, docName) => {
    setFormData(() => {
      return {
        ...formData,
        nomineeOne: {
          ...formData.nomineeOne,
          identificationDocument: [],
        },
      };
    });

    const file = e.target.files[0];
    if (file) {
      const nomineeData = new FormData();
      nomineeData.append('file', file);
      setIsLoading(true);
      try {
        const fileURL = await uploadImageToAPI(nomineeData);

        setFormData((prevFormData) => ({
          ...prevFormData,
          nomineeOne: {
            ...prevFormData.nomineeOne,
            identificationDocument: [
              ...formData.nomineeOne.identificationDocument,
              {
                documentType: docName,
                documentUrl: fileURL[0],
              },
            ],
          },
        }));
      } catch (error) {
        setErrors({ fileUploaded: error.message });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFileChangeNomineeTwo = async (e, docName) => {
    setFormData(() => {
      return {
        ...formData,
        nomineeTwo: {
          ...formData.nomineeTwo,
          identificationDocument: [],
        },
      };
    });

    const file = e.target.files[0];
    if (file) {
      setNomineeTwoUploadedFile((prev) => ({
        ...prev,
        [docName]: file,
      }));

      const nomineeData = new FormData();
      nomineeData.append('file', file);
      setIsLoading(true);
      try {
        const fileURL = await uploadImageToAPI(nomineeData);

        setFormData((prevFormData) => ({
          ...prevFormData,
          nomineeTwo: {
            ...prevFormData.nomineeTwo,
            identificationDocument: [
              ...prevFormData.nomineeTwo.identificationDocument,
              {
                documentType: docName,
                documentUrl: fileURL[0],
              },
            ],
          },
        }));
      } catch (error) {
        setErrors({ fileUploadedNomineeTwo: error.message });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClientFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setFileUploaded(true);
      setIsLoading(true);
      try {
        const fileURL = await uploadImageToAPI(formData);
        handleChange(
          'nomineeOne',
          'nomineePic'
        )({ target: { value: fileURL[0] } });
      } catch (error) {
        const updatedErrors = { ...errors, fileUploaded: error.message };
        setErrors(updatedErrors);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNomineeTwoFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setFileUploadedNomineeTwo(true);
      setIsLoading(true);
      try {
        const fileURL = await uploadImageToAPI(formData);
        handleChange(
          'nomineeTwo',
          'nomineePic'
        )({ target: { value: fileURL[0] } });
      } catch (error) {
        console.log('error: ', error);
        setErrors({ fileUploadedNomineeTwo: error.message });
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleNext = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = validateNomineeDetails(
  //     formData.nomineeOne,
  //     formData.nomineeTwo,
  //     documents,
  //     nomineePicFile,
  //     nomineeTwoDocuments,
  //     nomineeTwoPicFile
  //   );

  //   if (!formData?.nomineeOne?.nomineePic) {
  //     validationErrors.fileUploaded =
  //       'Please upload a nominee picture for nominee one.';
  //   }

  //   const isDocumentSelected = Object.keys(documents).some(
  //     (key) => documents[key] === true
  //   );

  //   const isDocumentUploaded = identificationDocument.length > 0;

  //   if (!isDocumentSelected && !isDocumentUploaded) {
  //     validationErrors.documents =
  //       'Please upload at least one identification document.';
  //   }

  //   if (
  //     !formData.identificationDocument ||
  //     formData.identificationDocument.length === 0
  //   ) {
  //     validationErrors.documents =
  //       'Please upload at least one identification document.';
  //   }

  //   const isNomineeTwoDocumentUploaded = Object.keys(nomineeTwoDocuments).some(
  //     (key) =>
  //       nomineeTwoDocuments[key] ||
  //       nomineeTwoIdentificationDocument.some((doc) => doc.documentType === key)
  //   );

  //   if (Object.keys(validationErrors).length > 0) {
  //     const errorMessage =
  //       validationErrors.ProfileUploadedNomineeTwo ||
  //       validationErrors.fileUploaded ||
  //       validationErrors.documents ||
  //       validationErrors.documentsNomineeTwo;
  //     toast.error(errorMessage || 'Please fix the errors and try again.');
  //     setErrors(validationErrors);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const dataToSubmit = {
  //     ...formData,
  //     nomineeOne: {
  //       ...formData.nomineeOne,
  //     },
  //     nomineeTwo: {
  //       ...formData.nomineeTwo,
  //       identificationDocument: nomineeTwoIdentificationDocument,
  //     },
  //   };

  //   nextStep();
  // };

  const handleNext = async (e) => {
    e.preventDefault();

    const validationErrors = validateNomineeDetails(
      formData.nomineeOne,
      formData.nomineeTwo,
      documents,
      identificationDocument,
      nomineeTwoDocuments,
      nomineeTwoIdentificationDocument
    );

    console.log('Validation Errors:', validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    const dataToSubmit = {
      ...formData,
      nomineeOne: {
        ...formData.nomineeOne,
      },
      nomineeTwo: {
        ...formData.nomineeTwo,
        identificationDocument: nomineeTwoIdentificationDocument,
      },
    };

    nextStep();
  };

  const handleRemoveImage = () => {
    handleChange('nomineeOne', 'nomineePic')({ target: { value: '' } });
  };

  const handleRemoveImageNomineeTwo = () => {
    handleChange('nomineeTwo', 'nomineePic')({ target: { value: '' } });
  };

  const handleRemoveDocument = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nomineeOne: {
        ...prevFormData.nomineeOne,
        identificationDocument: [],
      },
    }));
  };

  const handleRemoveDocumentNomineeTwo = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nomineeTwo: {
        ...prevFormData.nomineeTwo,
        identificationDocument: [],
      },
    }));
  };

  return (
    <div className="user-details-container mt-4 p-4">
      <div className="header mb-4">
        <h6 className="header-text">NOMINEE ACCOUNT HOLDER DETAILS</h6>
      </div>
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner-container">
            <Spin size="large" />
          </div>
        </div>
      )}
      <form className={isLoading ? 'blur-background' : ''}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center mb-0">
              <label htmlFor="title" className="custom-label">
                Title:
                <br />
                <span className="subTitle">(Mr/Mrs/Miss/Ms/Dr):</span>
              </label>
              <select
                id="nomineeTitle"
                name="nomineeTitle"
                className={`form-control select-title custom-input-box w-100 ${
                  errors.title ? 'border-danger' : ''
                }`}
                value={formData?.nomineeOne?.nomineeTitle || ''}
                onChange={handleChange('nomineeOne', 'nomineeTitle')}
              >
                <option value="">Select Title</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
              </select>
            </div>
            <div className="error-message">
              {errors.title && (
                <span className="text-danger">{errors.title}</span>
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="dob" className="custom-label">
                DOB:
              </label>
              <DatePicker
                id="nomineeDob"
                name="nomineeDob"
                className={`custom-input-box mt-1 w-100 custom-datepicker ${
                  errors.dob ? 'border-danger' : ''
                }`}
                style={{ height: '40px', border: '1px solid rgb(206 212 217)' }}
                format="DD-MM-YYYY"
                value={
                  formData?.nomineeOne?.nomineeDob
                    ? moment(formData?.nomineeOne?.nomineeDob, 'DD-MM-YYYY')
                    : null
                }
                onChange={(date) => {
                  if (date) {
                    const selectedDate = new Date(date.format('YYYY-MM-DD'));
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    if (selectedDate >= today) {
                      errors.dob =
                        'Date of birth cannot be today or in the future';
                    } else {
                      errors.dob = '';
                    }
                    handleChange(
                      'nomineeOne',
                      'nomineeDob'
                    )({
                      target: { value: date.format('DD-MM-YYYY') },
                    });
                  } else {
                    errors.dob = '';
                    handleChange(
                      'nomineeOne',
                      'nomineeDob'
                    )({
                      target: { value: '' },
                    });
                  }
                }}
                placeholder="Select Date of Birth"
              />
            </div>
            <div className="error-message">
              {errors.dob && <span className="text-danger">{errors.dob}</span>}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="foreName" className="custom-label">
                Forename:
              </label>
              <input
                id="nomineeForename"
                name="nomineeForename"
                type="text"
                className={`form-control custom-input-box ${
                  errors.forenames ? 'border-danger' : ''
                }`}
                placeholder="Forenames"
                value={formData?.nomineeOne?.nomineeForename}
                onChange={handleChange('nomineeOne', 'nomineeForename')}
              />
            </div>

            <div className="error-message">
              {errors.forenames && (
                <span className="text-danger">{errors.forenames}</span>
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="surName" className="custom-label">
                Surname:
              </label>
              <input
                id="nomineeSurname"
                name="nomineeSurname"
                type="text"
                className={`form-control custom-input-box ${
                  errors.surnames ? 'border-danger' : ''
                }`}
                placeholder="Surname"
                value={formData?.nomineeOne?.nomineeSurname || ''}
                onChange={handleChange('nomineeOne', 'nomineeSurname')}
              />
            </div>

            <div className="error-message">
              {errors.surnames && (
                <span className="text-danger">{errors.surnames}</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="telephone" className="custom-label">
                Telephone:
              </label>
              <input
                id="nomineeTel"
                name="nomineeTel"
                type="text"
                className={`form-control custom-input-box ${
                  errors.telephone ? 'border-danger' : ''
                }`}
                placeholder="telephone"
                value={formData?.nomineeOne?.nomineeTel || ''}
                onChange={handleChange('nomineeOne', 'nomineeTel')}
              />
            </div>
            <div className="error-message">
              {errors.telephone && (
                <span className="text-danger">{errors.telephone}</span>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="email" className="custom-label">
                Email:
              </label>
              <input
                id="nomineeEmail"
                name="nomineeEmail"
                type="text"
                className={`form-control custom-input-box ${
                  errors.email ? 'border-danger' : ''
                }`}
                placeholder="Email"
                value={formData?.nomineeOne?.nomineeEmail || ''}
                onChange={handleChange('nomineeOne', 'nomineeEmail')}
              />
            </div>
            <div className="error-message">
              {errors.email && (
                <span className="text-danger me-5">{errors.email}</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="street" className="custom-label">
                Street:
              </label>
              <input
                id="nomineeStreet"
                name="nomineeStreet"
                type="text"
                className={`form-control custom-input-box w-100 ${
                  errors.street ? 'border-danger' : ''
                }`}
                placeholder="Street"
                value={formData?.nomineeOne?.nomineeStreet}
                onChange={handleChange('nomineeOne', 'nomineeStreet')}
              />
            </div>
            <div className="error-message">
              {errors.street && (
                <span className="text-danger">{errors.street}</span>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="town" className="custom-label">
                Town:
              </label>
              <input
                id="nomineeTown"
                name="nomineeTown"
                type="text"
                className={`form-control custom-input-box w-100 ${
                  errors.town ? 'border-danger' : ''
                }`}
                placeholder="town"
                value={formData?.nomineeOne?.nomineeTown}
                onChange={handleChange('nomineeOne', 'nomineeTown')}
              />
            </div>
            <div className="error-message">
              {errors.town && (
                <span className="text-danger me-5">{errors.town}</span>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="postcode" className="custom-label">
                Postcode:
              </label>
              <input
                id="nomineePostcode"
                name="nomineePostcode"
                type="text"
                className={`form-control custom-input-box ${
                  errors.postcode ? 'border-danger' : ''
                }`}
                placeholder="Postcode"
                value={formData?.nomineeOne?.nomineePostcode || ''}
                onChange={handleChange('nomineeOne', 'nomineePostcode')}
              />
            </div>
            <div className="error-message">
              {errors.postcode && (
                <span className="text-danger">{errors.postcode}</span>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <div className="d-flex align-items-center">
              <label htmlFor="nationality" className="custom-label">
                Nationality:
              </label>

              <select
                id="nomineeNationality"
                name="nomineeNationality"
                className={`form-select-dropdown w-100 ${
                  errors.nationality ? 'border-danger' : ''
                }`}
                value={formData?.nomineeOne?.nomineeNationality || ''}
                onChange={handleChange('nomineeOne', 'nomineeNationality')}
              >
                <option value="">Select Nationality</option>
                {nationalities.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div className="error-message">
              {errors.nationality && (
                <span className="text-danger">{errors.nationality}</span>
              )}
            </div>
          </div>
        </div>

        <h6 className="header-text mt-5">Upload First Nominee Picture</h6>
        <div className="row upload-section mb-4">
          <div className="drop-box d-flex justify-content-between align-items-center col-sm-12 col-md-8 col-lg-8 h-25">
            <div className="upload-text">
              <p className="upload-instruction">
                <MdOutlineFileUpload /> Drag and Drop file here or{' '}
                <span
                  className="upload-link fw-bold"
                  onClick={handleFileUpload}
                >
                  Upload
                </span>
              </p>
              <p className="upload-supported">
                Files Supported: JPG, JPEG, PNG
              </p>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="file"
                ref={fileInputRef}
                name="nomineePic"
                accept="image/png, image/jpeg"
                className="d-none"
                onChange={handleClientFileChange}
              />

              <button className="btn upload-button" onClick={handleFileUpload}>
                Upload file
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center col-sm-12 col-md-4 col-lg-4 ">
            {formData?.nomineeOne?.nomineePic && (
              <div className="position-relative">
                <Image
                  src={formData?.nomineeOne?.nomineePic}
                  alt={'client image'}
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '12px',
                    border: '1px solid #ddd',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    marginLeft: '15px',
                  }}
                />
                <IoCloseCircleSharp
                  className="image-close-icon"
                  onClick={handleRemoveImage}
                />
              </div>
            )}
          </div>
          {errors.fileUploaded && (
            <span className="text-danger">{errors.fileUploaded}</span>
          )}
          {errors.nomineeOnePic && (
            <span className="text-danger">{errors.nomineeOnePic}</span>
          )}
        </div>

        <div className="document-section">
          <h6 className="header-text">
            Identification Documents (please tick box)
          </h6>
          {errors.documents && (
            <span className="text-danger">{errors.documents}</span>
          )}
          {[
            { name: 'Passport', label: 'Passport' },
            { name: 'Drivers License', label: 'Drivers License' },
            { name: 'Bank Statement', label: 'Bank Statement' },
            { name: 'council Tax', label: 'Council Tax' },
            { name: 'Utility Bill', label: 'Utility Bill' },
          ].map((doc) => {
            const isDocumentUploaded =
              identificationDocument?.some(
                (identification) => identification.documentType === doc.name
              ) || false;

            return (
              <div key={doc.name}>
                <div className="d-flex align-items-center mt-4">
                  <input
                    type="checkbox"
                    id={doc.name}
                    name={doc.name}
                    checked={documents[doc.name] || isDocumentUploaded}
                    onChange={(e) => handleCheckboxChange(e, doc.name)}
                  />
                  <label htmlFor={doc.name} className="ms-2 document-title">
                    {doc.label}
                  </label>
                </div>

                {(documents[doc.name] || isDocumentUploaded) && (
                  <div className="d-flex align-items-center mt-2">
                    <div className="drop-box col-sm-12 col-md-6 col-lg-6 me-3">
                      <p className="upload-instruction">
                        <MdOutlineFileUpload /> Upload your {doc.label} document
                        here
                      </p>
                    </div>
                    <div>
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        ref={(el) => (documentInputsRef.current[doc.name] = el)}
                        onChange={(e) => handleFileChange(e, doc.name)}
                      />
                      <button
                        className="btn upload-button"
                        onClick={(e) => {
                          e.preventDefault();
                          documentInputsRef.current[doc.name].click();
                        }}
                      >
                        Upload file
                      </button>
                    </div>

                    {isDocumentUploaded && (
                      <div className="ms-3">
                        {identificationDocument
                          .filter(
                            (identification) =>
                              identification.documentType === doc.name
                          )
                          .map((identification, index) => (
                            <div className="position-relative">
                              <Image
                                key={index}
                                src={identification.documentUrl}
                                alt={identification.documentType}
                                style={{
                                  width: '100px',
                                  height: '100px',
                                  borderRadius: '12px',
                                  border: '1px solid #ddd',
                                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                  marginLeft: '15px',
                                }}
                              />

                              <IoCloseCircleSharp
                                className="image-close-icon"
                                onClick={handleRemoveDocument}
                              />
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <Collapse className="mt-3">
          <Panel
            header={
              <span className="custom-collapse-header">
                Add Second Nominee Details
              </span>
            }
            key="1"
          >
            <div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center mb-0">
                    <label htmlFor="title" className="custom-label">
                      Title:
                      <br />
                      <span className="subTitle">(Mr/Mrs/Miss/Ms/Dr):</span>
                    </label>

                    <select
                      id="nomineeTitle"
                      name="nomineeTitle"
                      className={`form-control select-title custom-input-box w-100 ${
                        errors.nomineeTwoTitle ? 'border-danger' : ''
                      }`}
                      value={formData?.nomineeTwo?.nomineeTitle}
                      onChange={handleChange('nomineeTwo', 'nomineeTitle')}
                    >
                      <option value="">Select Title</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                    </select>
                  </div>
                  {errors.nomineeTwoTitle && (
                    <span style={{ color: 'red' }}>
                      {errors.nomineeTwoTitle}
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="dob" className="custom-label">
                      DOB:
                    </label>
                    <DatePicker
                      id="nomineeDob"
                      name="nomineeDob"
                      className={`custom-input-box mt-1 w-100 custom-datepicker ${
                        errors.nomineeTwoDob ? 'border-danger' : ''
                      }`}
                      style={{
                        height: '40px',
                        border: '1px solid rgb(206 212 217)',
                      }}
                      format="DD-MM-YYYY"
                      value={
                        formData?.nomineeTwo?.nomineeDob
                          ? moment(
                              formData?.nomineeTwo?.nomineeDob,
                              'DD-MM-YYYY'
                            )
                          : null
                      }
                      onChange={(date) => {
                        if (date) {
                          const selectedDate = new Date(
                            date.format('YYYY-MM-DD')
                          );
                          const today = new Date();
                          today.setHours(0, 0, 0, 0);

                          if (selectedDate >= today) {
                            errors.nomineeTwoDob =
                              'Date of birth cannot be today or in the future';
                          } else {
                            errors.nomineeTwoDob = '';
                          }
                          handleChange(
                            'nomineeTwo',
                            'nomineeDob'
                          )({
                            target: { value: date.format('DD-MM-YYYY') },
                          });
                        } else {
                          errors.dob = '';
                          handleChange(
                            'nomineeTwo',
                            'nomineeDob'
                          )({
                            target: { value: '' },
                          });
                        }
                      }}
                      placeholder="Select Date of Birth"
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoDob && (
                      <span className="text-danger">
                        {errors.nomineeTwoDob}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="foreName" className="custom-label">
                      Forename:
                    </label>
                    <input
                      id="nomineeForename"
                      name="nomineeForename"
                      type="text"
                      className={`form-control custom-input-box ${
                        errors.nomineeTwoForename ? 'border-danger' : ''
                      }`}
                      placeholder="Forenames"
                      value={formData?.nomineeTwo?.nomineeForename}
                      onChange={handleChange('nomineeTwo', 'nomineeForename')}
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoForename && (
                      <span className="text-danger">
                        {errors.nomineeTwoForename}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="surName" className="custom-label">
                      Surname:
                    </label>
                    <input
                      id="nomineeSurname"
                      name="nomineeSurname"
                      type="text"
                      className={`form-control custom-input-box ${
                        errors.nomineeTwoSurname ? 'border-danger' : ''
                      }`}
                      placeholder="Surname"
                      value={formData?.nomineeTwo?.nomineeSurname || ''}
                      onChange={handleChange('nomineeTwo', 'nomineeSurname')}
                    />
                  </div>

                  <div className="error-message">
                    {errors.nomineeTwoSurname && (
                      <span className="text-danger">
                        {errors.nomineeTwoSurname}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="telephone" className="custom-label">
                      Telephone:
                    </label>
                    <input
                      id="nomineeTel"
                      name="nomineeTel"
                      type="text"
                      className={`form-control custom-input-box ${
                        errors.nomineeTwoTel ? 'border-danger' : ''
                      }`}
                      placeholder="Telephone"
                      value={formData?.nomineeTwo?.nomineeTel || ''}
                      onChange={handleChange('nomineeTwo', 'nomineeTel')}
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoTel && (
                      <span className="text-danger">
                        {errors.nomineeTwoTel}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="email" className="custom-label">
                      Email:
                    </label>
                    <input
                      id="nomineeEmail"
                      name="nomineeEmail"
                      type="text"
                      className={`form-control custom-input-box ${
                        errors.nomineeTwoEmail ? 'border-danger' : ''
                      }`}
                      placeholder="Email"
                      value={formData?.nomineeTwo?.nomineeEmail || ''}
                      onChange={handleChange('nomineeTwo', 'nomineeEmail')}
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoEmail && (
                      <span className="text-danger">
                        {errors.nomineeTwoEmail}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="street" className="custom-label">
                      Street:
                    </label>
                    <input
                      id="nomineeStreet"
                      name="nomineeStreet"
                      type="text"
                      className={`form-control custom-input-box w-100 ${
                        errors.nomineeTwoStreet ? 'border-danger' : ''
                      }`}
                      placeholder="Street"
                      value={formData?.nomineeTwo?.nomineeStreet}
                      onChange={handleChange('nomineeTwo', 'nomineeStreet')}
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoStreet && (
                      <span className="text-danger">
                        {errors.nomineeTwoStreet}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="town" className="custom-label">
                      Town:
                    </label>
                    <input
                      id="nomineeTown"
                      name="nomineeTown"
                      type="text"
                      className={`form-control custom-input-box w-100 ${
                        errors.nomineeTwoTown ? 'border-danger' : ''
                      }`}
                      placeholder="town"
                      value={formData?.nomineeTwo?.nomineeTown}
                      onChange={handleChange('nomineeTwo', 'nomineeTown')}
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoTown && (
                      <span className="text-danger me-5">
                        {errors.nomineeTwoTown}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="postcode" className="custom-label">
                      Postcode:
                    </label>
                    <input
                      id="nomineePostcode"
                      name="nomineePostcode"
                      type="text"
                      className={`form-control custom-input-box ${
                        errors.nomineeTwoPostcode ? 'border-danger' : ''
                      }`}
                      placeholder="Postcode"
                      value={formData?.nomineeTwo?.nomineePostcode || ''}
                      onChange={handleChange('nomineeTwo', 'nomineePostcode')}
                    />
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoPostcode && (
                      <span className="text-danger">
                        {errors.nomineeTwoPostcode}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                  <div className="d-flex align-items-center">
                    <label htmlFor="nationality" className="custom-label">
                      Nationality:
                    </label>

                    <select
                      id="nomineeNationality"
                      name="nomineeNationality"
                      className={`form-select-dropdown w-100 ${
                        errors.nomineeTwoNationality ? 'border-danger' : ''
                      }`}
                      value={formData?.nomineeTwo?.nomineeNationality || ''}
                      onChange={handleChange(
                        'nomineeTwo',
                        'nomineeNationality'
                      )}
                    >
                      <option value="">Select Nationality</option>
                      {nationalities.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="error-message">
                    {errors.nomineeTwoNationality && (
                      <span className="text-danger">
                        {errors.nomineeTwoNationality}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <h6 className="header-text mt-5">
                Upload Second Nominee Picture
              </h6>
              <div className="upload-section row">
                <div className="drop-box d-flex justify-content-between align-items-center col-sm-12 col-md-7 col-lg-7 h-25">
                  <div className="upload-text">
                    <p className="upload-instruction">
                      <MdOutlineFileUpload /> Drag and Drop file here or{' '}
                      <span
                        className="upload-link fw-bold"
                        onClick={handleFileNomineeTwoUpload}
                      >
                        Upload
                      </span>
                    </p>
                    <p className="upload-supported">
                      Files Supported: JPG, JPEG, PNG
                    </p>
                  </div>
                  <div className="">
                    <input
                      type="file"
                      ref={fileInputNomineeTwoRef}
                      name="nomineePic"
                      accept="image/png, image/jpeg"
                      className="d-none"
                      onChange={handleNomineeTwoFileChange}
                    />
                    <button
                      className="btn upload-button"
                      onClick={handleFileNomineeTwoUpload}
                    >
                      Upload file
                    </button>
                  </div>
                </div>

                <div className="mt-5 col-sm-12 col-md-5 col-lg-5 justify-content-center">
                  {formData?.nomineeTwo?.nomineePic && (
                    <div
                      style={{
                        position: 'relative',
                        bottom: '3rem',
                        right: '13px',
                      }}
                    >
                      <Image
                        src={formData?.nomineeTwo?.nomineePic}
                        alt={'client image'}
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '12px',
                          border: '1px solid #ddd',
                          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                          marginLeft: '15px',
                        }}
                      />
                      <IoCloseCircleSharp
                        className="image-close-icon"
                        onClick={handleRemoveImageNomineeTwo}
                      />
                    </div>
                  )}
                </div>

                {errors.ProfileUploadedNomineeTwo && (
                  <div className="error-message mt-2 text-danger">
                    {errors.ProfileUploadedNomineeTwo}
                  </div>
                )}
                {errors.nomineeTwoPic && (
                  <div className="error-message mt-2 text-danger">
                    {errors.nomineeTwoPic}
                  </div>
                )}
              </div>
              <div className="document-section">
                <h6 className="header-text">
                  Identification Documents (please tick box)
                </h6>
                {errors.nomineeTwoDocuments && (
                  <span className="text-danger fs-6">
                    {errors.nomineeTwoDocuments}
                  </span>
                )}
                {[
                  { name: 'Passport', label: 'Passport' },
                  { name: 'Drivers License', label: 'Drivers License' },
                  { name: 'Bank Statement', label: 'Bank Statement' },
                  { name: 'Council Tax', label: 'Council Tax' },
                  { name: 'Utility Bill', label: 'Utility Bill' },
                ].map((doc) => {
                  const isDocumentUploaded =
                    nomineeTwoIdentificationDocument?.some(
                      (identification) =>
                        identification.documentType === doc.name
                    ) || false;

                  return (
                    <div key={doc.name}>
                      <div className="d-flex align-items-center mt-4">
                        <input
                          type="checkbox"
                          id={doc.name}
                          name={doc.name}
                          checked={
                            nomineeTwoDocuments[doc.name] || isDocumentUploaded
                          }
                          onChange={(e) =>
                            handleCheckboxNomineeTwoChange(e, doc.name)
                          }
                        />
                        <label
                          htmlFor={doc.name}
                          className="ms-2 document-title"
                        >
                          {doc.label}
                        </label>
                      </div>

                      {(nomineeTwoDocuments[doc.name] ||
                        isDocumentUploaded) && (
                        <div className="d-flex align-items-center mt-2">
                          <div className="drop-box col-sm-12 col-md-6 col-lg-6 me-3">
                            <p className="upload-instruction">
                              <MdOutlineFileUpload /> Upload your {doc.label}{' '}
                              document here
                            </p>
                          </div>
                          <div>
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              ref={(el) =>
                                (documentInputNomineeTwosRef.current[doc.name] =
                                  el)
                              }
                              onChange={(e) =>
                                handleFileChangeNomineeTwo(e, doc.name)
                              }
                            />
                            <button
                              className="btn upload-button"
                              onClick={(e) => {
                                e.preventDefault();
                                documentInputNomineeTwosRef.current[
                                  doc.name
                                ].click();
                              }}
                            >
                              Upload file
                            </button>
                          </div>

                          {isDocumentUploaded && (
                            <div className="ms-3">
                              {nomineeTwoIdentificationDocument
                                .filter(
                                  (identification) =>
                                    identification.documentType === doc.name
                                )
                                .map((identification, index) => (
                                  <div className="position-relative">
                                    <Image
                                      key={index}
                                      src={identification.documentUrl}
                                      alt={identification.documentType}
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '12px',
                                        border: '1px solid #ddd',
                                        boxShadow:
                                          '0 2px 8px rgba(0, 0, 0, 0.1)',
                                        marginLeft: '15px',
                                      }}
                                    />
                                    <IoCloseCircleSharp
                                      className="image-close-icon"
                                      onClick={handleRemoveDocumentNomineeTwo}
                                    />
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Panel>
        </Collapse>

        <div className="stepper-navigation">
          <button onClick={prevStep} className="btn prev-btn">
            <i className="fa-solid fa-caret-left"></i> Previous
          </button>

          <button onClick={handleNext} className="btn next-btn">
            Next <i className="fa-solid fa-caret-right"></i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default NomineeDetails;
