import { message, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import '../EventsStyle/EventLogoutModal.css';
import { clientLogout } from '../EventApis';

const EventLogOutModal = ({
  isOpenLogin,
  onCloseLOgin,
  client,
  fetchClientData,
}) => {
  const [timeLeft, setTimeLeft] = useState(3600);

  const calculateTimeLeft = () => {
    if (client?.loginData?.loginTime) {
      const loginTime = moment(client?.loginData?.loginTime);
      const elapsedTime = moment().diff(loginTime, 'seconds');
      const remainingTime = Math.max(3600 - elapsedTime, 0);
      return remainingTime;
    }
    return 3600;
  };

  useEffect(() => {
    if (isOpenLogin) {
      const initialTimeLeft = calculateTimeLeft();
      setTimeLeft(initialTimeLeft);

      const interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            handleLogout();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isOpenLogin]);

  const handleLogout = async () => {
    try {
      if (!client || !client?._id) {
        throw new Error('Client ID is not available. Cannot log out.');
      }
      const data = await clientLogout(client?._id);

      if (data && data?.status === 200) {
        message.success(data.message || 'Client successfully logged out');
        fetchClientData();
        onCloseLOgin();
      } else {
        message.error(data?.message || 'Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const formatTime = (timeLeft) => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  const loginTime = client?.loginData?.loginTime
    ? moment(client?.loginData?.loginTime)
    : moment();

  const dataSource = [
    {
      key: '1',
      label: 'Login Date',
      value: loginTime ? moment(loginTime).format('DD-MM-YYYY') : 'N/A',
    },
    {
      key: '2',
      label: 'Login Time',
      value: loginTime ? moment(loginTime).format('HH:mm:ss') : 'N/A',
    },
    {
      key: '3',
      label: 'Client Name',
      value:
        `${client?.foreName || ''} ${client?.surName || ''}`.trim() || 'N/A',
    },
    {
      key: '4',
      label: 'UCC Number',
      value: client?.uccNumber || 'N/A',
    },
    {
      key: '5',
      label: 'Box Number',
      value: client?.boxNumber || 'N/A',
    },
  ];

  const columns = [
    {
      title: 'Field',
      dataIndex: 'label',
      key: 'label',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <Modal
      open={isOpenLogin}
      onCancel={onCloseLOgin}
      footer={null}
      centered
      width={500}
    >
      <h3 className="header-text fs-4 text-center">Active Client Details</h3>
      <div className="modal-content border-0">
        <div className="client-details-section p-2">
          <div className="card time-left-card">
            <strong>Time left:</strong> {formatTime(timeLeft)}
          </div>
          <div>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn-login" onClick={handleLogout}>
            Log Out
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EventLogOutModal;
