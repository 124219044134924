import React from 'react';
import FingerPrintScanImage from '../../assets/images/client/fingerprintScan.png';
import { BsArrowUpRightCircleFill } from 'react-icons/bs';
import './client-style/FingerPrintScan.css';
import BackButton from '../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';

function FingerPrintScan() {
  const navigate = useNavigate();

  const handleStartNowClick = async () => {
    navigate('/locker-safe-allocate');
  };

  const handlePreviousBack = () => {
    navigate(-1);
  };

  return (
    <div className="position-relative">
      <img src={FingerPrintScanImage} alt="Sample" className="img-fluid" />
      <div className="finger-print-bg position-absolute text-white">
        <div className="text-start">
          <p className="fw-bold finger-print-heading">
            Start Using <span className="finger-print-color">Fingerprint</span>
          </p>
          <p className="finger-print-text">
            Set Up Your Fingerprint: Once Complete, a Unique Client ID Will Be
            Created to Ensure Personalized and Secure Entry to Your Account.
          </p>
          <button
            className="btn bg-white rounded-pill fw-bold finger-print-button"
            onClick={handleStartNowClick}
          >
            Start Now
            <BsArrowUpRightCircleFill className="start-now-icon fs-2 ms-2" />
          </button>
        </div>
      </div>

      <div className="back-button-container">
        <BackButton onClick={handlePreviousBack} />
      </div>
    </div>
  );
}

export default FingerPrintScan;
