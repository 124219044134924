import React, { useState } from 'react';
import SafesTable from './SafesTable';
import SafeNavbar from './SafeNavbar';

const SafesHome = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <SafeNavbar setSearchTerm={setSearchTerm} />
      <SafesTable searchTerm={searchTerm} />
    </>
  );
};

export default SafesHome;
