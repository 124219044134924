import React, { useEffect, useState } from 'react';
import { getLoginData } from '../../client-management/API/ClientTableAPI';
import { Image, Input, Modal, Pagination, Result, Spin } from 'antd';
import BackButton from '../../reusable/reusableComponent/BackButton';
import LoginAccount from '../../locker-management/locker/popUPPage/LoginAccount';
import EventLogOutModal from './EventLogOutModal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import CopyableText from '../../reusable/Common/CopyableText';

const { Search } = Input;

const EventLogin = ({ searchTerm }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [clientDatas, setClientDatas] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [fingerisPopupOpen, setIsAccountPopupOpen] = useState(false);
  const [logOutIsPopUp, setLogOutIsPopUp] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [fingerPrintString, setFingerPrintString] = useState('');
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    createdAt: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchClientData();
    startAutoLogoutTimer();
  }, []);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getLoginData();
      if (response.success && response.data) {
        setClientDatas(response.data);
        setFilteredClients(response.data);
        setCurrentPage(1);
      } else {
        setError(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const startAutoLogoutTimer = () => {
    const timer = setTimeout(() => {
      showAlertAndLogout();
      fetchClientData();
    }, 3600000);

    return () => clearTimeout(timer);
  };

  const showAlertAndLogout = () => {
    setLogoutModalVisible(true);
  };

  const handleConfirmLogout = () => {
    setLogoutModalVisible(false);
    alert('You have been logged out due to inactivity.');
  };

  useEffect(() => {
    filterClients();
  }, [searchTerm, searchTerm1]);

  const filterClients = () => {
    if (!searchTerm && !searchTerm1) {
      setFilteredClients(clientDatas);
      return;
    }

    const lowerCaseSearchTerm = (searchTerm || '').toLowerCase();
    const lowerCaseSearchTerm1 = (searchTerm1 || '').toLowerCase();

    const filtered = clientDatas.filter((client) => {
      const formattedCreatedDate = moment(client.createdAt).format(
        'DD-MM-YYYY'
      );
      const formattedDob = moment(client.dob).format('DD-MM-YYYY');

      const matchesSearchTerm =
        (`${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
          client.email?.toLowerCase().includes(lowerCaseSearchTerm) ||
          client.postcode?.toLowerCase().includes(lowerCaseSearchTerm) ||
          client.street?.toLowerCase().includes(lowerCaseSearchTerm) ||
          client.town?.toLowerCase().includes(lowerCaseSearchTerm) ||
          client.telephone?.toLowerCase().includes(lowerCaseSearchTerm) ||
          client.applicationNo?.toLowerCase().includes(lowerCaseSearchTerm) ||
          client.dob.toLowerCase().includes(lowerCaseSearchTerm) ||
          formattedDob.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.uccNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
          formattedCreatedDate.toLowerCase().includes(lowerCaseSearchTerm)) &&
        (`${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm1) ||
          client.email?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.postcode?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.street?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.town?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.telephone?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.applicationNo?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.dob.toLowerCase().includes(lowerCaseSearchTerm1) ||
          client.uccNumber?.toLowerCase().includes(lowerCaseSearchTerm1) ||
          formattedCreatedDate.toLowerCase().includes(lowerCaseSearchTerm1) ||
          formattedDob.toLowerCase().includes(lowerCaseSearchTerm1));

      return matchesSearchTerm;
    });

    setFilteredClients(filtered);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const paginatedClients = filteredClients?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePreviousBack = () => {
    navigate(-1);
  };

  const LoginAccounttogglePopup = (clientId) => {
    const selectedClientData = clientDatas.find(
      (client) => client._id === clientId
    );

    const loginCount = clientDatas.filter(
      (client) => client.loginData?.status === 'active'
    ).length;

    if (loginCount >= 3) {
      Swal.fire({
        icon: 'warning',
        title: 'Login Limit Exceeded',
        text: 'Currently, 3 clients are logged in. Please log out an existing client before logging in another.',
      });
    } else {
      const fingerprint = selectedClientData?.fingerPrintString;
      setSelectedClientId(clientId);
      setFingerPrintString(fingerprint);
      setIsAccountPopupOpen(!fingerisPopupOpen);
    }
  };

  const LogoutAccountTogglePopUp = (client) => {
    setSelectedClient(client);
    setLogOutIsPopUp(!logOutIsPopUp);
  };

  const toggleSortOrder = (field) => {
    const newSortOrder = { ...sortOrder };

    if (newSortOrder[field] === 'asc') {
      newSortOrder[field] = 'desc';
    } else if (newSortOrder[field] === 'desc') {
      newSortOrder[field] = null;
    } else {
      newSortOrder[field] = 'asc';
    }

    setSortOrder(newSortOrder);
    sortData(field, newSortOrder[field]);
  };

  const sortData = (field, order) => {
    if (!order) {
      setFilteredClients(clientDatas);
      return;
    }

    const sortedClients = [...filteredClients];

    sortedClients.sort((a, b) => {
      let aField, bField;

      if (field === 'name') {
        aField = `${a.foreName || ''} ${a.surName || ''}`.toLowerCase();
        bField = `${b.foreName || ''} ${b.surName || ''}`.toLowerCase();
      } else if (field === 'createdAt') {
        aField = a.createdAt ? moment(a.createdAt).unix() : 0;
        bField = b.createdAt ? moment(b.createdAt).unix() : 0;
      }

      if (order === 'asc') {
        return aField > bField ? 1 : aField < bField ? -1 : 0;
      } else {
        return aField < bField ? 1 : aField > bField ? -1 : 0;
      }
    });

    setFilteredClients(sortedClients);
  };

  const handleLoginSuccess = (clientId) => {
    const client = clientDatas.find((c) => c._id === clientId);
    if (client) {
      setSelectedClient(client);
      setLogOutIsPopUp(true); // Automatically open the logout popup
    }
  };

  return (
    <div className="container-fluid my-3">
      <div className="d-flex justify-content-end align-items-center">
        <Search
          placeholder="Search clients by name, email, or other details"
          allowClear
          value={searchTerm1}
          onChange={(e) => setSearchTerm1(e.target.value)}
          enterButton
          className="search-input"
        />
      </div>

      <div className="table-responsive">
        {loading ? (
          <div className="loading-container d-flex align-item-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : filteredClients.length === 0 ? (
          <Result
            status="404"
            title="No Clients Found"
            subTitle={error || "Sorry, we couldn't find any matching clients."}
          />
        ) : (
          <>
            <table className="table client-table">
              <thead>
                <tr>
                  <th scope="col">CLIENT</th>
                  <th scope="col" onClick={() => toggleSortOrder('name')}>
                    NAME/EMAIL{' '}
                    {sortOrder.name === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : sortOrder.name === 'desc' ? (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>
                  <th scope="col" onClick={() => toggleSortOrder('createdAt')}>
                    START DATE{' '}
                    {sortOrder.createdAt === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : sortOrder.createdAt === 'desc' ? (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>

                  <th scope="col">LOGIN</th>
                  <th scope="col">LOGOUT</th>
                  <th scope="col">UCC NO.</th>
                  <th scope="col">BOX NO.</th>
                  <th scope="col">IN/OUT</th>
                </tr>
              </thead>
              <tbody>
                {paginatedClients.map((client) => (
                  <tr key={client?._id} className="client-row">
                    <td className="client-view justify-content-center">
                      <Image
                        src={
                          client?.clientPic ||
                          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9SRRmhH4X5N2e4QalcoxVbzYsD44C-sQv-w&s'
                        }
                        alt="profile"
                        className="avatar rounded-circle client-avatar"
                        width={60}
                        height={60}
                      />
                    </td>
                    <td>
                      <p className="fw-bold mb-0">
                        {`${client?.foreName || 'Unknown'} ${
                          client?.surName || ''
                        }`}
                      </p>
                      <p className="client-email fw-normal">
                        {client?.email || 'N/A'}
                      </p>
                    </td>
                    <td className="client-date fw-normal">
                      {moment(client?.createdAt).format('DD-MM-YYYY')}
                    </td>
                    <td className="client-address text-center">
                      {moment(client?.loginData?.loginTime).format('HH:mm')}
                    </td>
                    <td className="client-postcode text-center">
                      {client?.loginData?.status === 'logged_out' &&
                      client?.loginData?.logoutTime ? (
                        moment(client?.loginData?.logoutTime).format('HH:mm')
                      ) : client?.loginData?.status === 'active' ? (
                        <span className="fw-bold text-danger">Active</span>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td className="client-postcode">
                      <CopyableText text={client?.uccNumber} />
                    </td>
                    <td className="client-telephone">
                      {client?.boxNumber || 'N/A'}
                    </td>
                    <td className="client-locker">
                      {client?.loginData?.status === 'logged_out' ? (
                        <button
                          className="btn btn-view-detail"
                          onClick={() => LoginAccounttogglePopup(client?._id)}
                        >
                          LOGIN
                        </button>
                      ) : client?.loginData?.status === 'active' ? (
                        <button
                          className="btn btn-login"
                          onClick={() => LogoutAccountTogglePopUp(client)}
                        >
                          LOG OUT
                        </button>
                      ) : (
                        <p>N/A</p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredClients.length}
          onChange={handlePaginationChange}
          showSizeChanger
          pageSizeOptions={['5', '10', '20']}
          className="pagination"
        />
      </div>
      <div className="back-button-container">
        <BackButton onClick={handlePreviousBack} />
      </div>

      <LoginAccount
        isOpenLogin={fingerisPopupOpen}
        onCloseLOgin={LoginAccounttogglePopup}
        clientId={selectedClientId}
        fingerPrintString={fingerPrintString}
        fetchClientData={fetchClientData}
        onLoginSuccess={handleLoginSuccess}
      />

      <EventLogOutModal
        isOpenLogin={logOutIsPopUp}
        onCloseLOgin={LogoutAccountTogglePopUp}
        client={selectedClient}
        fetchClientData={fetchClientData}
      />

      <Modal
        open={logoutModalVisible}
        title="Session Expired"
        onOk={handleConfirmLogout}
        onCancel={() => setLogoutModalVisible(false)}
        okText="Log Out"
        cancelText="Stay Logged In"
      >
        <p>Your session has expired due to inactivity. Please log in again.</p>
      </Modal>
    </div>
  );
};

export default EventLogin;
