import React, { useState } from 'react';
import NavbarWithSearchBar from '../../reusable/reusableComponent/NavbarWithSearchBar';
import EventFilterButtons from './EventFilterButtons';
import HomeFooter from '../../home/HomeFooter/HomeFooter';

const EventHome = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div>
      <NavbarWithSearchBar setSearchTerm={setSearchTerm} />
      <EventFilterButtons searchTerm={searchTerm} />
      <HomeFooter />
    </div>
  );
};

export default EventHome;
