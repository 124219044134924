import React, { useState } from 'react';
import NavbarWithSearchBar from '../reusable/reusableComponent/NavbarWithSearchBar';
import RenewalTable from './RenewalTable';
import HomeFooter from '../home/HomeFooter/HomeFooter';

const RenewalHome = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div>
      <NavbarWithSearchBar setSearchTerm={setSearchTerm} />
      <RenewalTable searchTerm={searchTerm} />
      <HomeFooter />
    </div>
  );
};

export default RenewalHome;
