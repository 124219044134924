import React, { useState, useRef } from 'react';
import AuthSideImage from '../../assets/images/loginImage.png';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { PiLessThan } from 'react-icons/pi';
import AuthImage from '../reusable/AuthComponents/AuthImage';
import FormButtons from '../reusable/reusableComponent/FormButtons';
import { IoIosWarning } from 'react-icons/io';
import '../../assets/css/forget.css';
import { verifyOTP } from './AuthApi';

function VerifyOTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};

  const [otp, setOtp] = useState(['', '', '', '']);
  const [errors, setErrors] = useState('');

  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      if (index > 0 && !otp[index]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const otpValue = otp.join('');

    if (otp.includes('')) {
      setErrors('Please fill all OTP fields.');
      return;
    }
    setErrors('');
    try {
      const response = await verifyOTP(email, otpValue);
      if (response && response.success) {
        navigate('/reset-password', { state: { otp: otpValue, email } });
      } else {
        setErrors(response.error || 'Verification failed.');
      }
    } catch (error) {
      setErrors(error.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row min-vh-100">
        <AuthImage imageUrl={AuthSideImage} />
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
          <div className="text-center mb-5">
            <img
              className="img-fluid"
              src={logo}
              alt="Logo"
              style={{ height: '100px' }}
            />
          </div>
          <div className="w-75 px-lg-5 px-md-4">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <p className="fs-5 fw-bold mb-1">OTP Verification</p>
                <p className="forgot-text text-muted">
                  To ensure your security, please enter the One-Time Password
                  (OTP) sent to your registered email below: <b>{email}</b>.
                </p>
              </div>

              {errors && (
                <div
                  className="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <IoIosWarning className="me-2 fs-4" />
                  {errors}
                </div>
              )}
              <div className="d-flex justify-content-center mb-4">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    className="otp-input text-center"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    style={{
                      width: '3rem',
                      height: '3rem',
                      fontSize: '2rem',
                      margin: '0.5rem',
                    }}
                  />
                ))}
              </div>

              <div className="text-center">
                <div className="text-center mb-3">
                  <FormButtons type="submit">Submit</FormButtons>
                </div>
                <div className="">
                  <button className="cancelBtn">Cancel</button>
                </div>
              </div>
            </form>

            <div className="d-flex justify-content-between mt-3">
              <Link to="/" className="text-decoration-none">
                <b
                  className="py-3"
                  style={{ fontSize: '15px', color: '#000000' }}
                >
                  <PiLessThan /> Back to Login
                </b>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyOTP;
