import React, { useState, useEffect } from 'react';
import { Image, Input, Pagination, Result } from 'antd';
import NewClientForm from './NewClientForm';
import { getClientTableAPI } from './API/ClientTableAPI';
import ClientHistory from './ClientHistory';
import './client-style/ClientTable.css';
import BackButton from '../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';
import ClientDetailsShow from './ClientDetailsShow';
import {
  BsArrowUpRightCircleFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from 'react-icons/bs';
import moment from 'moment';
import CopyableText from '../reusable/Common/CopyableText';
import DefaultClientProfile from '../../assets/user.jpg';

const { Search } = Input;

function ClientTable({ searchTerm }) {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [clientDatas, setClientDatas] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [showNewClientForm, setShowNewClientForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showVisitHistory, setShowVisitHistory] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    createdAt: null,
    renewalDate: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    filterClients();
  }, [searchTerm, searchTerm1, clientDatas]);

  const handleShowDetails = (clientData) => {
    setClientDetails(clientData);
    setShowDetails(true);
    setShowNewClientForm(false);
    setShowVisitHistory(false);
  };

  const handleBack = () => {
    if (showDetails || showVisitHistory) {
      setShowDetails(false);
      setShowVisitHistory(false);
      fetchClientData();
    }
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getClientTableAPI();
      if (response.success && response.data) {
        const clients = response.data.clients;
        setClientDatas(clients);
        setFilteredClients(clients);
      } else {
        setError(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const filterClients = () => {
    let filtered = [...clientDatas];

    if (searchTerm && searchTerm.trim()) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((client) => {
        const fullNameMatch = `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
        const emailMatch = client.email
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const uccNumberMatch = client.uccNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const boxNumberMatch = client.boxNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const postcodeMatch = client.postcode
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const telephoneMatch = client.telephone
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
        const renewalDateMatch = renewDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        let regDate = moment(client.createdAt).format('DD-MM-YYYY');
        const RegDateMatch = regDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const dobDate = moment(client.dob).format('DD-MM-YYYY');
        const dobDateMatch = dobDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const streetMatch = client.street
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const townMatch = client.town
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);

        return (
          fullNameMatch ||
          emailMatch ||
          uccNumberMatch ||
          boxNumberMatch ||
          postcodeMatch ||
          telephoneMatch ||
          renewalDateMatch ||
          RegDateMatch ||
          dobDateMatch ||
          streetMatch ||
          townMatch
        );
      });
    }

    if (searchTerm1 && searchTerm1.trim()) {
      const lowerCaseSearchTerm1 = searchTerm1.toLowerCase();
      filtered = filtered.filter((client) => {
        const fullNameMatch = `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const emailMatch = client.email
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const uccNumberMatch = client.uccNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const boxNumberMatch = client.boxNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const postcodeMatch = client.postcode
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const telephoneMatch = client.telephone
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const streetMatch = client.street
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const townMatch = client.town
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
        const renewalDateMatch = renewDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        let regDate = moment(client.createdAt).format('DD-MM-YYYY');
        const RegDateMatch = regDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const dobDate = moment(client.dob).format('DD-MM-YYYY');
        const dobDateMatch = dobDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        return (
          fullNameMatch ||
          emailMatch ||
          uccNumberMatch ||
          boxNumberMatch ||
          postcodeMatch ||
          telephoneMatch ||
          renewalDateMatch ||
          RegDateMatch ||
          streetMatch ||
          townMatch ||
          dobDateMatch
        );
      });
    }

    setFilteredClients(filtered);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const toggleSortOrder = (field) => {
    const newSortOrder = { ...sortOrder };
    const currentOrder = newSortOrder[field];

    if (currentOrder === 'asc') {
      newSortOrder[field] = 'desc';
    } else if (currentOrder === 'desc') {
      newSortOrder[field] = 'asc';
    } else {
      newSortOrder[field] = 'asc';
    }

    setSortOrder(newSortOrder);
    sortData(field, newSortOrder[field]);
  };

  const sortData = (field, order) => {
    const sortedClients = [...filteredClients];

    if (field === 'name') {
      sortedClients.sort((a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return order === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    } else if (field === 'createdAt') {
      sortedClients.sort((a, b) => {
        const dateA = moment(a.createdAt).unix();
        const dateB = moment(b.createdAt).unix();
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    } else if (field === 'renewalDate') {
      sortedClients.sort((a, b) => {
        const dateA = moment(a.renewalDate).unix();
        const dateB = moment(b.renewalDate).unix();
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }

    setFilteredClients(sortedClients);
  };

  const paginatedClients = filteredClients.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="client-table-container">
      {showNewClientForm && (
        <NewClientForm onClose={() => setShowNewClientForm(false)} />
      )}
      {!showNewClientForm && !showDetails && !showVisitHistory && (
        <>
          <div className="row justify-content-end">
            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <Search
                placeholder="Search by Postcode or Box Number"
                onChange={(e) => setSearchTerm1(e.target.value)}
                enterButton
                value={searchTerm1}
                className="custom-search-bar"
                style={{
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  fontSize: '16px',
                }}
              />
            </div>
          </div>

          <div className="card-section row mt-0 p-2">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
              <div className="row">
                <div className="col-6 client-card card border mx-2">
                  <div className="card-body">
                    <div className="d-flex client-info">
                      <p className="client-count fs-3 mt-2 text-primary ">
                        {filteredClients.length}
                      </p>
                      <BsArrowUpRightCircleFill className="icon-arrow text-primary fs-4 mt-3 ms-2" />
                    </div>
                    <h5 className="card-title text-start mt-1">
                      Active Clients
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid my-3">
            <div className="table-responsive">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>Error: {error}</p>
              ) : (
                <>
                  <table className="table client-table">
                    <thead>
                      <tr>
                        <th scope="col table-heading">CLIENT</th>
                        <th
                          style={{ width: '15%' }}
                          scope="col table-heading"
                          className="sortable-column"
                          onClick={() => toggleSortOrder('name')}
                        >
                          NAME/EMAIL
                          {sortOrder.name === 'asc' ? (
                            <BsFillCaretUpFill className="m-2 sort-icon" />
                          ) : sortOrder.name === 'desc' ? (
                            <BsFillCaretDownFill className="m-2 sort-icon" />
                          ) : (
                            <BsFillCaretDownFill className="m-2 sort-icon" />
                          )}
                        </th>
                        <th scope="col table-heading">UCC NO.</th>
                        <th scope="col table-heading">BOX NO.</th>
                        <th
                          scope="col table-heading"
                          className="px-0"
                          onClick={() => toggleSortOrder('createdAt')}
                        >
                          REG. DATE
                          {sortOrder.createdAt === 'asc' ? (
                            <BsFillCaretUpFill className="m-2 sort-icon" />
                          ) : sortOrder.createdAt === 'desc' ? (
                            <BsFillCaretDownFill className="m-2 sort-icon" />
                          ) : (
                            <BsFillCaretDownFill className="m-2 sort-icon" />
                          )}
                        </th>
                        <th
                          scope="col table-heading"
                          className="px-0"
                          onClick={() => toggleSortOrder('renewalDate')}
                        >
                          RENEWAL
                          {sortOrder.renewalDate === 'asc' ? (
                            <BsFillCaretUpFill className="m-2 sort-icon" />
                          ) : sortOrder.renewalDate === 'desc' ? (
                            <BsFillCaretDownFill className="m-2 sort-icon" />
                          ) : (
                            <BsFillCaretDownFill className="m-2 sort-icon" />
                          )}
                        </th>
                        <th scope="col table-heading">STREET</th>
                        <th scope="col table-heading">TOWN</th>
                        <th scope="col table-heading">POSTCODE</th>
                        <th scope="col table-heading">TELEPHONE</th>
                        <th scope="col table-heading">VIEW</th>
                      </tr>
                    </thead>
                    <tbody className="align-items-center">
                      {paginatedClients.length > 0 ? (
                        paginatedClients.map((client) => (
                          <tr key={client._id} className="client-row">
                            <td className="client-view">
                              <Image
                                src={client.clientPic || DefaultClientProfile}
                                alt="profile"
                                className="avatar rounded-circle client-avatar"
                                width={60}
                                height={60}
                              />
                            </td>
                            <td>
                              <p className="fw-bold mb-0">{`${
                                client.foreName || 'Unknown'
                              } ${client.surName || ''}`}</p>
                              <p className="client-email fw-normal email-wrap">
                                {client.email || 'N/A'}
                              </p>
                            </td>
                            <td className="client-date fw-normal">
                              <CopyableText
                                text={client.uccNumber}
                                textStyle={{
                                  color: 'green',
                                  fontWeight: 'bold',
                                }}
                              />
                            </td>
                            <td
                              className="client-date fw-normal"
                              style={{ width: '15%' }}
                            >
                              {client.boxNumber ? client.boxNumber : '---'}
                            </td>
                            <td
                              className="client-date fw-normal"
                              style={{ width: '15%' }}
                            >
                              {client.createdAt
                                ? moment(client.createdAt).format('DD-MM-YYYY')
                                : '---'}
                            </td>
                            <td
                              className="client-date fw-normal"
                              style={{ width: '15%' }}
                            >
                              {client.renewalDate
                                ? moment(client.renewalDate).format(
                                    'DD-MM-YYYY'
                                  )
                                : '---'}
                            </td>

                            <td
                              className="client-address"
                              style={{ width: '20%' }}
                            >
                              {client.street
                                ? client.street.split(' ').length > 20
                                  ? client.street
                                      .split(' ')
                                      .slice(0, 20)
                                      .join(' ') + '...'
                                  : client.street
                                : 'N/A'}
                            </td>
                            <td
                              className="client-address"
                              style={{ width: '20%' }}
                            >
                              {client.town
                                ? client.town.split(' ').length > 20
                                  ? client.town
                                      .split(' ')
                                      .slice(0, 20)
                                      .join(' ') + '...'
                                  : client.town
                                : 'N/A'}
                            </td>
                            <td className="client-postcode">
                              {client.postcode || 'N/A'}
                            </td>
                            <td className="client-telephone">
                              {client.telephone || 'N/A'}
                            </td>
                            <td className="client-locker">
                              <button
                                className="btn btn-view-detail"
                                onClick={() => handleShowDetails(client)}
                              >
                                View Detail
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <Result
                              status="404"
                              title="No Clients Found"
                              subTitle={
                                error ||
                                "Sorry, we couldn't find any clients matching your search."
                              }
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={filteredClients.length}
                    onChange={handlePaginationChange}
                    showSizeChanger
                    pageSizeOptions={['5', '10', '20']}
                    className="pagination"
                  />
                </>
              )}
            </div>
            <div className="back-button-container">
              <BackButton onClick={handleBackPrevious} />
            </div>
          </div>
        </>
      )}

      {showDetails && (
        <>
          <ClientDetailsShow
            clientDetails={clientDetails}
            f
            onClose={handleBack}
          />
        </>
      )}

      {showVisitHistory && (
        <>
          <ClientHistory clientDetails={clientDetails} />
          <div className="back-button-container">
            <BackButton onClick={handleBack} />
          </div>
        </>
      )}
    </div>
  );
}

export default ClientTable;
