import React, { useState } from 'react';
import NavbarWithSearchBar from '../../reusable/reusableComponent/NavbarWithSearchBar';
import FinancialFilterButton from './FinancialFilterButton';
import HomeFooter from '../../home/HomeFooter/HomeFooter';

const HomeFinance = () => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <div>
      <NavbarWithSearchBar setSearchTerm={setSearchTerm} />
      <FinancialFilterButton searchTerm={searchTerm} />
      <HomeFooter />
    </div>
  );
};

export default HomeFinance;
