import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/auth/Login';
import ForgetPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import ResetPasswordSuccessfull from './components/auth/ResetPasswordSuccessfull';
import AdminHome from './components/home/AdminHome';
import {
  AuthProvider,
  ProtectedRoute,
  PublicRoute,
} from './services/AuthContext';
import {
  requestFCMToken,
  setupOnMessageListener,
} from './config/firebaseConfig';
import Poppages from './components/locker-management/locker/popUPPage/Poppages';
import UpdatePlan from './components/reusable/Client/UpdatePlan';
import SafesHome from './components/safes-management/SafesHome';
import EventHome from './components/event-management/Events/EventHome';
import ClientHome from './components/client-management/ClientHome';
import DocumentUpload from './components/reusable/Client/DocumentUpload';
import LockerHome from './components/locker-management/locker/LockerHome';
import VerifyOTP from './components/auth/VerifyOTP';
import HomeFinance from './components/financial-management/financial/HomeFinance';
import UpgradeLocker from './components/reusable/Client/UpgradeLocker';
import UpgradeSafe from './components/reusable/Client/UpgradeSafe';
import RenewalHome from './components/RenewalEmails/RenewalHome';
import ViewClientVisitHistroy from './components/client-management/ViewClientVisitHistroy';
import ChooseLockerAndSafe from './components/client-management/ChooseLockerAndSafe';
import ClientDetailsViewAdmin from './components/client-management/ClientDetailsViewAdmin';
import LockerSafeAllocate from './components/client-management/LockerSafeAllocate';
import PaymentCalculator from './components/payment-management/payment-calc/PaymentCalculator';
import { getLoginData } from './components/client-management/API/ClientTableAPI';
import { Avatar, Drawer } from 'antd';
import dayjs from 'dayjs';
import './App.css';

const App = () => {
  const [fcmToken, setFcmToken] = useState(null);
  const [usersWithNullLogout, setUsersWithNullLogout] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const intervalRef = useRef(null);
  const usersRef = useRef(usersWithNullLogout);
  const location = useLocation();

  useEffect(() => {
    handleFetchFCMToken();
    setupOnMessageListener();
  }, []);

  useEffect(() => {
    fetchUsersWithNullLogout();

    const timerInterval = setInterval(() => {
      updateRemainingTime();
    }, 1000);

    const pollingInterval = setInterval(() => {
      fetchUsersWithNullLogout();
    }, 10000);

    return () => {
      clearInterval(timerInterval);
      clearInterval(pollingInterval);
    };
  }, []);

  const handleFetchFCMToken = async () => {
    const token = await requestFCMToken();
    setFcmToken(token);
  };

  const fetchUsersWithNullLogout = async () => {
    try {
      const response = await getLoginData();
      if (response.success && response.data) {
        const filteredUsers = response.data.filter(
          (user) => !user.loginData.logoutTime
        );
        const usersWithTimeData = filteredUsers.map((user) => ({
          ...user,
          remainingTime: calculateTimeLeft(user.loginData.loginTime),
        }));

        // if (
        //   JSON.stringify(usersWithNullLogout) !==
        //   JSON.stringify(usersWithTimeData)
        // ) {
        //   setUsersWithNullLogout(usersWithTimeData);
        // }

        usersRef.current = usersWithTimeData;
        setUsersWithNullLogout(usersWithTimeData);
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Network Error:', error.message);
    }
  };
  const formatTime = (timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) {
      return '00:00';
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  const calculateTimeLeft = (loginTime) => {
    if (loginTime) {
      const elapsedTime = dayjs().diff(dayjs(loginTime), 'seconds');
      return Math.max(3600 - elapsedTime, 0);
    }
    return 3600;
  };

  const updateRemainingTime = () => {
    let shouldFetchData = false;

    setUsersWithNullLogout((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        const remainingTime = calculateTimeLeft(user.loginData.loginTime);

        if (remainingTime <= 0 && user.remainingTime > 0) {
          shouldFetchData = true;
        }

        return {
          ...user,
          remainingTime: Math.max(remainingTime, 0),
        };
      });

      return updatedUsers;
    });

    if (shouldFetchData) {
      fetchUsersWithNullLogout();
    }
  };

  const handleIconClick = (user) => {
    setSelectedUser(user);
    setDrawerVisible(true);
  };

  const publicRoutes = [
    '/',
    '/forgot-password',
    '/reset-password',
    '/verify-otp',
    '/reset-password-successfull',
  ];

  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password-successfull"
            element={
              <PublicRoute>
                <ResetPasswordSuccessfull />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/verify-otp"
            element={
              <PublicRoute>
                <VerifyOTP />
              </PublicRoute>
            }
          />
          <Route
            path="/admin-home"
            element={
              <ProtectedRoute>
                <AdminHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <ClientHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <EventHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/safes"
            element={
              <ProtectedRoute>
                <SafesHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lockers"
            element={
              <ProtectedRoute>
                <LockerHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/finance-home"
            element={
              <ProtectedRoute>
                <HomeFinance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upgrade-locker"
            element={
              <ProtectedRoute>
                <UpgradeLocker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upgrade-safe"
            element={
              <ProtectedRoute>
                <UpgradeSafe />
              </ProtectedRoute>
            }
          />
          <Route
            path="/renewal-mails"
            element={
              <ProtectedRoute>
                <RenewalHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/visit-history"
            element={
              <ProtectedRoute>
                <ViewClientVisitHistroy />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-client-details-show"
            element={
              <ProtectedRoute>
                <ClientDetailsViewAdmin />
              </ProtectedRoute>
            }
          />

          <Route
            path="/locker-safe-allocate"
            element={
              <ProtectedRoute>
                <LockerSafeAllocate />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment-calculator"
            element={
              <ProtectedRoute>
                <PaymentCalculator />
              </ProtectedRoute>
            }
          />

          <Route path="/popup" element={<Poppages />} />
          {/* <Route path="/getClient" element={<GetClient />} /> */}
          <Route path="/updatePlane" element={<UpdatePlan />} />
          <Route
            path="/Client-visit-history"
            element={<ViewClientVisitHistroy />}
          />
          <Route path="/upload-documents" element={<DocumentUpload />} />
          <Route path="/choose-locker-safe" element={<ChooseLockerAndSafe />} />
        </Routes>
      </AuthProvider>

      {!publicRoutes.includes(location.pathname) && (
        <div
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          {usersWithNullLogout.map((user) => (
            <div
              key={user._id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar
                size={80}
                src={user.clientPic}
                alt={user.foreName}
                onClick={() => handleIconClick(user)}
                style={{
                  cursor: 'pointer',
                  border: '1px solid #ddd',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                }}
              />
              <div
                style={{
                  marginTop: 5,
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: user.remainingMinutes > 0 ? 'green' : 'red',
                  width: '60px',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '25px',
                  borderRadius: '10px',
                  boxShadow: 'rgb(233 14 14 / 57%) 0px 3px 14px',
                }}
              >
                <p>{formatTime(user.remainingTime)}</p>
              </div>
            </div>
          ))}
        </div>
      )}
      <Drawer
        title="User Details"
        placement="right"
        closable
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={350}
      >
        {selectedUser ? (
          <div>
            <Avatar
              size={64}
              src={selectedUser.clientPic}
              alt={selectedUser.foreName}
              style={{ marginBottom: 20 }}
            />
            <h3>{`${selectedUser.foreName} ${selectedUser.surName}`}</h3>
            <p>
              <strong>UCC Number:</strong> {selectedUser.uccNumber}
            </p>
            <p>
              <strong>Box Number:</strong> {selectedUser.boxNumber}
            </p>
            <p>
              <strong>Email:</strong> {selectedUser.email}
            </p>
          </div>
        ) : (
          <p>No user selected.</p>
        )}
      </Drawer>
    </div>
  );
};

export default App;
