import React from 'react'
import './Popup.css';
 import loginSuccefullyImage from '../popUPPage/popupimage/successFully.png'


function LoginSuccessFully({ isOpensuccess, onClosesuccess }) {
    if (!isOpensuccess) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content text-center ">
                <img src={`${loginSuccefullyImage}`} alt='' className='popupSuccessImage'/>
                <h4 className='text-success'>Login Successfully</h4>
              
                <p>You have successfully login into your account<br/>
                You can close this window.</p>
                <button onClick={onClosesuccess} className='btn btn-primary rounded-pill px-5'>Close</button>
            </div>
        </div>
    );
}

export default LoginSuccessFully