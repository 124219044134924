import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/images/logo.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUserCircle } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { logoutUser } from '../../auth/AuthApi';

const NavbarWithSearchBar = ({ setSearchTerm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [adminFirstName, setAdminFirstName] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    getAdminDetails();
  }, []);

  const handleHomeNavigate = () => {
    navigate('/admin-home');
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      const message = logoutUser(navigate);
      toast.success(message);
    } catch (error) {
      console.error('Error during logout:', error);
      toast.error(error.message);
    }
  };

  const getAdminDetails = () => {
    const adminDetails = Cookies.get('admin-details');
    if (adminDetails) {
      const parsedDetails = JSON.parse(adminDetails);
      setAdminFirstName(parsedDetails.firstName);
    }
  };

  return (
    <div>
      <nav className={`navbar`}>
        <div className="navbar-content">
          <img
            src={Logo}
            alt="Logo"
            className="logo"
            onClick={handleHomeNavigate}
          />
          <div className="profile text-light">
            <FaUserCircle className="profile-icon" onClick={toggleDropdown} />
            {isOpen && (
              <div className="dropdown-menu-profile">
                <button onClick={handleLogout} className="btn btn-danger w-100">
                  Logout
                </button>
              </div>
            )}
            <span className="username">
              {adminFirstName ? adminFirstName : 'User Name'}
            </span>
          </div>
        </div>
        <div className="search-container">
          <div className="search-input-container">
            <FiSearch className="search-icon" />
            <input
              type="text"
              className="search-input"
              placeholder="Search anything here"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="home-button-container">
          <button className="home-button" onClick={handleHomeNavigate}>
            <FaHome className="me-2 fs-5" /> <span className="">Home</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default NavbarWithSearchBar;
