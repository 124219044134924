import React, { useState } from 'react';
import BoxSizeRental from '../reusable/Client/BoxSizeRental';
import SecuritySafe from '../reusable/Client/SecuritySafe';

const ChooseLockerAndSafe = ({
  prevStep,
  clientId,
  setRemark,
  handleSubmit,
  email,
  isEmailSubmitted,
  handleBackPrevious,
  resetFlow,
  handleBack,
}) => {
  const [activeTab, setActiveTab] = useState('BoxSizeRental');

  const handleTabClick = (choice) => {
    setActiveTab(choice);
  };

  return (
    <div className="p-2">
      <div style={styles.container}>
        <h2 style={styles.heading}>Select Your Option</h2>
        <div style={styles.tabContainer}>
          <button
            className="fw-bold"
            onClick={() => handleTabClick('BoxSizeRental')}
            style={{
              ...styles.tabButton,
              backgroundColor:
                activeTab === 'BoxSizeRental' ? '#4CAF50' : '#ddd',
              color: activeTab === 'BoxSizeRental' ? 'white' : '#333',
            }}
          >
            Choose Locker
          </button>
          <button
            className="fw-bold"
            onClick={() => handleTabClick('SecuritySafe')}
            style={{
              ...styles.tabButton,
              backgroundColor:
                activeTab === 'SecuritySafe' ? '#4CAF50' : '#ddd',
              color: activeTab === 'SecuritySafe' ? 'white' : '#333',
            }}
          >
            Choose Safe
          </button>
        </div>
        <div style={styles.content}>
          {activeTab === 'BoxSizeRental' && (
            <BoxSizeRental
              clientId={clientId}
              setRemark={setRemark}
              prevStep={prevStep}
              handleSubmit={handleSubmit}
              email={email}
              isEmailSubmitted={isEmailSubmitted}
              resetFlow={resetFlow}
              handleBackPrevious={handleBackPrevious}
              handleBack={handleBack}
            />
          )}
          {activeTab === 'SecuritySafe' && (
            <SecuritySafe
              clientId={clientId}
              setRemark={setRemark}
              prevStep={prevStep}
              handleSubmit={handleSubmit}
              email={email}
              isEmailSubmitted={isEmailSubmitted}
              handleBackPrevious={handleBackPrevious}
              handleBack={handleBack}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '15px',
    width: '100%',
  },
  heading: {
    marginBottom: '20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: '20px',
  },
  tabButton: {
    padding: '12px 25px',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
    cursor: 'pointer',
    flex: 1,
    margin: '0 5px',
    textAlign: 'center',
    transition: 'background-color 0.3s, color 0.3s',
  },
  content: {
    width: '100%',
  },
  subContainer: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    width: '100%',
    textAlign: 'center',
  },
};

export default ChooseLockerAndSafe;
