import React, { useEffect, useState } from 'react';
import { Table, Button, Avatar, Typography, Input, message } from 'antd';
import { getTransactionAPI } from '../../client-management/API/ClientTableAPI';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../reusable/reusableComponent/BackButton';
import TransactionColumns from './TransactionColumns';
import { getAllPayment } from './FinancialApi';
import moment from 'moment';
import '../financial-style/financialStyle.css';
import Swal from 'sweetalert2';
import { sendUserEventReport } from '../../event-management/EventApis';
import CopyableText from '../../reusable/Common/CopyableText';

const { Text, Title } = Typography;
const { Search } = Input;

const FinanceClientTable = ({ searchTerm }) => {
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchTerm1, setSearchTerm1] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredClients, setFilteredClients] = useState([]);

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (showTransactions) {
      setShowTransactions(false);
      setSelectedClient(null);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const response = await getAllPayment();
        if (response.success) {
          const transactions = response.data.flatMap((client) => {
            return client.transactions.map((transaction) => ({
              ...transaction,
              clientPic: client.clientPic,
              foreName: client.foreName,
              surName: client.surName,
              applicationNo: client.applicationNo,
              uccNumber: client.uccNumber,
              accountBalance: client.accountBalance,
            }));
          });
          setClientData(transactions);
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClientData();
  }, []);

  const handleViewTransaction = async (client) => {
    setLoading(true);
    try {
      const response = await getTransactionAPI(client?.userId);
      if (response.success) {
        setTransactionData(response?.data);
        setShowTransactions(true);
        setSelectedClient(client);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filterClients = () => {
      let filtered = [...clientData];

      const searchTerms = [searchTerm, searchTerm1]
        .map((term) => term?.trim().toLowerCase())
        .filter(Boolean);

      if (searchTerms.length > 0) {
        filtered = filtered.filter((client) => {
          return searchTerms.some((searchTerm) => {
            const fullNameMatch = `${client.foreName} ${client.surName}`
              .toLowerCase()
              .includes(searchTerm);
            const emailMatch = client.email?.toLowerCase().includes(searchTerm);
            const uccNumberMatch = client.uccNumber
              ?.toLowerCase()
              .includes(searchTerm);
            const boxNumberMatch = client.boxNumber
              ?.toLowerCase()
              .includes(searchTerm);
            const postcodeMatch = client.postcode
              ?.toLowerCase()
              .includes(searchTerm);
            const telephoneMatch = client.telephone
              ?.toLowerCase()
              .includes(searchTerm);
            let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
            const renewalDateMatch = renewDate
              ?.toLowerCase()
              .includes(searchTerm);
            let regDate = moment(client.createdAt).format('DD-MM-YYYY');
            const RegDateMatch = regDate?.toLowerCase().includes(searchTerm);
            const dobDate = moment(client.dob).format('DD-MM-YYYY');
            const dobDateMatch = dobDate?.toLowerCase().includes(searchTerm);
            const streetMatch = client.street
              ?.toLowerCase()
              .includes(searchTerm);
            const townMatch = client.town?.toLowerCase().includes(searchTerm);

            return (
              fullNameMatch ||
              emailMatch ||
              uccNumberMatch ||
              boxNumberMatch ||
              postcodeMatch ||
              telephoneMatch ||
              renewalDateMatch ||
              RegDateMatch ||
              dobDateMatch ||
              streetMatch ||
              townMatch
            );
          });
        });
      }

      setFilteredClients(filtered);
      setCurrentPage(1);
    };

    filterClients();
  }, [searchTerm, searchTerm1, clientData]);

  const columns = [
    {
      title: 'Client Picture',
      dataIndex: 'clientPic',
      key: 'clientPic',
      render: (clientPic) => <Avatar src={clientPic} alt="Client" size={64} />,
    },
    {
      title: 'FULL NAME',
      key: 'fullName',
      align: 'center',
      sorter: (a, b) => {
        const nameA = `${a.foreName || ''} ${a.surName || ''}`.toLowerCase();
        const nameB = `${b.foreName || ''} ${b.surName || ''}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      render: (text, record) => {
        const fullName = `${record.foreName || ''} ${record.surName || ''}`;
        return fullName || '---';
      },
    },
    {
      title: 'UCC NO.',
      dataIndex: 'uccNumber',
      key: 'uccNumber',
      render: (uccNumber) => <CopyableText text={uccNumber} />,
      align: 'center',
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (createdAt) =>
        createdAt ? (
          <div style={{ textAlign: 'center' }}>
            <div>{moment(createdAt).format('DD-MM-YYYY')}</div>
            <div>{moment(createdAt).format('hh:mm A')}</div>
          </div>
        ) : (
          '---'
        ),
      align: 'center',
    },
    {
      title: 'DETAILS',
      dataIndex: 'details',
      key: 'details',
      render: (details) => details || '---',
      align: 'center',
    },
    {
      title: 'ACCOUNT BALANCE',
      dataIndex: 'accountBalance',
      key: 'accountBalance',
      render: (accountBalance) =>
        accountBalance != null ? (
          <span className={accountBalance < 0 ? 'text-danger fw-bold' : ''}>
            {accountBalance < 0
              ? `-£${Math.abs(accountBalance).toLocaleString()}`
              : `£${accountBalance.toLocaleString()}`}
          </span>
        ) : (
          '---'
        ),
      align: 'center',
    },
    {
      title: 'ACTIONS',
      key: 'actions',
      render: (text, record) => (
        <Button
          className="btn-view-detail w-100 p-0 m-2"
          onClick={() => handleViewTransaction(record)}
        >
          View Transaction
        </Button>
      ),
      align: 'center',
    },
  ];

  const handlePrint = () => {
    const printableElement = document.getElementById('printableArea');
    const originalBody = document.body.innerHTML;

    if (printableElement) {
      document.body.innerHTML = printableElement.innerHTML;
      window.print();
      document.body.innerHTML = originalBody;
      window.location.reload();
    } else {
      message.error('Nothing to print');
    }
  };

  const handleMailSend = async () => {
    setLoading(true);

    try {
      // if (!visitHistory.length) {
      //   return message.error('No Visit History Found');
      // }

      const result = await sendUserEventReport(selectedClient?.userId);
      if (result.success) {
        Swal.fire({
          icon: 'success',
          title: 'Email Sent',
          text: result.message || 'Email sent successfully!',
          confirmButtonText: 'Close',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: result.message || 'Failed to send the document link.',
          confirmButtonText: 'Retry',
        });
      }
    } catch (error) {
      console.error('Error in handleMailSend:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An unexpected error occurred.',
        confirmButtonText: 'Close',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3 mt-4">
      <div className="back-button-container">
        <BackButton onClick={handleBackButtonClick} />
      </div>
      {showTransactions && (
        <div className="d-flex justify-content-end mb-2">
          <div className="action-buttons">
            <button
              className="custom-button print-button"
              onClick={handlePrint}
            >
              Print PDF
            </button>
            <button
              className="custom-button email-button"
              onClick={handleMailSend}
            >
              Send via Email
            </button>
          </div>
        </div>
      )}
      {showTransactions && (
        <div id="printableArea">
          {selectedClient && (
            <div className="client-details-container">
              <div className="client-info">
                <Avatar src={selectedClient.clientPic} size={80} />
                <div className="client-text">
                  <Title level={4}>
                    {`${selectedClient?.foreName} ${selectedClient?.surName}`}
                  </Title>
                  <Text>
                    <strong>Application No:</strong>{' '}
                    {selectedClient?.applicationNo || '---'}
                  </Text>
                  <br />
                  <Text>
                    <strong>UCC No:</strong>
                    <CopyableText text={selectedClient?.uccNumber} />
                  </Text>
                </div>
              </div>
            </div>
          )}

          <Table
            columns={TransactionColumns}
            dataSource={transactionData}
            rowKey="_id"
            pagination={false}
            loading={loading}
          />
        </div>
      )}

      <div className="col-sm-12 col-md-6 col-lg-6 float-end w-25 mb-2">
        {!showTransactions && (
          <Search
            placeholder="Search by Name, UCC No., Postcode, etc."
            onChange={(e) => setSearchTerm1(e.target.value)}
            enterButton
            allowClear
            value={searchTerm1}
            className="custom-search-bar"
          />
        )}
      </div>

      {!showTransactions && (
        <Table
          columns={columns}
          dataSource={filteredClients}
          rowKey="_id"
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: 10,
            onChange: (page) => setCurrentPage(page),
          }}
          className="table-responsive"
          style={{ tableLayout: 'fixed' }}
        />
      )}
    </div>
  );
};

export default FinanceClientTable;
