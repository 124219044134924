import {
  makeBearerJsonApiRequest,
  makeJsonApiRequest,
} from '../../../services/ApiService';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const makePayment = async (dataToSubmit) => {
  try {
    const endpoint = `${API_BASE_URL}make-payment`;
    const response = await makeBearerJsonApiRequest(
      'POST',
      endpoint,
      dataToSubmit
    );
    return response;
  } catch (error) {
    throw new Error(error.message || 'Something went wrong');
  }
};

export const getNonCashPayment = async () => {
  try {
    const endpoint = `${API_BASE_URL}get-non-cash-payment`;
    const response = await makeBearerJsonApiRequest('POST', endpoint, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching non-cash payments:', error);
    throw error;
  }
};

export const getAllPayment = async () => {
  const endpoint = `${API_BASE_URL}get-all-payment`;
  const response = await makeBearerJsonApiRequest('POST', endpoint, {});
  return response;
};

export const getArrearsList = async () => {
  const endpoint = `${API_BASE_URL}arrears-list`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, {});
    if (response?.success) {
      return response?.data;
    } else {
      throw new Error('Failed to fetch arrears list');
    }
  } catch (error) {
    console.error('Error fetching arrears list:', error);
    throw error;
  }
};

export const fetchArrearsReport = async () => {
  const endpoint = `${API_BASE_URL}arrears-list-report`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, {});
    return response?.data;
  } catch (error) {
    console.error('Error fetching arrears report:', error);
    throw error;
  }
};

export const getForename = async (uccNumber) => {
  const endpoint = `${API_BASE_URL}get-forename`;

  try {
    const response = await makeJsonApiRequest('POST', endpoint, { uccNumber });
    return response;
  } catch (error) {
    throw new Error('Failed to fetch forename');
  }
};
