import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputField from '../reusable/reusableComponent/InputField';
import FormButtons from '../reusable/reusableComponent/FormButtons';
import AuthSideImage from '../../assets/images/loginImage.png';
import AuthImage from '../../components/reusable/AuthComponents/AuthImage';
import { IoIosWarning } from 'react-icons/io';
import { toast } from 'react-toastify';
import { resetPassword } from './AuthApi';
import logo from '../../assets/images/logo.png';
import '../../assets/css/style.css';

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, otp } = location.state;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
    if (!password) {
      tempErrors.password = 'New password is required.';
      isValid = false;
    } else if (password.length < 6) {
      tempErrors.password = 'Password must be at least 6 characters long.';
      isValid = false;
    }
    if (!confirmPassword) {
      tempErrors.confirmPassword = 'Confirm password is required.';
      isValid = false;
    } else if (confirmPassword !== password) {
      tempErrors.confirmPassword = 'Passwords do not match.';
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await resetPassword(email, otp, password);
        if (response && response.success) {
          toast.success(response.message || 'Password changed successfully.');
          navigate('/reset-password-successfull');
          setPassword('');
          setConfirmPassword('');
          setErrors({});
        } else {
          toast.error(response.error || 'Failed to reset password.');
        }
      } catch (error) {
        toast.error(error.message || 'An unexpected error occurred.');
      }
    }
  };
  return (
    <div className="container-fluid">
      <div className="row min-vh-100">
        <AuthImage imageUrl={AuthSideImage} />
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
          <div className="text-center mb-5">
            <img
              className="img-fluid mt-4"
              src={logo}
              alt=""
              style={{ height: '80px' }}
            />
          </div>
          <div className="w-75 px-lg-5 px-md-4">
            {errors.password || errors.confirmPassword ? (
              <div
                className="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <IoIosWarning className="me-2" />
                <div>
                  {errors.password && <span>{errors.password} </span>}
                  {errors.confirmPassword && (
                    <span>{errors.confirmPassword}</span>
                  )}
                </div>
              </div>
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <h5 className="text-bolder mb-3">Reset your Account</h5>
                <InputField
                  label="New Password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                />
                <InputField
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
              </div>
              <div className="text-center">
                <FormButtons type="submit">Change Password</FormButtons>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
