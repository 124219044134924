import React from 'react';
import { GrFormPreviousLink } from 'react-icons/gr';

const BackButton = ({ onClick }) => {
  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    background: 'linear-gradient(90deg, #1F3F5F, #4089D2)',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.background =
      'linear-gradient(90deg, #4089D2, #1F3F5F)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.background =
      'linear-gradient(90deg, #1F3F5F, #4089D2)';
  };

  return (
    <div className="d-flex justify-content-end mt-4">
      <button
        onClick={onClick}
        style={buttonStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label="Back to previous page"
      >
        <GrFormPreviousLink className="fs-4 me-2 text-light" /> Back To Previous
      </button>
    </div>
  );
};

export default BackButton;
