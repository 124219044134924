import React, { useEffect, useState } from 'react';
import { BsArrowUpRightCircleFill } from 'react-icons/bs';
import { Pagination, Result, Spin, Table } from 'antd';
import { RiSafe2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import BackButton from '../reusable/reusableComponent/BackButton';
import { fetchSafesApi } from './SafeApi';
import ClientDetailShowModal from '../reusable/reusableComponent/ClientDetailShowModal';
import { ClientViewDetailsAPI } from '../client-management/ClientApi';
import moment from 'moment';

function SafesTable({ searchTerm }) {
  const [safes, setSafes] = useState([]);
  const [safesCount, setSafesCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [safesPerPage] = useState(10);
  const [occupiedCount, setOccupiedCount] = useState(0);
  const [vacantCount, setVacantCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    loadSafesData();
  }, []);

  const loadSafesData = async () => {
    setLoading(true);
    try {
      const data = await fetchSafesApi();
      const safesData = data?.safes;
      setSafesCount(data.safesCount);
      setSafes(safesData);

      const occupiedSafes = safesData.filter(
        (safe) => safe.status === 'Occupied'
      ).length;
      const vacantSafes = safesData.filter(
        (safe) => safe.status === 'Vacant'
      ).length;

      setOccupiedCount(occupiedSafes);
      setVacantCount(vacantSafes);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const filteredSafes = safes.filter((safe) => {
    if (filterStatus) {
      return safe.status === filterStatus;
    }
    const safeNumberMatch = safe.safeNumber
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    const startDateMatch =
      safe.startDate &&
      moment(safe.startDate).format('DD-MM-YYYY').includes(searchTerm);

    const renewalDateMatch =
      safe.renewalDate &&
      moment(safe.renewalDate).format('DD-MM-YYYY').includes(searchTerm);
    const statusMatch = safe.status
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const sizeMatch = safe.size?.toString().includes(searchTerm);

    return (
      safeNumberMatch ||
      startDateMatch ||
      renewalDateMatch ||
      statusMatch ||
      sizeMatch
    );
  });

  const currentSafes = filteredSafes.slice(
    (currentPage - 1) * safesPerPage,
    currentPage * safesPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusClick = async (userId) => {
    try {
      const response = await ClientViewDetailsAPI(userId?._id);
      if (response.success) {
        setSelectedUserData(response.data);
      } else {
        setError('User not found');
      }
    } catch (error) {
      setError('Failed to fetch user data. Please try again later.');
    } finally {
      setIsModalVisible(true);
    }
  };

  const handleTotalOccupiedClick = () => {
    setFilterStatus('Occupied');
    setCurrentPage(1);
  };

  const handleTotalVacantClick = () => {
    setFilterStatus('Vacant');
    setCurrentPage(1);
  };

  const handleTotalSafesClick = () => {
    setFilterStatus(null);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: (
        <span
          style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}
        >
          SAFE NUMBER
        </span>
      ),
      dataIndex: 'safeNumber',
      key: 'safeNumber',
      sorter: (a, b) => a.safeNumber.localeCompare(b.safeNumber),
      sortDirections: ['ascend', 'descend'],
      render: (text) => text,
    },
    {
      title: (
        <span
          style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}
        >
          START DATE
        </span>
      ),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        text ? (
          <div>{moment(text).format('DD-MM-YYYY')}</div>
        ) : (
          <span className="fw-bold">---</span>
        ),
    },
    {
      title: (
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
          RENEWAL DATE
        </span>
      ),
      dataIndex: 'renewalDate',
      key: 'renewalDate',
      sorter: (a, b) => new Date(a.renewalDate) - new Date(b.renewalDate),
      sortDirections: ['ascend', 'descend'],
      render: (text) =>
        text ? (
          <div>{moment(text).format('DD-MM-YYYY')}</div>
        ) : (
          <span className="fw-bold">---</span>
        ),
    },
    {
      title: <span style={{ fontWeight: 'bold', fontSize: '16px' }}>SIZE</span>,
      dataIndex: 'size',
      key: 'size',
      render: (text) => (
        <div
          style={{
            backgroundColor: '#F0F8FF',
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            fontWeight: 'bold',
            width: '110px',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: (
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>STATUS</span>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div
          onClick={() =>
            status === 'Occupied' && handleStatusClick(record.userId)
          }
          style={{
            backgroundColor: status === 'Occupied' ? '#FFC7C7' : '#C4FFD1',
            color: status === 'Occupied' ? '#FF2424' : '#00A324',
            borderRadius: '10px',
            padding: '8px 22px',
            display: 'inline-block',
            fontWeight: '600',
            width: '110px',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          {status}
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status="404"
        title="No Clients Found"
        subTitle={
          error || "Sorry, we couldn't find any clients matching your search."
        }
      />
    );
  }
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="client-table-container">
        <div className="back-button-container">
          <BackButton onClick={handleBackButtonClick} />
        </div>
        <div className="card-section d-flex mt-3 p-2">
          <div
            className="client-card card border mb-2 pe-auto"
            onClick={handleTotalSafesClick}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-body">
              <div className="d-flex client-info">
                <p className="client-count fs-3 mt-2 text-primary fw-bold">
                  {safesCount}
                </p>
                <BsArrowUpRightCircleFill className="icon-arrow text-primary fs-3 mt-3 ms-2" />
              </div>
              <h5 className="card-title text-start fs-5 mt-1 ">TOTAL SAFES</h5>
            </div>
          </div>
          <div
            className="client-card card border mb-2"
            onClick={handleTotalOccupiedClick}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-body">
              <div className="d-flex client-info">
                <p className="client-count fs-3 mt-2 text-primary fw-bold">
                  {occupiedCount}
                </p>
                <i className="fa-sharp fa-solid fa-vault fs-3 mt-2 text-primary ms-2" />
              </div>
              <h5 className="card-title text-start fs-5 mt-1">
                TOTAL OCCUPIED
              </h5>
            </div>
          </div>
          <div
            className="client-card card border mb-2"
            onClick={handleTotalVacantClick}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-body">
              <div className="d-flex client-info">
                <p className="client-count fs-3 mt-2 text-primary fw-bold">
                  {vacantCount}
                </p>
                <RiSafe2Fill className="icon-arrow text-primary fs-2 mt-1 ms-2" />
              </div>
              <h4 className="card-title text-start fs-5 mt-1">TOTAL VACANT</h4>
            </div>
          </div>
        </div>

        <div className="container-fluid my-3">
          <Table
            dataSource={currentSafes}
            columns={columns}
            rowKey={(record) => record.safeNumber || record.id}
            pagination={false}
            className="table-responsive"
          />
        </div>
        <div>
          <Pagination
            current={currentPage}
            pageSize={safesPerPage}
            total={filteredSafes.length}
            onChange={handlePageChange}
            showSizeChanger={false}
            className="pagination"
          />
        </div>
        <ClientDetailShowModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          selectedUserData={selectedUserData}
        />
      </div>
    </>
  );
}

export default SafesTable;
