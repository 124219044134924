import React, { useState } from 'react';
import { MdOutlineMail } from 'react-icons/md';
import { IoKeyOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import '../resusableStyle/InputField.css';

const InputField = ({ label, type, name, value, onChange, placeholder }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const renderIcon = () => {
    if (name === 'email') {
      return <MdOutlineMail />;
    }
    if (name === 'password') {
      return <IoKeyOutline />;
    }
    return null;
  };

  return (
    <div className="mb-3">
      {label && <label className="input-form-label mb-2">{label}</label>}
      <div className="input-wrapper">
        <span className="icon-left">{renderIcon()}</span>
        <input
          type={name === 'password' && isPasswordVisible ? 'text' : type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="input-field"
        />
        {name === 'password' && (
          <span className="icon-right" onClick={togglePasswordVisibility}>
            {isPasswordVisible ? <IoEyeOutline /> : <IoEyeOffOutline />}
          </span>
        )}
      </div>
    </div>
  );
};

export default InputField;
