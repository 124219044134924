import React, { useState } from 'react';
import { Modal, Radio, Button, Form, Input, message } from 'antd';
import { fetchPlans } from '../../client-management/ClientApi';
import { updatePlanPricing } from '../lockerApi/LockerApi';
import Swal from 'sweetalert2';

const UpgradeLockerPlan = ({
  showModal,
  handleClose,
  handleConfirmUpgrade,
}) => {
  const [plans, setPlans] = useState({});
  const [selectedSize, setSelectedSize] = useState(false);
  const [form] = Form.useForm();

  const mapBoxSizeToApiSize = (label) => {
    switch (label) {
      case 'SMALL (SV65)':
        return 'Small';
      case 'MEDIUM (SV83)':
        return 'Medium';
      case 'LARGE (SV83)':
        return 'Large';
      case 'EXTRA LARGE (SV93)':
        return 'Extra Large';
      default:
        return '';
    }
  };

  const handleSizeChange = async (e) => {
    const size = e.target.value;
    setSelectedSize(size);

    const apiSize = mapBoxSizeToApiSize(size);
    try {
      const response = await fetchPlans(apiSize);
      if (response.success) {
        const planData = response.data[0]?.price || {};
        setPlans(planData);
        form.setFieldsValue(
          Object.keys(planData).reduce((acc, duration) => {
            acc[`duration-${duration}`] = planData[duration];
            return acc;
          }, {})
        );
      } else {
        message.error('Failed to fetch plans');
      }
    } catch (error) {
      message.error('Error fetching plans');
    }
  };

  const handlePriceChange = (duration, newPrice) => {
    setPlans((prevPlans) => ({
      ...prevPlans,
      [duration]: newPrice,
    }));
  };

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();
      if (Object.keys(values).length === Object.keys(plans).length) {
        const apiSize = mapBoxSizeToApiSize(selectedSize);

        const updatedPlans = Object.keys(plans).reduce((acc, duration) => {
          acc[duration] = parseInt(plans[duration], 10);
          return acc;
        }, {});

        const payload = { lockerSize: apiSize, price: updatedPlans };

        try {
          const response = await updatePlanPricing(payload);
          if (response.success) {
            form.resetFields();
            setSelectedSize(false);
            handleConfirmUpgrade();
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.message || 'Plan price updated successfully',
            });
          } else {
            message.error('Failed to update the plan price');
          }
        } catch (error) {
          message.error('Error updating plan price');
        }
      }
    } catch (error) {
      message.error('Please fill out all fields');
    }
  };

  return (
    <Modal
      title="Upgrade Locker Plan"
      open={showModal}
      onCancel={handleClose}
      width={600}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Close
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={handleConfirm}
          disabled={!selectedSize}
        >
          Confirm Upgrade
        </Button>,
      ]}
    >
      <p>Select the new locker size:</p>
      <Radio.Group onChange={handleSizeChange} value={selectedSize}>
        <div className="d-flex">
          <Radio value="SMALL (SV65)">SMALL (SV65)</Radio>
          <Radio value="MEDIUM (SV83)">MEDIUM (SV83)</Radio>
          <Radio value="LARGE (SV83)">LARGE (SV83)</Radio>
          <Radio value="EXTRA LARGE (SV93)">EXTRA LARGE (SV93)</Radio>
        </div>
      </Radio.Group>

      {selectedSize && (
        <Form form={form} layout="horizontal" style={{ marginTop: 20 }}>
          {Object.entries(plans).length > 0 &&
            Object.entries(plans).map(([duration, price]) => (
              <div key={duration}>
                <Form.Item
                  label={`Plan Duration: ${duration} months`}
                  name={`duration-${duration}`}
                  rules={[{ required: true, message: 'Please enter a price!' }]}
                >
                  <Input
                    className="w-100"
                    type="number"
                    placeholder={`Enter amount for ${duration} months`}
                    value={price}
                    onChange={(e) => {
                      const updatedPrice = e.target.value;
                      handlePriceChange(duration, updatedPrice);
                    }}
                  />
                </Form.Item>
              </div>
            ))}
        </Form>
      )}
    </Modal>
  );
};

export default UpgradeLockerPlan;
