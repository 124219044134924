import React, { useState } from 'react';
import '../resusableStyle/AZFilter.css';

const AZFilter = ({ onFilter }) => {
  const [activeLetter, setActiveLetter] = useState('');
  const letters = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );

  const handleFilterClick = (letter) => {
    setActiveLetter(letter);
    onFilter(letter);
  };

  return (
    <div className="mt-4">
      <div className="az-filter">
        {letters.map((letter) => (
          <button
            key={letter}
            onClick={() => handleFilterClick(letter)}
            className={`az-filter-btn ${
              activeLetter === letter ? 'active' : ''
            }`}
            type="button"
            aria-label={`Filter by letter ${letter}`}
          >
            {letter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AZFilter;
