import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { PiLessThan } from 'react-icons/pi';
import InputField from '../reusable/reusableComponent/InputField';
import FormButtons from '../reusable/reusableComponent/FormButtons';
import { toast } from 'react-toastify';
import { forgetPassword } from './AuthApi';
import Loader from '../reusable/Loader/Loader';
import { IoIosWarning } from 'react-icons/io';
import '../../assets/css/style.css';
import AuthImage from '../reusable/AuthComponents/AuthImage';
import AuthSideImage from '../../assets/images/loginImage.png';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setErrors({ email: 'Email is required.' });
      return;
    } else if (!validateEmail(email)) {
      toast.error('Please enter a valid email.');
      setErrors({ email: 'Email is invalid.' });
      return;
    }
    setLoading(true);
    setErrors({});
    try {
      const result = await forgetPassword(email);
      if (result.status === 200 && result.success) {
        toast.success(result.message);
        setEmail('');
        navigate('/verify-otp', { state: { email } });
      } else {
        setErrors(result.message || 'Failed to send password reset link.');
      }
    } catch (error) {
      setErrors('Error sending password reset link. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      {loading ? (
        <Loader />
      ) : (
        <div className="row min-vh-100">
          <AuthImage imageUrl={AuthSideImage} />
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center">
            <div className="text-center mb-5">
              <img
                className="img-fluid"
                src={logo}
                alt="Sentinel Vaults Logo"
                style={{ height: '100px' }}
              />
            </div>
            <div className="w-75 px-lg-5 px-md-4">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <p className="fs-5 fw-bold mb-1">Forgot Your Password?</p>
                  <p className="forgot-text text-muted">
                    Enter your email, and we’ll send you a link to reset your
                    password.
                  </p>
                  {errors.email && (
                    <div
                      className="alert alert-danger d-flex align-items-center"
                      role="alert"
                    >
                      <IoIosWarning className="me-2 fs-4" />
                      <div>
                        {errors.email && (
                          <span>
                            {errors.email}
                            {errors.password && ' '}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  <InputField
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <Link to="/" className="text-decoration-none">
                    <b
                      className="py-3"
                      style={{ fontSize: '15px', color: '#000000' }}
                    >
                      <PiLessThan /> Back to Login
                    </b>
                  </Link>
                </div>
                <div className="text-center">
                  <FormButtons type="submit">Submit</FormButtons>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
