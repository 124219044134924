import React, { useEffect, useState } from 'react';
import { Table, Avatar, Spin, Alert, Input } from 'antd';
import { toast } from 'react-toastify';
import { fetchArrearsReport, getArrearsList } from './FinancialApi';
import BackButton from '../../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CopyableText from '../../reusable/Common/CopyableText';

const { Search } = Input;

const ArrearsTable = ({ searchTerm }) => {
  const [arrearsData, setArrearsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm1, setSearchTerm1] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getArrearsList();
      setArrearsData(data);
      setFilteredData(data);
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Failed to load arrears data');
    } finally {
      setLoading(false);
    }
  };

  const handleArrearsReport = async () => {
    try {
      setLoading(true);
      const data = await fetchArrearsReport();
      window.open(data, '_blank');
      toast.success(data.message);
    } catch (error) {
      toast.error('Failed to generate arrears report');
      setError('Failed to generate arrears report');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const combinedSearchTerm =
      (searchTerm?.trim().toLowerCase() || '') +
      (searchTerm1?.trim().toLowerCase() || '');

    const filtered = arrearsData.filter((record) => {
      const searchString = `
        ${record.foreName || ''} ${record.surName || ''}
        ${record.email || ''} ${record.uccNumber || ''}
        ${record.boxType || ''} ${record.boxNumber || ''}
        ${record.postcode || ''} ${record.telephone || ''}
        ${record.street || ''} ${record.town || ''}
        ${moment(record.createdAt).format('DD-MM-YYYY') || ''}
        ${moment(record.renewalDate).format('DD-MM-YYYY') || ''}
        ${`${record.accountBalance}` || ''}
      `.toLowerCase();

      return searchString.includes(combinedSearchTerm);
    });

    setFilteredData(filtered);
  }, [searchTerm, searchTerm1, arrearsData]);

  const columns = [
    {
      title: 'CLIENT PIC',
      dataIndex: 'clientPic',
      align: 'center',
      render: (text) => <Avatar src={text} size={50} />,
    },
    {
      title: 'FULL NAME',
      dataIndex: 'fullName',
      align: 'center',
      render: (_, record) => `${record.foreName} ${record.surName}`,
      sorter: (a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      align: 'center',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) =>
        a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
    },
    {
      title: 'UCC NUMBER',
      dataIndex: 'uccNumber',
      align: 'center',
      render: (_, record) => <CopyableText text={record.uccNumber || '---'} />,
    },
    {
      title: 'TELEPHONE',
      dataIndex: 'telephone',
      align: 'center',
    },
    {
      title: 'BOX TYPE',
      dataIndex: 'boxType',
      align: 'center',
      render: (_, record) => `${record.boxType || '---'}`,
    },
    {
      title: 'BOX NO.',
      dataIndex: 'boxNumber',
      align: 'center',
      render: (_, record) => `${record.boxNumber || '---'}`,
    },
    {
      title: 'ACCOUNT BALANCE',
      dataIndex: 'accountBalance',
      align: 'center',
      render: (text) => {
        if (text === null || text === undefined || isNaN(text)) {
          return '---';
        }

        const isNegative = text < 0;
        const formattedBalance = isNegative
          ? `-£${Math.abs(text).toLocaleString()}`
          : `£${text.toLocaleString()}`;

        return (
          <span className={`fw-bold ${isNegative ? 'text-danger' : ''}`}>
            {formattedBalance}
          </span>
        );
      },
    },

    {
      title: 'DATE',
      dataIndex: 'createdAt',
      align: 'center',
      render: (text) => <span>{moment(text).format('DD-MM-YYYY')}</span>,
      sorter: (a, b) => (moment(a.date).isBefore(moment(b.date)) ? -1 : 1),
    },
  ];

  const handlePreviousBack = () => {
    navigate(-1);
  };

  return (
    <div className="">
      <div className="row mb-2">
        <div className="d-flex justify-content-end">
          <Search
            placeholder="Search by Name, UCC No., Postcode, etc."
            onChange={(e) => setSearchTerm1(e.target.value)}
            enterButton
            allowClear
            value={searchTerm1}
            className="custom-search-bar w-25"
          />
        </div>
      </div>
      <div className="p-4">
        <div className="d-flex justify-content-between">
          <h2 className="header-text fs-4">Arrears List</h2>
          <button
            onClick={handleArrearsReport}
            className="btn btn-move-archive fw-bold mb-3 w-25"
          >
            Generate Arrears List Report
          </button>
        </div>
        {loading && (
          <Spin size="large" className="d-flex justify-content-center" />
        )}
        {error && <Alert message={error} type="error" showIcon />}

        <Table
          rowKey="_id"
          columns={columns}
          dataSource={filteredData}
          pagination={{
            current: currentPage,
            pageSize: 5,
            onChange: (page) => setCurrentPage(page),
          }}
          className="table-responsive"
          loading={loading}
        />
        <div className="back-button-container">
          <BackButton onClick={handlePreviousBack} />
        </div>
      </div>
    </div>
  );
};

export default ArrearsTable;
