import React, { useRef } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { Image } from 'antd';
import { IoCloseCircleSharp } from 'react-icons/io5';

const IdentificationDocuments = ({
  documents,
  setDocuments,
  identificationDocument,
  handleFileChange,
  disabled,
  errors,
  handleRemoveDocument,
}) => {
  const documentInputsRef = useRef({});

  const handleCheckboxChange = (e, docName) => {
    const { checked } = e.target;
    setDocuments((prev) => ({
      ...prev,
      [docName]: checked,
    }));
  };

  return (
    <div className="document-section">
      <h6 className="header-text">
        Identification Documents (please tick box)
      </h6>

      {(errors?.documents || errors?.nomineeIdentificationDocument) && (
        <p className="error-text text-danger">
          {errors?.documents && <span>{errors.documents}</span>}
          {errors?.nomineeIdentificationDocument && (
            <span>{errors.nomineeIdentificationDocument}</span>
          )}
        </p>
      )}

      {[
        { name: 'Passport', label: 'Passport' },
        { name: 'Drivers License', label: 'Drivers License' },
        { name: 'Bank Statement', label: 'Bank Statement' },
        { name: 'Council Tax', label: 'Council Tax' },
        { name: 'Utility Bill', label: 'Utility Bill' },
      ].map((doc) => {
        const isDocumentUploaded = identificationDocument?.some(
          (identification) => identification.documentType === doc.name
        );

        return (
          <div key={doc.name}>
            <div className="d-flex align-items-center mt-4">
              <input
                type="checkbox"
                id={doc.name}
                name={doc.name}
                checked={documents[doc.name] || isDocumentUploaded}
                onChange={(e) => handleCheckboxChange(e, doc.name)}
                disabled={disabled}
              />
              <label htmlFor={doc.name} className="ms-2 document-title">
                {doc.label}
              </label>
            </div>

            {(documents[doc.name] || isDocumentUploaded) && (
              <div className="d-flex align-items-center mt-2">
                <div className="drop-box col-sm-12 col-md-6 col-lg-6 me-3">
                  <p className="upload-instruction">
                    <MdOutlineFileUpload /> Upload your {doc.label} document
                    here
                  </p>
                </div>
                <div>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    ref={(el) => (documentInputsRef.current[doc.name] = el)}
                    onChange={(e) => handleFileChange(e, doc.name)}
                    disabled={disabled}
                  />
                  <button
                    className="btn upload-button"
                    onClick={(e) => {
                      e.preventDefault();
                      documentInputsRef.current[doc.name].click();
                    }}
                    disabled={disabled}
                  >
                    Upload file
                  </button>
                </div>

                {isDocumentUploaded && (
                  <div className="ms-3">
                    {identificationDocument
                      .filter(
                        (identification) =>
                          identification.documentType === doc.name
                      )
                      .map((identification, index) => (
                        <div className="position-relative">
                          <Image
                            key={index}
                            src={identification.documentUrl}
                            alt={identification.documentType}
                            style={{
                              width: '100px',
                              height: '100px',
                              borderRadius: '12px',
                              border: '1px solid #ddd',
                              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                              marginLeft: '15px',
                            }}
                          />
                          <IoCloseCircleSharp
                            className={`image-close-icon ${
                              disabled ? 'disabled-icon' : ''
                            }`}
                            onClick={
                              !disabled
                                ? () => handleRemoveDocument(doc.name)
                                : null
                            }
                            style={{
                              cursor: !disabled ? 'pointer' : 'not-allowed',
                              opacity: !disabled ? 1 : 0.5,
                            }}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default IdentificationDocuments;
