import React, { useEffect, useMemo, useState } from 'react';
import { getClientTableAPI } from './API/ClientTableAPI';
import { Input, Pagination, Result } from 'antd';
import UpgradeModal from '../reusable/Client/UpgradeModal';
import { useNavigate } from 'react-router-dom';
import BackButton from '../reusable/reusableComponent/BackButton';
import moment from 'moment';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import CopyableText from '../reusable/Common/CopyableText';
const { Search } = Input;

const ClientChangePlan = ({ searchTerm }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [displayedClients, setDisplayedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [componentType, setComponentType] = useState('');
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    createdAt: null,
    renewalDate: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    const filtered = searchTerm ? filterClients(searchTerm) : filteredClients;
    setDisplayedClients(filtered);
    setCurrentPage(1);
  }, [searchTerm, filteredClients]);

  useEffect(() => {
    const filtered = searchTerm1
      ? filterClients(searchTerm1, filteredClients)
      : filteredClients;
    setDisplayedClients(filtered);
    setCurrentPage(1);
  }, [searchTerm1, filteredClients]);

  const filterClients = (searchTerm) => {
    const lowercasedSearch = searchTerm.toLowerCase();
    return filteredClients.filter((client) => {
      return (
        `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowercasedSearch) ||
        (client.email &&
          client.email.toLowerCase().includes(lowercasedSearch)) ||
        (client.uccNumber &&
          client.uccNumber.toLowerCase().includes(lowercasedSearch)) ||
        (client.dob && client.dob.toLowerCase().includes(lowercasedSearch)) ||
        (client.telephone &&
          client.telephone.toLowerCase().includes(lowercasedSearch)) ||
        (client.postcode &&
          client.postcode.toLowerCase().includes(lowercasedSearch)) ||
        (client.createdAt &&
          moment(client.createdAt)
            .format('DD-MM-YYYY')
            .includes(lowercasedSearch)) ||
        (client.street &&
          client.street.toLowerCase().includes(lowercasedSearch)) ||
        (client.town && client.town.toLowerCase().includes(lowercasedSearch)) ||
        (client.dob &&
          moment(client.dob).format('DD-MM-YYYY').includes(lowercasedSearch))
      );
    });
  };

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getClientTableAPI();
      if (response.success && response?.data) {
        const clients = response?.data?.clients;
        setFilteredClients(clients);
        setDisplayedClients(clients);
      } else {
        setError(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleChangeClientPlan = (client) => {
    setSelectedClient(client);
    setIsModalVisible(true);
    setIsTableVisible(false);
  };

  const handleBackToTable = () => {
    setIsModalVisible(false);
    setIsTableVisible(true);
    setComponentType('');
  };

  const handleUpgradeComplete = () => {
    setIsTableVisible(true);
    setIsModalVisible(false);
  };

  const onSelectComponent = (type) => {
    const client = selectedClient;
    if (client) {
      if (type === 'locker') {
        navigate(`/upgrade-locker`, { state: { client } });
      } else if (type === 'safe') {
        navigate(`/upgrade-safe`, { state: { client } });
      }
      handleUpgradeComplete();
    }
  };

  const paginatedClients = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return displayedClients.slice(startIndex, endIndex);
  }, [currentPage, pageSize, displayedClients]);

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const toggleSortOrder = (field) => {
    const newSortOrder = { ...sortOrder };
    const currentOrder = newSortOrder[field];
    if (currentOrder === 'asc') {
      newSortOrder[field] = 'desc';
    } else if (currentOrder === 'desc') {
      newSortOrder[field] = 'asc';
    } else {
      newSortOrder[field] = 'asc';
    }

    setSortOrder(newSortOrder);
    sortData(field, newSortOrder[field]);
  };

  const sortData = (field, order) => {
    const sortedClients = [...filteredClients];

    if (field === 'name') {
      sortedClients.sort((a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return order === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    } else if (field === 'createdAt') {
      sortedClients.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }

    setFilteredClients(sortedClients);
  };

  return (
    <div className="container-fluid my-3">
      <div className="back-button-container">
        <BackButton onClick={handleBackPrevious} />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Search
          placeholder="Search clients by name, email, or other details"
          allowClear
          value={searchTerm1}
          onChange={(e) => setSearchTerm1(e.target.value)}
          enterButton
          className="search-input"
        />
      </div>

      {isModalVisible ? (
        <div className="modal-content">
          <UpgradeModal
            isVisible={isModalVisible}
            onClose={handleBackToTable}
            onSelectComponent={onSelectComponent}
            client={selectedClient}
          />
        </div>
      ) : isTableVisible ? (
        <div className="table-responsive">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <>
              <table className="table client-table">
                <thead>
                  <tr>
                    <th scope="col" className="th-client">
                      CLIENT
                    </th>
                    <th scope="col" className="th-name-email">
                      NAME/EMAIL
                      <span onClick={() => toggleSortOrder('name')}>
                        {sortOrder.name === 'asc' ? (
                          <BsFillCaretUpFill className="m-2 sort-icon" />
                        ) : sortOrder.name === 'desc' ? (
                          <BsFillCaretDownFill className="m-2 sort-icon" />
                        ) : (
                          <BsFillCaretDownFill className="m-2 sort-icon" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="th-telephone">
                      UCC NO.
                    </th>
                    <th scope="col" className="th-start-date">
                      START DATE
                      <span onClick={() => toggleSortOrder('createdAt')}>
                        {sortOrder.createdAt === 'asc' ? (
                          <BsFillCaretUpFill className="m-2 sort-icon" />
                        ) : sortOrder.createdAt === 'desc' ? (
                          <BsFillCaretDownFill className="m-2 sort-icon" />
                        ) : (
                          <BsFillCaretDownFill className="m-2 sort-icon" />
                        )}
                      </span>
                    </th>
                    <th scope="col" className="th-address">
                      STREET
                    </th>
                    <th scope="col" className="th-address">
                      TOWN
                    </th>
                    <th scope="col" className="th-postcode">
                      POSTCODE
                    </th>
                    <th scope="col" className="th-telephone">
                      TELEPHONE
                    </th>
                    <th scope="col" className="th-edit-client">
                      CHANGE PLAN
                    </th>
                  </tr>
                </thead>
                <tbody className="align-items-center">
                  {paginatedClients.length > 0 ? (
                    paginatedClients.map((client) => (
                      <tr key={client._id} className="client-row">
                        <td className="client-view">
                          <img
                            src={
                              client.clientPic ||
                              'https://via.placeholder.com/60'
                            }
                            alt="profile"
                            className="avatar rounded-circle client-avatar"
                            width={60}
                            height={60}
                          />
                        </td>
                        <td>
                          <p className="client-name fs-6 fw-bold mb-0">{`${client.foreName} ${client.surName}`}</p>
                          <p className="client-email fw-normal">
                            {client.email || 'N/A'}
                          </p>
                        </td>
                        <td className="client-date fw-normal">
                          <CopyableText text={client.uccNumber} />
                        </td>
                        <td className="client-date fw-normal w-25">
                          {client.createdAt
                            ? moment(client.createdAt).format('DD-MM-YYYY')
                            : '---'}
                        </td>
                        <td className="client-address">
                          {client.street || 'N/A'}
                        </td>
                        <td className="client-address">
                          {client.town || 'N/A'}
                        </td>
                        <td className="client-postcode">
                          {client.postcode || 'N/A'}
                        </td>
                        <td className="client-telephone">
                          {client.telephone || 'N/A'}
                        </td>
                        <td className="client-locker">
                          <button
                            className="btn btn-view-history"
                            onClick={() => handleChangeClientPlan(client)}
                          >
                            Change Plan
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">
                        <Result
                          status="404"
                          title="No Clients Found"
                          subTitle={
                            error ||
                            "Sorry, we couldn't find any clients matching your search."
                          }
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredClients.length}
                onChange={handlePaginationChange}
                className="pagination"
              />
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ClientChangePlan;
