import React from 'react';
import AdminNavbar from './AdminNavbar.jsx/AdminNavbar';
import HomeFooter from './HomeFooter/HomeFooter';

function AdminHome() {
  return (
    <div>
      <AdminNavbar />
      <HomeFooter />
    </div>
  );
}

export default AdminHome;
