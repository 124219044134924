import React, { useState, useEffect } from 'react';
import NavbarWithSearchBar from '../reusable/reusableComponent/NavbarWithSearchBar';
import HomeFooter from '../home/HomeFooter/HomeFooter';
import { useLocation, useNavigate } from 'react-router-dom';
import { getVisitHistoryAPI } from '../event-management/EventApis';
import { Table, message } from 'antd';
import BackButton from '../reusable/reusableComponent/BackButton';

const ViewClientVisitHistroy = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [visitHistory, setVisitHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const { clientId } = location.state || {};

  useEffect(() => {
    const fetchVisitHistory = async () => {
      if (!clientId) return;
      try {
        const visitHistoryResponse = await getVisitHistoryAPI(clientId);
        setVisitHistory(visitHistoryResponse);
      } catch (error) {
        message.error('Failed to fetch visit history.');
        console.error('Error fetching visit history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitHistory();
  }, [clientId]);

  const historyColumns = [
    {
      title: <div className="text-center">LOGIN TIME</div>,
      dataIndex: 'loginTime',
      sorter: (a, b) => new Date(a.loginTime) - new Date(b.loginTime), // Sorting by date
      render: (text) => {
        if (!text) {
          return <div className="text-center">N/A</div>;
        }

        try {
          const date = new Date(text);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return (
            <div className="text-center">
              <p className="mb-0 fs-6 fw-medium">{date.toLocaleDateString()}</p>
              <p className="mb-0 fs-7 text-muted">
                {date.toLocaleTimeString()}
              </p>
            </div>
          );
        } catch (error) {
          return <div className="text-center">Invalid Date</div>;
        }
      },
    },
    {
      title: <div className="text-center">LOGOUT TIME</div>,
      dataIndex: 'logoutTime',
      sorter: (a, b) => new Date(a.logoutTime) - new Date(b.logoutTime), // Sorting by date
      render: (text) => {
        if (!text) {
          return <div className="text-center">N/A</div>;
        }
        try {
          const date = new Date(text);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return (
            <div className="text-center">
              <p className="mb-0 fs-6 fw-medium">{date.toLocaleDateString()}</p>
              <p className="mb-0 fs-7 text-muted">
                {date.toLocaleTimeString()}
              </p>
            </div>
          );
        } catch (error) {
          return <div className="text-center">Invalid Date</div>;
        }
      },
    },
    {
      title: <div className="text-center">STATUS</div>,
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => {
        if (!text) {
          return (
            <span className="d-block text-center fw-bold text-muted fs-6">
              N/A
            </span>
          );
        }

        const formattedStatus = text
          ? text.replace(/_/g, ' ').toUpperCase()
          : 'N/A';
        const statusClass =
          formattedStatus === 'LOGGED OUT' ? 'text-danger' : 'text-success';

        return (
          <span className={`d-block text-center fw-bold ${statusClass} fs-6`}>
            {formattedStatus}
          </span>
        );
      },
    },
  ];

  const handleBackPrevious = () => {
    navigate(-1);
  };

  return (
    <div>
      <NavbarWithSearchBar setSearchTerm={setSearchTerm} />

      <div className="mt-5">
        <div className="back-button-container">
          <BackButton onClick={handleBackPrevious} />
        </div>
        <Table
          style={{ textAlign: 'center' }}
          columns={historyColumns}
          dataSource={visitHistory}
          rowKey="_id"
          loading={loading}
          onChange={(pagination, filters, sorter) => {}}
          scroll={{ x: true }}
        />
      </div>
      <HomeFooter />
    </div>
  );
};

export default ViewClientVisitHistroy;
