import React from 'react';
import moment from 'moment';

const TransactionColumns = [
  {
    title: <div className="fs-6 text-center fw-bold">Payment Method</div>,
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    render: (text) => <div className="fs-6 text-center">{text}</div>,
  },
  {
    title: <div className="fs-6 text-center fw-bold">Transaction Details</div>,
    dataIndex: 'details',
    key: 'details',
    render: (text) => (
      <div className="fs-6 text-center">{text ? text : '---'}</div>
    ),
  },
  {
    title: <div className="fs-6 text-center fw-bold">Debit</div>,
    dataIndex: 'debit',
    key: 'debit',
    render: (debit) => (
      <div className="fs-6 text-center text-danger fw-bolder">
        {debit ? `£${parseFloat(debit).toLocaleString()}` : '---'}
      </div>
    ),
  },
  {
    title: <div className="fs-6 text-center fw-bold">Credit</div>,
    dataIndex: 'credit',
    key: 'credit',
    render: (credit) => (
      <div className="fs-6 text-center">
        {credit ? `£${parseFloat(credit).toLocaleString()}` : '---'}
      </div>
    ),
  },
  {
    title: <div className="fs-6 text-center fw-bold">Balance</div>,
    dataIndex: 'balance',
    key: 'balance',
    render: (balance) => (
      <div
        className={`fs-6 text-center ${
          balance < 0 ? 'text-danger fw-bold' : ''
        }`}
      >
        {balance < 0
          ? `-£${Math.abs(balance).toLocaleString()}`
          : `£${balance.toLocaleString()}`}
      </div>
    ),
  },

  {
    title: 'DATE',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    render: (createdAt) =>
      createdAt ? (
        <div style={{ textAlign: 'center' }}>
          <div>{moment(createdAt).format('DD-MM-YYYY')}</div>
          <div>{moment(createdAt).format('hh:mm A')}</div>
        </div>
      ) : (
        '---'
      ),
    align: 'center',
  },
];

export default TransactionColumns;
