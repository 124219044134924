import React from 'react';
import { validatePaymentProcess } from '../../../services/Utils';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import { Spin } from 'antd';

const PaymentProcess = ({ prevStep, handleSubmit, formData, setFormData }) => {
  const [isOtherSelected, setIsOtherSelected] = React.useState(false);
  const [otherFeedback, setOtherFeedback] = React.useState(
    formData.feedbackRemark || ''
  );
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handleFeedbackChange = (e) => {
    const { id } = e.target;

    if (id === 'other') {
      setFormData({ ...formData, feedback: id, feedbackRemark: '' });
      setOtherFeedback('');
      setIsOtherSelected(true);
    } else {
      setFormData({ ...formData, feedback: id, feedbackRemark: '' });
      setOtherFeedback('');
      setIsOtherSelected(false);
    }
  };

  const handleOtherFeedbackChange = (e) => {
    setOtherFeedback(e.target.value);
    setFormData({ ...formData, feedbackRemark: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validatePaymentProcess(
      formData.feedback,
      otherFeedback
    );

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});

    try {
      setLoading(true);
      await handleSubmit(formData);
      setLoading(false);
    } catch (error) {
      toast.error('Error submitting payment process.');
      setLoading(false);
    }
  };

  return (
    <div className="mt-5">
      {loading ? (
        <div className="loader-overlay">
          <Spin size="large" />
        </div>
      ) : (
        <form className="mb-4" onSubmit={onSubmit}>
          <h6 className="header-text mb-4">FEEDBACK</h6>
          <div>
            <div className="fs-6 form-row fw-normal">
              How did you hear of us? (please tick one)
            </div>
            {errors.feedback && (
              <div className="text-danger">{errors.feedback}</div>
            )}
            <div className="mt-4 d-flex flex-wrap fw-bold">
              {[
                'Brochures',
                'Radio',
                'Friends / Family',
                'Previous Clients',
                'Events',
              ].map((source, index) => (
                <div className="form-check me-5" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={source.replace(/\s+/g, '')}
                    checked={formData.feedback === source.replace(/\s+/g, '')}
                    onChange={handleFeedbackChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={source.replace(/\s+/g, '')}
                  >
                    {source}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-check fw-bold">
              <input
                className="form-check-input"
                type="checkbox"
                id="other"
                checked={formData.feedback === 'other'}
                onChange={handleFeedbackChange}
              />
              <label className="form-check-label" htmlFor="other">
                Others (please describe briefly)
              </label>
            </div>

            {isOtherSelected && (
              <div className="mt-3">
                <textarea
                  className="form-control"
                  placeholder="Please specify..."
                  rows="3"
                  value={otherFeedback}
                  onChange={handleOtherFeedbackChange}
                ></textarea>
              </div>
            )}
            {errors.otherFeedback && (
              <div className="text-danger">{errors.otherFeedback}</div>
            )}
          </div>

          <div className="stepper-navigation">
            <button type="button" onClick={prevStep} className="btn prev-btn">
              <i className="fa-solid fa-caret-left"></i> Previous
            </button>
            <button type="submit" className="btn next-btn">
              Submit <i className="fa-solid fa-caret-right"></i>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PaymentProcess;
